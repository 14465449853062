import {
  faCheckSquare,
  faImage,
  faPencil,
  faPlus,
  faSquare,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { find, get, join } from "lodash";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  FrequencyTypes,
  Product,
  ProductDeliveryTypes,
  ProductStatuses,
  ProductTypes,
  useProductsQuery,
  useSellersFilterQuery,
  useUpdateProductMutation,
} from "../../graphql/generated/types";
import { formatTimeFromSeconds } from "../../helpers/FormatTime";
import { monthDays, weekDays } from "../../helpers/PickerValues";
import { priceDisplay } from "../../helpers/PriceDisplay";
import {
  productDisplayDetailsCompleted,
  productPriceDetailsCompleted,
} from "../../helpers/ProductDetailsCompletion";
import { Colors } from "../../styles/Colors";
import {
  AppButton,
  AppCard,
  AppCheckBox,
  AppDialog,
  AppError,
  AppLoader,
  AppRow,
  AppTimerButton,
  AppTitle,
} from "../components";
import { AppCol } from "../components/AppCol";
import { AppText } from "../components/AppText";
import { AppPicker } from "../components/FormComponents/AppPicker";
import { CreateItem } from "./mutations/CreateItem";
import { CreatePrice } from "./mutations/CreatePrice";
import { Delete } from "./mutations/Delete";
import { DeletePrice } from "./mutations/DeletePrice";
import { Update } from "./mutations/Update";
import { UploadImages } from "./mutations/UploadImages";
import { PackProducts } from "./packProducts/PackProducts";
import styles from "./Products.module.scss";
import { Variant } from "./variants/Variant";

export interface Checked {
  id: string;
  check: boolean;
}

export interface PickerOption {
  label: string;
  value: string;
}

export interface IFuncCompProps {}

export const Products: FunctionComponent<IFuncCompProps> = () => {
  const { t } = useTranslation();
  //

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowPerPage] = useState<number>(50);

  const [createRow, setCreateRow] = useState<boolean>(false);
  const [deleteRow, setDeleteRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);
  const [updateRow, setUpdateRow] = useState<
    { id: string; show: boolean; fields: string[] } | undefined
  >(undefined);

  const [updateImages, setUpdateImages] = useState<
    { product: Product; show: boolean } | undefined
  >(undefined);

  const [showVariants, setShowVariants] = useState<
    | {
        show: boolean;
        product: Product;
      }
    | undefined
  >(undefined);

  const [showPackProducts, setShowPackProducts] = useState<
    | {
        show: boolean;
        product: Product;
      }
    | undefined
  >(undefined);

  const [createPrice, setCreatePrice] = useState<
    | {
        show: true;
        productId: string;
        productVariantId: string;
      }
    | undefined
  >(undefined);
  const [deletePrice, setDeletePrice] = useState<
    | {
        show: boolean;
        priceId: string;
        productId: string;
      }
    | undefined
  >(undefined);

  /**********************************************************
   *** FILTER ***
   **********************************************************/

  const [sellerId, setSellerId] = useState<string>("");
  const [filterSellerOptions, setFilterSellerOptions] = useState<
    PickerOption[]
  >([]);

  const [productType, setProductType] = useState<string>("");
  const productTypesOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(ProductTypes)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    productTypesOptions.push({ value: propertyValue, label: propertyKey });
  }

  useSellersFilterQuery({
    variables: {
      skip: 0,
      limit: 100,
    },
    onCompleted: (d) =>
      setFilterSellerOptions(
        d.sellers.results.map((s) => {
          return { label: s.name, value: s.id };
        })
      ),
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
  });

  /**********************************************************
   *** QUERY & MUTATION ***
   **********************************************************/

  const { data, loading, error, refetch } = useProductsQuery({
    variables: {
      filter: {
        isDeleted: false,
        sellers: sellerId !== "" ? [sellerId] : undefined,
        type: productType,
        skip: page * rowsPerPage,
        limit: rowsPerPage,
        order: ["name:ASC"],
      },
    },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
    fetchPolicy: "network-only",
  });

  const [update] = useUpdateProductMutation({
    onCompleted: () => {
      if (refetch) refetch();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  if (error) {
    return <AppError title={"GraphQL Error"} error={error} />;
  }

  const products = get(data, "products.results", []);

  // console.log(products);
  return (
    <div className={styles.App}>
      <AppRow style={{ justifyContent: "space-between", padding: "0 20px" }}>
        <AppTitle bold>{`Products`}</AppTitle>

        {filterSellerOptions.length > 0 && (
          <AppRow
            style={{
              justifyContent: "flex-end",
              width: "100%",
              transform: "scale(0.7) translateX(15%)",
              gap: 20,
            }}
          >
            <AppButton
              link
              onClick={() => {
                setCreateRow(true);
              }}
            >
              {t("button.filters", "FILTERS:")}
            </AppButton>
            <div style={{ width: 300 }}>
              <AppPicker
                options={filterSellerOptions}
                firstOptionAsDefaultValue
                onChange={(value) => setSellerId(value)}
              />
            </div>
            <div style={{ width: 75 }}>
              <AppPicker
                options={productTypesOptions}
                firstOptionAsDefaultValue
                onChange={(value) => setProductType(value)}
              />
            </div>
          </AppRow>
        )}

        <AppButton
          pill
          onClick={() => {
            setCreateRow(true);
          }}
        >
          {t("button.create", "NEW PRODUCT")}
        </AppButton>
        {/*  <AppButton
          pill
          onClick={() => {
            refetch();
          }}
        >
          {t("button.refresh", "REFRESH")}
        </AppButton> */}
      </AppRow>
      <AppCard style={stylesheet.appCard as any}>
        {loading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "30%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppLoader size={25} thickness={3}>
              Loading Products...
            </AppLoader>
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ ...stylesheet.columnHeadText }}>
                  STATUS
                </TableCell>
                <TableCell style={{ ...stylesheet.columnHeadText }}>
                  SORTING
                </TableCell>
                <TableCell style={{ ...stylesheet.columnHeadText }}>
                  SELLER
                </TableCell>
                <TableCell style={{ ...stylesheet.columnHeadText }}>
                  SEGMENTS / CATEGORIES
                </TableCell>

                <TableCell style={{ ...stylesheet.columnHeadText }}>
                  QTY
                </TableCell>
                <TableCell style={{ ...stylesheet.columnHeadText }}>
                  TYPE
                </TableCell>
                <TableCell style={{ ...stylesheet.columnHeadText }}>
                  PRODUCT NAME
                </TableCell>
                <TableCell style={{ ...stylesheet.columnHeadText }}>
                  {productType === ProductTypes.Item
                    ? "VARIANTS"
                    : productType === ProductTypes.Pack ||
                      productType === ProductTypes.Subscription
                    ? "PACK PRODUCTS"
                    : ""}
                </TableCell>
                <TableCell style={{ ...stylesheet.columnHeadText }}>
                  AUTOMATIC ACTIVATION
                </TableCell>
                <TableCell />

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {products.map((product: Product, key: number) => {
                const displayDetailsCompleted =
                  productDisplayDetailsCompleted(product);
                const priceDetailsCompleted =
                  productPriceDetailsCompleted(product);
                return [
                  <StyledTableRow
                    key={`${key}-master`}
                    hover
                    tabIndex={-1}
                    onDoubleClick={() => {}}
                  >
                    <TableCell
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                      }}
                    >
                      <AppRow style={{ justifyContent: "flex-start" }}>
                        <AppButton
                          link
                          onClick={() => {
                            if (
                              displayDetailsCompleted &&
                              priceDetailsCompleted &&
                              product.status !== ProductStatuses.Deleted
                            )
                              update({
                                variables: {
                                  id: product.id,
                                  updateProductInput: {
                                    status:
                                      product.status ===
                                      ProductStatuses.NotAvailable
                                        ? ProductStatuses.Available
                                        : ProductStatuses.NotAvailable,
                                  },
                                },
                              });
                          }}
                          textStyle={{ fontSize: 14 }}
                        >
                          {displayDetailsCompleted &&
                            priceDetailsCompleted &&
                            product.status !== ProductStatuses.Deleted && (
                              <FontAwesomeIcon
                                icon={
                                  product.status === ProductStatuses.Available
                                    ? faCheckSquare
                                    : faSquare
                                }
                                style={{ fontSize: 16 }}
                                color={
                                  product.status === ProductStatuses.Available
                                    ? Colors.valid
                                    : Colors.transparentDark
                                }
                              />
                            )}
                        </AppButton>
                        <AppText
                          bold
                          disabled={product.status === ProductStatuses.Deleted}
                          valid={product.status === ProductStatuses.Available}
                          danger={
                            product.status === ProductStatuses.NotAvailable
                          }
                          transparentDark={
                            product.status === ProductStatuses.Deleted
                          }
                        >{`${product.status} `}</AppText>
                      </AppRow>
                    </TableCell>

                    <TableCell
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                      }}
                    >
                      <AppText
                        transparentDark={
                          product.status === ProductStatuses.Deleted
                        }
                        small
                      >{`${
                        product.hasSorting ? product.sorting : "-"
                      }`}</AppText>
                    </TableCell>

                    <TableCell
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                      }}
                    >
                      <AppText
                        bold
                        transparentDark={
                          product.status === ProductStatuses.Deleted
                        }
                      >{`${product.seller.name}`}</AppText>
                      <AppText
                        transparentDark={
                          product.status === ProductStatuses.Deleted
                        }
                        small
                      >{`${product.deliveryType}`}</AppText>
                    </TableCell>

                    <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                      <AppText
                        bold
                        transparentDark={
                          product.status === ProductStatuses.Deleted
                        }
                      >{`${join(
                        product.segments?.map((segment) => segment.name),
                        " | "
                      )}`}</AppText>
                      <AppText
                        bold
                        transparentDark={
                          product.status === ProductStatuses.Deleted
                        }
                      >{`${join(
                        product.categories?.map((category) => category.name),
                        " | "
                      )}`}</AppText>
                    </TableCell>

                    <TableCell // QUANTITY
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                      }}
                    >
                      {product.variants.length > 0 ? (
                        <></>
                      ) : (
                        <AppRow style={{ justifyContent: "flex-start" }}>
                          {(product.type === ProductTypes.Item ||
                            product.type === ProductTypes.Upsell ||
                            product.type === ProductTypes.Subscription) && (
                            <FontAwesomeIcon
                              onClick={() => {
                                if (product.status !== ProductStatuses.Deleted)
                                  setUpdateRow({
                                    id: product.id,
                                    show: true,
                                    fields: ["quantity"],
                                  });
                              }}
                              data-tip={"Update data"}
                              data-for="updateToolTip"
                              icon={faPencil}
                              style={{
                                margin: "0px 10px 0 0",
                                outline: "none",
                                cursor: "pointer",
                                color: Colors.dark,
                              }}
                            />
                          )}
                          <AppText
                            bold
                            transparentDark={
                              product.status === ProductStatuses.Deleted
                            }
                          >{`${
                            product.quantity ? product.quantity : 0
                          }`}</AppText>
                        </AppRow>
                      )}
                    </TableCell>

                    <TableCell // TYPE
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          <AppText
                            bold
                            transparentDark={
                              product.status === ProductStatuses.Deleted
                            }
                          >{`${product.type} ${
                            product.type === ProductTypes.Subscription
                              ? `[${product.duration} Days]`
                              : ""
                          }`}</AppText>
                          <AppText
                            transparentDark={
                              product.status === ProductStatuses.Deleted
                            }
                            small
                          >{`${product.deliveryType}`}</AppText>
                        </div>
                        {product.type === ProductTypes.Subscription && (
                          <AppCol style={{ paddingLeft: 20 }}>
                            <AppText
                              bold
                              transparentDark={
                                product.status === ProductStatuses.Deleted
                              }
                            >{`${product.frequency}`}</AppText>
                            <AppText
                              transparentDark={
                                product.status === ProductStatuses.Deleted
                              }
                              small
                            >
                              {product.frequency === FrequencyTypes.Monthly &&
                                (product.monthdays?.length ?? -1) > 0 && (
                                  <span>
                                    {`Every ${join(
                                      product.monthdays?.map((monthday) => {
                                        return find(
                                          monthDays,
                                          (o) => o.value === monthday
                                        )?.label;
                                      }),
                                      " / "
                                    )} of each month`}
                                  </span>
                                )}
                              {product.frequency === FrequencyTypes.Weekly &&
                                (product.weekdays?.length ?? -1) > 0 && (
                                  <span>
                                    {`Every ${join(
                                      product.weekdays?.map((weekday) => {
                                        return find(
                                          weekDays,
                                          (o) => o.value === weekday
                                        )?.label;
                                      }),
                                      " / "
                                    )}`}
                                  </span>
                                )}
                              {product.frequency === FrequencyTypes.Daily && (
                                <span>{`Everyday`}</span>
                              )}
                              {(product.hours?.length ?? -1) > 0 && (
                                <span>
                                  {` at ${join(
                                    product.hours?.map((hour) => {
                                      return formatTimeFromSeconds(
                                        hour[0] * 3600 + hour[1] * 60
                                      );
                                    }),
                                    " / "
                                  )}`}
                                </span>
                              )}
                            </AppText>
                          </AppCol>
                        )}
                      </div>
                    </TableCell>

                    <TableCell
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                      }}
                    >
                      <AppText
                        bold
                        transparentDark={
                          product.status === ProductStatuses.Deleted
                        }
                      >{`${product.name}`}</AppText>
                      <AppText
                        transparentDark={
                          product.status === ProductStatuses.Deleted
                        }
                        small
                      >
                        {join(product.subCategories, " / ")}
                      </AppText>
                    </TableCell>

                    <TableCell // PACK PRODUCT or VARIANTS
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                      }}
                    >
                      {productType === ProductTypes.Item ? (
                        <AppRow style={{ justifyContent: "flex-start" }}>
                          <div>
                            <AppText
                              disabled={
                                product.status === ProductStatuses.Deleted
                              }
                              bold
                            >{`${product.variants?.length} Variant(s)`}</AppText>
                            {product.status !== ProductStatuses.Deleted && (
                              <AppText
                                style={{
                                  fontSize: 10,
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                bold
                                onClick={() =>
                                  setShowVariants({ show: true, product })
                                }
                              >
                                SHOW VARIANT(S)
                              </AppText>
                            )}
                          </div>
                        </AppRow>
                      ) : productType === ProductTypes.Pack ||
                        productType === ProductTypes.Subscription ? (
                        <AppRow style={{ justifyContent: "flex-start" }}>
                          <div>
                            <AppText
                              disabled={
                                product.status === ProductStatuses.Deleted
                              }
                              bold
                            >{`${product.packProducts?.length} Product(s)`}</AppText>
                            {product.status !== ProductStatuses.Deleted && (
                              <AppText
                                style={{
                                  fontSize: 10,
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                bold
                                onClick={() =>
                                  setShowPackProducts({ show: true, product })
                                }
                              >
                                SHOW PRODUCT(S)
                              </AppText>
                            )}
                          </div>
                        </AppRow>
                      ) : (
                        <AppRow
                          style={{ justifyContent: "flex-start" }}
                        ></AppRow>
                      )}
                    </TableCell>

                    <TableCell
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                      }}
                    >
                      <AppRow>
                        <AppCheckBox
                          disabled={product.status === ProductStatuses.Deleted}
                          bold
                          initialValue={product.automaticActivation}
                          // comment={
                          //   product.automaticActivation ? "Automatic" : "Manual"
                          // }
                          onChange={(value) => {
                            if (
                              product.status !== ProductStatuses.Deleted &&
                              value !== product.automaticActivation
                            )
                              update({
                                variables: {
                                  id: product.id,
                                  updateProductInput: {
                                    automaticActivation: value,
                                  },
                                },
                              });
                          }}
                          style={{ marginRight: 5 }}
                        />
                        <div>
                          <AppText
                            bold
                            transparentDark={
                              product.status === ProductStatuses.Deleted
                            }
                          >{`Starts at: ${moment(
                            product.availabilityStartDate
                          ).format("MMM Do YYYY, HH:mm")}`}</AppText>
                          <AppText
                            small
                            transparentDark={
                              product.status === ProductStatuses.Deleted
                            }
                          >{`Ends at: ${moment(
                            product.availabilityEndDate
                          ).format("MMM Do YYYY, HH:mm")}`}</AppText>
                        </div>
                      </AppRow>
                    </TableCell>

                    <TableCell
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                        textAlign: "left",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <AppRow style={{ justifyContent: "flex-start" }}>
                        <FontAwesomeIcon
                          onClick={() => {
                            if (product.status !== ProductStatuses.Deleted)
                              setUpdateRow({
                                id: product.id,
                                show: true,
                                fields: [
                                  "name",
                                  "deliveryType",
                                  "reference",
                                  "segments",
                                  "categories",
                                  "subCategories",
                                  "type",
                                  "description",
                                  "availabilityStartDate",
                                  "availabilityEndDate",
                                  "packProducts",
                                  // "options",
                                  "upsellProducts",
                                  "frequency",
                                  "duration",
                                  "warningDescription",
                                ],
                              });
                          }}
                          data-tip={"Update data"}
                          data-for="updateToolTip"
                          icon={faPencil}
                          style={{
                            margin: "0px 10px 0 0",
                            outline: "none",
                            cursor: "pointer",
                            color:
                              product.status === ProductStatuses.Deleted
                                ? Colors.disabled
                                : displayDetailsCompleted
                                ? Colors.dark
                                : Colors.danger,
                          }}
                        />
                        <FontAwesomeIcon
                          onClick={() => {
                            if (product.status !== ProductStatuses.Deleted)
                              setUpdateImages({
                                product,
                                show: true,
                              });
                          }}
                          data-tip={"Update data"}
                          data-for="updateToolTip"
                          icon={faImage}
                          style={{
                            margin: "0px 10px 0 0",
                            outline: "none",
                            cursor: "pointer",
                            color:
                              product.status === ProductStatuses.Deleted
                                ? Colors.disabled
                                : product.images &&
                                  product.images.length > 0 &&
                                  product.mainImage
                                ? Colors.dark
                                : Colors.danger,
                          }}
                        />
                        <div>
                          <AppText
                            disabled={
                              product.status === ProductStatuses.Deleted
                            }
                            small
                            valid={displayDetailsCompleted}
                            danger={!displayDetailsCompleted}
                          >
                            Display Details
                          </AppText>
                          <AppText
                            disabled={
                              product.status === ProductStatuses.Deleted
                            }
                            bold
                            valid={displayDetailsCompleted}
                            danger={!displayDetailsCompleted}
                          >
                            {displayDetailsCompleted
                              ? "COMPLETE"
                              : "INCOMPLETE"}
                          </AppText>
                        </div>
                      </AppRow>
                    </TableCell>

                    <TableCell
                      scope="row"
                      style={{
                        ...stylesheet.rowText,
                        textAlign: "left",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <AppRow style={{ justifyContent: "flex-start" }}>
                        {!priceDetailsCompleted ? (
                          <FontAwesomeIcon
                            onClick={() => {
                              if (product.status !== ProductStatuses.Deleted)
                                setCreatePrice({
                                  show: true,
                                  productId: product
                                    ? product
                                      ? product.id
                                      : ""
                                    : "",
                                  productVariantId: product.id,
                                });
                            }}
                            data-tip={"Create data"}
                            data-for="createToolTip"
                            icon={faPlus}
                            style={{
                              margin: "0px 10px 0 0",
                              outline: "none",
                              cursor: "pointer",
                              color:
                                product.status === ProductStatuses.Deleted
                                  ? Colors.disabled
                                  : priceDetailsCompleted
                                  ? Colors.dark
                                  : Colors.danger,
                            }}
                          />
                        ) : (
                          <>
                            <AppButton
                              link
                              disabled={
                                product.status === ProductStatuses.Deleted
                              }
                              data-tip={"Delete"}
                              data-for="deleteToolTip"
                              style={{ padding: "0 10px 0 0" }}
                              onClick={() => {
                                console.log(product.prices);
                                if (product.status !== ProductStatuses.Deleted)
                                  setDeletePrice({
                                    priceId:
                                      product.prices &&
                                      product.prices.length > 0
                                        ? product.prices[0].id
                                        : "",
                                    productId: product.id,
                                    show: true,
                                  });
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={
                                  product.status === ProductStatuses.Deleted
                                    ? { color: Colors.disabled }
                                    : { color: "inherited" }
                                }
                              />
                            </AppButton>

                            <ReactTooltip
                              id="deleteToolTip"
                              place="left"
                              multiline
                            />
                          </>
                        )}

                        <div>
                          <div>
                            <AppText
                              bold
                              disabled={
                                product.status === ProductStatuses.Deleted
                              }
                              danger={!priceDetailsCompleted}
                            >
                              {priceDetailsCompleted
                                ? priceDisplay(
                                    (find(product.prices, { country: "FR" })
                                      ?.amountWithTax ?? 0) / 100,
                                    "En",
                                    find(product.prices, { country: "FR" })
                                      ?.currency ?? "EUR"
                                  )
                                : `Please set a Price`}
                            </AppText>

                            {priceDetailsCompleted && (
                              <AppText
                                small
                                disabled={
                                  product.status === ProductStatuses.Deleted
                                }
                              >
                                {`${priceDisplay(
                                  (find(product.prices, { country: "FR" })
                                    ?.amountWithoutTax ?? 0) / 100,
                                  "En",
                                  find(product.prices, { country: "FR" })
                                    ?.currency ?? "EUR"
                                )} + ${
                                  find(product.prices, { country: "FR" })
                                    ?.taxRate
                                }%`}
                              </AppText>
                            )}
                          </div>
                        </div>
                      </AppRow>
                    </TableCell>

                    <TableCell scope="row" style={{ paddingRight: 20 }}>
                      <AppRow style={{ justifyContent: "flex-end" }}>
                        {product.status !== ProductStatuses.Deleted && (
                          <AppTimerButton
                            data-tip={
                              "Delete row data<br/>Press this button for 5s"
                            }
                            data-for="deleteToolTip"
                            style={{ padding: "0 10px 0 0" }}
                            endOfTimeFunction={() => {
                              setDeleteRow({ id: product.id, show: true });
                            }}
                          />
                        )}
                        <ReactTooltip
                          id="deleteToolTip"
                          place="left"
                          multiline
                        />
                      </AppRow>
                    </TableCell>
                  </StyledTableRow>,
                ];
              })}
            </TableBody>
          </Table>
        )}

        <AppRow
          style={{
            justifyContent: "space-between",
          }}
        >
          {/*             <ProductsFiltersForm width={250} />
           */}
          <Table style={{ width: "100%" }}>
            <TableFooter>
              <TableRow>
                <StyledTablePagination
                  labelRowsPerPage={`ROWS PER PAGE`}
                  labelDisplayedRows={(paginationInfo: any) => {
                    return `PAGE ${paginationInfo.page + 1} - FROM ${
                      paginationInfo.from
                    } TO ${paginationInfo.to} - TOTAL ${paginationInfo.count}`;
                  }}
                  count={data?.products.total ?? 0}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 20, 50, 100, 250, 500]}
                  page={page}
                  onPageChange={(e, page: number) => {
                    setPage(page);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    setRowPerPage(parseInt(event.target.value, 10));
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </AppRow>
      </AppCard>

      <AppDialog
        open={createRow}
        onClose={() => setCreateRow(false)}
        scroll="body"
        width={500}
      >
        <CreateItem
          onClose={() => {
            setCreateRow(false);
            refetch();
          }}
          fields={[
            "deliveryType",
            "segments",
            "categories",
            "subCategories",
            "seller",
            "reference",
            "type",
            "name",
            "description",
            "availabilityStartDate",
            "availabilityEndDate",
            "warningDescription",
            "packProducts",
            // "options",
            "upsellProducts",
            "frequency",
            "duration",
          ]}
          initialValues={{
            seller: sellerId,
            type: productType,
            deliveryType: ProductDeliveryTypes.Physical,
          }}
        />
      </AppDialog>

      <AppDialog
        open={updateRow ? updateRow.show : false}
        onClose={() => setUpdateRow(undefined)}
        scroll="body"
        width={500}
      >
        <Update
          id={updateRow?.id}
          initialValues={find(products, { id: updateRow?.id })}
          onClose={() => setUpdateRow(undefined)}
          fields={updateRow?.fields}
        />
      </AppDialog>
      <ReactTooltip place="right" multiline />

      <AppDialog
        open={deleteRow ? deleteRow.show : false}
        onClose={() => setDeleteRow(undefined)}
        scroll="body"
        width={600}
      >
        <Delete id={deleteRow?.id} onClose={() => setDeleteRow(undefined)} />
      </AppDialog>

      <AppDialog
        open={updateImages ? updateImages.show : false}
        onClose={() => setUpdateImages(undefined)}
        scroll="body"
        width={400}
      >
        <UploadImages productId={updateImages?.product.id ?? ""} />
      </AppDialog>

      <AppDialog
        open={showVariants ? showVariants.show : false}
        onClose={() => setShowVariants(undefined)}
        scroll="body"
        width={1600}
      >
        <Variant
          product={showVariants?.product}
          onClose={() => setShowVariants(undefined)}
        />
      </AppDialog>

      <AppDialog
        open={showPackProducts ? showPackProducts.show : false}
        onClose={() => setShowPackProducts(undefined)}
        scroll="body"
        width={1600}
      >
        <PackProducts
          product={showPackProducts?.product}
          onClose={() => setShowPackProducts(undefined)}
        />
      </AppDialog>

      <AppDialog
        open={createPrice ? createPrice.show : false}
        onClose={() => setCreatePrice(undefined)}
        scroll="body"
        width={500}
      >
        <CreatePrice
          initialValues={{
            productId: createPrice?.productId,
            productVariantId: undefined,
          }}
          onClose={() => {
            refetch();
            setCreatePrice(undefined);
          }}
        />
      </AppDialog>

      <AppDialog
        open={deletePrice ? deletePrice.show : false}
        onClose={() => setDeletePrice(undefined)}
        scroll="body"
        width={600}
      >
        <DeletePrice
          id={deletePrice?.priceId}
          onClose={() => {
            refetch();
            setDeletePrice(undefined);
            update({
              variables: {
                id: deletePrice?.productId ?? "",
                updateProductInput: { status: ProductStatuses.NotAvailable },
              },
            });
          }}
        />
      </AppDialog>
    </div>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTablePagination = withStyles({
  root: { bproductBottom: 0, color: Colors.transparentDark, fontSize: 12 },
  caption: { color: Colors.transparentDark, fontSize: 10 },
  selectIcon: { color: Colors.transparentDark, fontSize: 22 },
  // MuiButtonBase: { root: { outline: 'none' } }
  // overrides: {
  //     MuiButtonBase: { root: { outline: 'none' } }
  // }
})(TablePagination);

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
