import { push } from "connected-react-router";
import { get } from "lodash";
import React, { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { Signin } from "../signin/Signin";
import styles from "./Welcome.module.scss";

export interface IFuncCompProps {}

export const Welcome: FunctionComponent<IFuncCompProps> = () => {
  //

  /**********************************************************
   *** NAVIGATION ***
   **********************************************************/

  const dispatch = useDispatch();
  const pushUrl = useCallback(
    (location: string) => {
      dispatch(push(location));
    },
    [dispatch]
  );

  /**********************************************************
   *** AUTH CHECK ***
   **********************************************************/

  const authToken = useSelector((state: RootState) =>
    get(state, "data.auth.accessToken", undefined)
  );
  useEffect(() => {
    if (authToken) pushUrl("/dashboard");
  }, [authToken, pushUrl]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <div className={styles.App}>
      <Signin />
    </div>
  );
};
