import { indexOf } from "lodash";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  Field,
  FieldArray,
  Form,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { CategoryStatuses } from "../../../graphql/generated/types";
import {
  AppButton,
  AppCard,
  AppCol,
  AppFormDateTimePicker,
  AppFormPicker,
  AppFormTextField,
  AppRow,
  AppText,
} from "../../components";
import { AppFormCheckBox } from "../../components/ReduxFormComponents/AppFormCheckBox";
import { FieldArrayDisplayProducts } from "./FieldArrays";

export interface IUpdateBaseFormProps {
  initialValues: any;
  loading?: boolean;
  fields?: string[];
  submitButtonString: any;
}

export type IUpdateFormProps = IUpdateBaseFormProps &
  InjectedFormProps<any, IUpdateBaseFormProps>;

const ReduxForm: FunctionComponent<IUpdateFormProps> = ({
  initialValues,
  loading,
  handleSubmit,
  error,
  fields,
  submitButtonString,
}) => {
  const { t } = useTranslation();

  const segmentStatusOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(CategoryStatuses)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    segmentStatusOptions.push({ value: propertyValue, label: propertyKey });
  }

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}

      <AppCard
        style={{
          margin: "20px 0",
        }}
      >
        {(!fields || indexOf(fields, "status") !== -1) && (
          <Field
            name="status"
            component={AppFormPicker}
            firstOptionAsDefaultValue={initialValues.type}
            options={segmentStatusOptions}
            label={t("fieldLabel.status", "Status")}
            //   help={t("fieldHelp.resourceType", "Type of Account")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {!initialValues && (
          <AppRow>
            <Field
              name="hasSorting"
              component={AppFormCheckBox}
              label={t("fieldLabel.hasSorting", "Sort?")}
              //   help={t("fieldHelp.firstname", )}
              autoFocus
              style={{ marginBottom: 20 }}
            />
            <AppCol style={{ width: "100%" }}>
              <Field
                name="sorting"
                component={AppFormTextField}
                label={t("fieldLabel.sorting", "Sorting")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                autoFocus
                style={{ marginBottom: 20 }}
              />
            </AppCol>
          </AppRow>
        )}

        {(!fields || indexOf(fields, "name") !== -1) && (
          <Field
            name="name"
            component={AppFormTextField}
            label={t("fieldLabel.segmentName", "Category Name")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "description") !== -1) && (
          <Field
            name="description"
            component={AppFormTextField}
            label={t("fieldLabel.description", "Description")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "availabilityStartDate") !== -1) && (
          <Field
            name="availabilityStartDate"
            component={AppFormDateTimePicker}
            label={t("fieldLabel.availabilityStartDate", "Starts at")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "availabilityEndDate") !== -1) && (
          <Field
            name="availabilityEndDate"
            component={AppFormDateTimePicker}
            label={t("fieldLabel.availabilityEndDate", "Ends at")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "displayProducts") !== -1) && (
          <FieldArray
            name="displayProducts"
            component={FieldArrayDisplayProducts as any}
            props
          />
        )}
      </AppCard>

      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}
      <AppButton primary valid type="submit" loading={loading}>
        {submitButtonString}
      </AppButton>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: "categoryForm",
})(ReduxForm);
