import { createTheme, ThemeProvider, withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Colors } from "../../../styles/Colors";
import { MaterialTheme } from "../../../styles/MaterialTheme";
import theme from "../../../styles/Theme.module.scss";

interface option {
  value: string;
  label: string;
}
export interface IAppPickerProps {
  options: option[];
  firstOptionAsDefaultValue?: boolean;
  label?: string;
  help?: string;
  disableUnderline?: boolean;
  hideValue?: boolean;
  replacedValue?: string;
  light?: boolean;
  valid?: boolean;
  danger?: boolean;
  style?: React.CSSProperties;
  initialValue?: any;
  onChange: (value: any) => void;
  className?: string;
}

export const AppPicker: FunctionComponent<IAppPickerProps> = ({
  firstOptionAsDefaultValue,
  help,
  label,
  style,
  options,
  initialValue,
  disableUnderline,
  light,
  onChange,
  className,
}) => {
  const [optionValue, setOptionValue] = useState<string>("");

  const handleChange = (event: any) => {
    setOptionValue(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    if (initialValue) {
      setOptionValue(initialValue);
    }
    if (firstOptionAsDefaultValue && !optionValue) {
      setOptionValue(options[0].value);
      onChange(options[0].value);
    }
  }, [onChange, optionValue, firstOptionAsDefaultValue, options, initialValue]);

  const StyledSelect = withStyles({
    root: {
      color: light ? Colors.light : Colors.dark,
      borderBottomWidth: disableUnderline ? 0 : undefined,
    },
    icon: { color: light ? Colors.light : Colors.dark },
  })(Select);

  const materialTheme = createTheme(MaterialTheme(light));

  return (
    <ThemeProvider theme={materialTheme}>
      <div style={{ ...style }} className={className}>
        {label && (
          <div
            style={{ marginTop: -2 }}
            className={`${theme.labelStyle}  ${theme.bold}
                            ${light ? theme.colorLight : theme.colorDark}`}
          >
            {label}
          </div>
        )}

        <StyledSelect
          className={`container-fluid `}
          style={{
            display: "flex",
            textAlign: "left",
            padding: 0,
            color: light ? Colors.light : Colors.dark,
          }}
          value={optionValue}
          onChange={handleChange}
          disableUnderline={disableUnderline}
        >
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </StyledSelect>

        {help && (
          <span
            className={`${theme.smallTextStyle} ${theme.bold} ${
              light ? theme.colorTransparentLight : theme.colorTransparentDark
            }`}
          >
            {help}
          </span>
        )}
      </div>
    </ThemeProvider>
  );
};
