import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Colors } from "../../styles/Colors";
import theme from "../../styles/Theme.module.scss";
import { AppLoader } from "./AppLoader";

export interface IAppButtonProps {
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string;
  endOfTimeFunction: () => void;
  icon?: any;
  style?: React.CSSProperties;
  light?: boolean;
  valid?: boolean;
  danger?: boolean;
  warning?: boolean;
}

export const AppTimerButton: React.FC<IAppButtonProps> = ({
  disabled,
  loading,
  icon = faTrashAlt,
  endOfTimeFunction,
  tooltip,
  style,
  light,
  valid,
  danger,
  warning,
}) => {
  const colorClassName = `${
    valid
      ? theme.colorValid
      : danger
      ? theme.colorDanger
      : warning
      ? theme.colorWarning
      : light
      ? theme.colorLight
      : theme.colorDark
  }`;

  /**********************************************************
   *** DELETE TIMER ***
   **********************************************************/
  const [startTimer, setStartTimer] = useState<Moment | undefined>(undefined);
  const [stateTimer, setStateTimer] = useState<number>(5);

  const timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (startTimer) {
      setStateTimer(5);
      timer.current = setInterval(() => {
        setStateTimer(5 - (moment().unix() - moment(startTimer).unix()));
      }, 1000);
    } else {
      if (timer.current !== undefined) clearInterval(timer.current);
      setStateTimer(5);
    }
  }, [startTimer]);
  useEffect(() => {
    if (stateTimer === 0) {
      if (timer.current !== undefined) clearInterval(timer.current);
      endOfTimeFunction();
      setStateTimer(5);
      setStartTimer(undefined);
    }
  }, [stateTimer, endOfTimeFunction]);

  const fontSize: any = style?.fontSize;

  return (
    <div
      className={`${theme.alignItemsCenter} ${theme.justifyContentCenter} ${theme.textAlignCenter} ${theme.cursorPointer}`}
      onMouseDown={() => {
        if (!disabled) setStartTimer(moment());
      }}
      onMouseLeave={() => {
        if (!disabled) setStartTimer(undefined);
      }}
      onMouseUp={() => {
        if (!disabled) setStartTimer(undefined);
      }}
    >
      {loading ? (
        <AppLoader size={fontSize ? fontSize - 2 : 12} />
      ) : (
        <div className={`${colorClassName}`}>
          {startTimer ? (
            <div
              className={`${colorClassName} ${theme.bold}`}
              style={{ fontSize: fontSize ?? 16, paddingRight: 3, ...style }}
            >
              {stateTimer}
            </div>
          ) : (
            <FontAwesomeIcon
              data-tip={
                disabled
                  ? "Action not available"
                  : tooltip ?? "Hold your click for 5 seconds"
              }
              data-for="buttonToolTip"
              icon={icon}
              style={
                disabled
                  ? { color: Colors.disabled, ...style }
                  : { color: "inherited", ...style }
              }
            />
          )}
        </div>
      )}
      <ReactTooltip id="buttonToolTip" place="left" multiline />
    </div>
  );
};
