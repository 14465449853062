import MomentUtils from "@date-io/moment";
import { faCalendar, faClock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
import { CSSProperties, ThemeProvider } from "@material-ui/styles";
import "date-fns";
import { split } from "lodash";
import moment, { Moment } from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { WrappedFieldProps } from "redux-form";
import { Colors } from "../../../styles/Colors";
import { MaterialTheme } from "../../../styles/MaterialTheme";
import theme from "../../../styles/Theme.module.scss";
import { AppRow } from "../AppRow";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}
export interface IAppFormDateTimePickerProps {
  label: string;
  mode?: "datetime" | "date" | "time";
  light?: boolean;
  help?: string;
  minDate?: Moment;
  maxDate?: Moment;
  fieldFormat: string;
  style: CSSProperties;
}

export const AppFormDateTimePicker: FunctionComponent<
  TextFieldProps & WrappedFieldProps & IAppFormDateTimePickerProps
> = (props) => {
  const {
    mode,
    light,
    help,
    input,
    label,
    meta: { touched, error, warning },
    minDate,
    maxDate,
    fieldFormat,
    style,
  } = props;
  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [selectedDate, setSelectedDate] = useState<any>(null);

  /**********************************************************
   *** LIFE CYCLE ***
   **********************************************************/

  useEffect(() => {
    if (input.value) {
      let formatedTime = input.value;
      if (mode === "time") {
        const timeArray = split(input.value, ":");
        formatedTime = moment()
          .hour(parseInt(timeArray[0]))
          .minute(parseInt(timeArray[1]))
          .second(0)
          .toJSON();
      }
      setSelectedDate(formatedTime);
    }
  }, [input, mode]);

  const materialTheme = createTheme(MaterialTheme(light));

  return (
    <div style={{ ...style }}>
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid>
            {(!mode || mode === "datetime") && (
              <AppRow>
                <AppRow
                  style={{
                    position: "absolute",
                    right: 0,
                    marginTop: 10,
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCalendar}
                    color={light ? Colors.light : Colors.dark}
                    style={{ marginRight: 5 }}
                  />
                  <FontAwesomeIcon
                    icon={faClock}
                    color={light ? Colors.light : Colors.dark}
                    style={{ marginTop: 1 }}
                  />
                </AppRow>
                <DateTimePicker
                  className={`container-fluid`}
                  style={{ width: "100%" }}
                  ampm={false}
                  value={selectedDate}
                  onChange={(date) => {
                    input.onChange(date);
                  }}
                  label={label}
                  // onError={console.log}
                  minDate={
                    minDate ? new Date(minDate.toISOString()) : undefined
                  }
                  maxDate={
                    maxDate ? new Date(maxDate.toISOString()) : undefined
                  }
                  format={fieldFormat}
                />
              </AppRow>
            )}

            {mode && mode === "date" && (
              <AppRow>
                <AppRow
                  style={{
                    position: "absolute",
                    right: 0,
                    marginTop: 10,
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCalendar}
                    color={light ? Colors.light : Colors.dark}
                    style={{ marginRight: 5 }}
                  />
                </AppRow>
                <DatePicker
                  className={`container-fluid`}
                  label={label}
                  format={fieldFormat}
                  value={selectedDate}
                  onChange={(date) => input.onChange(date)}
                  minDate={
                    minDate ? new Date(minDate.toISOString()) : undefined
                  }
                  maxDate={
                    maxDate ? new Date(maxDate.toISOString()) : undefined
                  }
                />
              </AppRow>
            )}

            {mode && mode === "time" && (
              <AppRow>
                <AppRow
                  style={{
                    position: "absolute",
                    right: 0,
                    marginTop: 10,
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClock}
                    color={light ? Colors.light : Colors.dark}
                    style={{ marginTop: 1 }}
                  />
                </AppRow>
                <TimePicker
                  ampm={false}
                  className={`container-fluid`}
                  label={label}
                  format="HH:mm"
                  value={selectedDate}
                  onChange={(date) => {
                    input.onChange(moment(date).format("HH:mm"));
                  }}
                />
              </AppRow>
            )}
          </Grid>
        </MuiPickersUtilsProvider>
        {(help ?? (touched && (error || warning))) && (
          <span>
            {touched && error && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorDanger}`}
              >
                {error}
              </small>
            )}
            {touched && warning && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorWarning}`}
              >
                {warning}
              </small>
            )}
            {help && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${
                  light
                    ? theme.colorTransparentLight
                    : theme.colorTransparentDark
                }`}
              >
                {help}
              </small>
            )}
          </span>
        )}
      </ThemeProvider>
    </div>
  );
};
