import { get } from "lodash";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { GoogleMap, Polygon, withGoogleMap } from "react-google-maps";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { useUpdateSellerMutation } from "../../graphql/generated/types";
import { apiErrors } from "../../helpers/Errors";
import { RootState } from "../../redux";
import { Colors } from "../../styles/Colors";
import { mapStyles } from "../../styles/MapStyles";
import { AppButton, AppRow, AppSelector } from "../components";

export interface LatLng {
  lat: number;
  lng: number;
}

export interface IPolygonAreaProps {
  initialPolygons?: any;
  onClose: () => void;
  refetch: () => void;
  sellerId: string;
}

const PolygonAreaComp: FunctionComponent<IPolygonAreaProps> = ({
  initialPolygons,
  onClose,
  refetch,
  sellerId,
}) => {
  const googleMapRef = useRef<GoogleMap>(null);

  const [polygon, setPolygon] = useState<any>([]);
  const [polygonData, setPolygonData] = useState<any>([]);
  const [areaIndex, setAreaIndex] = useState<number>(0);
  const [addNewZone, setAddNewZone] = useState<boolean>(false);
  const mapState = useSelector((state: RootState) => state.data.map.state);

  const polygonRef: any = [];
  const _polyRef = (ref: any) => {
    polygonRef.push(ref);
  };

  const fitBoundsFromPath = (path: any) => {
    const map = googleMapRef.current;

    const bounds = new google.maps.LatLngBounds();

    path.map((coords: any, i: number) => {
      const latLng = new google.maps.LatLng(coords[1], coords[0]);
      bounds.extend(latLng);
      return null;
    });

    if (map) {
      const padding = {
        top: 25,
        bottom: 25,
        left: 25,
        right: 25,
      };
      map.fitBounds(bounds, padding);
    }
  };

  const areaBtnClicked = (area: any, index: any) => {
    setPolygon(area.polygon);
    setAreaIndex(index);
    fitBoundsFromPath(initialPolygons[index]);
  };

  const _onMousedown = (refIndex: number, newDrawing: boolean) => {
    // get the reference of the polygon then used the ref as it's index so that you can get the specific polygon
    const polygon = polygonRef[refIndex].getPath();
    // add event listeners for the polygon changes and pass the polygon as parameter to the function you need, you also need to pass the ref (or index)
    google.maps.event.addListener(polygon, "set_at", () => {
      _getPolygonNewPaths(polygon, newDrawing);
    });
    google.maps.event.addListener(polygon, "insert_at", () => {
      _getPolygonNewPaths(polygon, newDrawing);
    });
    google.maps.event.addListener(polygon, "remove_at", () => {
      _getPolygonNewPaths(polygon, newDrawing);
    });
  };

  const _getPolygonNewPaths = (polygon: any, newDrawing: any) => {
    const polygonPaths: any = [];

    polygon.getArray().forEach((path: any) => {
      const line = {
        lat: path.lat(),
        lng: path.lng(),
      };
      polygonPaths.push(line);
    });

    if (newDrawing) {
      setDrawnPolygon(polygonPaths);
    } else {
      const newPolygon = polygonData;
      newPolygon[areaIndex].polygon = polygonPaths;
      setPolygonData(newPolygon);
    }
  };

  /**********************************************************
   *** LIFECYCLE ***
   **********************************************************/

  useEffect(() => {
    console.log(initialPolygons);
    const polygonData = initialPolygons.map((polygon: any) => {
      return {
        polygon: polygon.map((coord: any) => {
          return { lng: coord[0], lat: coord[1] };
        }),
      };
    });
    setPolygonData(polygonData);
    setPolygon(polygonData.length > 0 ? polygonData[0].polygon : []);
    setAreaIndex(0);

    if (initialPolygons && initialPolygons.length > 0)
      fitBoundsFromPath(initialPolygons[0]);
  }, [initialPolygons]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  const [update] = useUpdateSellerMutation({
    onCompleted: () => {
      if (refetch) refetch();
      onClose();
    },
    onError: (e: any) => {
      console.log(JSON.parse(JSON.stringify(e)));
    },
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/
  const [drawnPolygon, setDrawnPolygon] = useState<any>([]);

  const getDawnPolygonPaths = (polygon: any) => {
    const polygonBounds = polygon.getPath();
    const bounds: any[] = [];
    for (let i = 0; i < polygonBounds.length; i++) {
      const point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
    }

    setDrawnPolygon(bounds);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <GoogleMap
        ref={googleMapRef}
        defaultCenter={mapState.center}
        defaultZoom={mapState.zoom}
        defaultOptions={{
          panControl: false,
          streetViewControl: false,
          styles: mapStyles,
          zoomControl: true,
        }}
      >
        {addNewZone && (
          <>
            <DrawingManager
              defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
              defaultOptions={{
                drawingControl: true,
                drawingControlOptions: {
                  position: google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                },
                polygonOptions: {
                  editable: false,
                  draggable: false,
                  visible: true,
                  strokeColor: Colors.warning,
                  strokeWeight: 3,
                  fillColor: Colors.warning,
                  fillOpacity: 0.2,
                },
              }}
              onPolygonComplete={(value) => {
                getDawnPolygonPaths(value);
                value.setMap(null);
              }}
            />
          </>
        )}

        {addNewZone && drawnPolygon.length > 0 && (
          <Polygon
            ref={(ref) => {
              _polyRef(ref);
            }}
            paths={drawnPolygon}
            editable
            onMouseDown={() => {
              _onMousedown(0, true);
            }}
            options={{
              strokeColor: Colors.danger,
              strokeWeight: 3,
              fillColor: Colors.danger,
              fillOpacity: 0.2,
            }}
          />
        )}

        {!addNewZone && (
          <Polygon
            ref={(ref) => {
              _polyRef(ref);
            }}
            paths={polygon}
            onMouseDown={() => {
              _onMousedown(0, false);
            }}
            editable
            options={{
              strokeColor: Colors.warning,
              strokeWeight: 3,
              fillColor: Colors.warning,
              fillOpacity: 0.2,
            }}
          />
        )}
      </GoogleMap>
      <AppRow
        style={{
          justifyContent: "space-between",
          width: "100%",
          paddingTop: 10,
        }}
      >
        <AppSelector
          initialPosition={`Area #1`}
          positions={[
            ...polygonData.map(
              (area: any, index: number) => `Area #${index + 1}`
            ),
            "DRAW NEW ZONE",
          ]}
          setPositionState={(area) => {
            if (area !== "DRAW NEW ZONE") {
              setAddNewZone(false);
              areaBtnClicked(
                polygonData[parseInt(area.slice(-1), 10) - 1],
                parseInt(area.slice(-1), 10) - 1
              );
            } else setAddNewZone(true);
          }}
        />

        <AppRow>
          <AppButton
            pill
            onClick={() => {
              onClose();
            }}
          >
            {`CANCEL`}
          </AppButton>
          <AppButton
            pill
            warning
            onClick={async () => {
              console.log(drawnPolygon);
              const drawPolygonAsGeoJSON = drawnPolygon.map(
                (polygon: any, index: number) => [polygon.lng, polygon.lat]
              );

              let newPolygon;
              if (drawnPolygon.length > 0)
                newPolygon = [
                  ...drawPolygonAsGeoJSON,
                  [drawnPolygon[0].lng, drawnPolygon[0].lat],
                ];

              console.log(newPolygon);

              const oldPolygon = polygonData[0].polygon.map(
                (polygon: any, index: number) => [polygon.lng, polygon.lat]
              );
              console.log(oldPolygon);

              try {
                await update({
                  variables: {
                    id: sellerId,
                    deliveryAreaPolygon: {
                      type: "Polygon",
                      coordinates:
                        drawnPolygon.length > 0 ? [newPolygon] : [oldPolygon],
                    },
                  },
                });
              } catch (e: any) {
                throw new SubmissionError(
                  apiErrors({
                    errors: get(
                      e,
                      "graphQLErrors[0].extensions.exception.errors",
                      []
                    ),
                    message: e.message,
                  })
                );
              }
            }}
          >
            {`SAVE ZONE`}
          </AppButton>
        </AppRow>
      </AppRow>
    </div>
  );
};

export const PolygonArea = withGoogleMap(PolygonAreaComp);
