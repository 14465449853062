import moment from "moment";
import React, { FunctionComponent } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { AppDetails } from "./AppDetails";
import { AppFormCheckBox } from "./ReduxFormComponents/AppFormCheckBox";
import { AppFormColorPicker } from "./ReduxFormComponents/AppFormColorPicker";
import { AppFormDateTimePicker } from "./ReduxFormComponents/AppFormDateTimePicker";
import { AppFormPhoneNumberField } from "./ReduxFormComponents/AppFormPhoneNumberField";
import { AppFormPicker } from "./ReduxFormComponents/AppFormPicker";
import { AppFormRadio } from "./ReduxFormComponents/AppFormRadio";
import { AppFormSwitch } from "./ReduxFormComponents/AppFormSwitch";
import { AppFormTextField } from "./ReduxFormComponents/AppFormTextField";

export interface IAppReduxFormBaseProps {
  loadingSimulation?: boolean;
  darkMode?: boolean;
  submitButtonString: string;
}
export type IAppReduxFormProps = IAppReduxFormBaseProps &
  InjectedFormProps<any, IAppReduxFormBaseProps>;

const AppReduxFormComp: FunctionComponent<IAppReduxFormProps> = ({
  darkMode,
}) => {
  // const { loadingSimulation, darkMode, handleSubmit, error } = props;
  return (
    <div style={{ padding: "0px 0px 100px 0px" }}>
      <Field
        name="appFormDateTimePicker"
        component={AppFormDateTimePicker}
        mode="datetime"
        fieldFormat="dddd, MMMM Do - HH:mm"
        minDate={moment().add(5, "minutes")}
        maxDate={moment().add(3, "month")}
        light={darkMode}
        label="Label"
        locale="fr-FR"
        help={"Help me if you can"}
      />

      <Field
        style={{ marginTop: 20 }}
        name="appFormTextField"
        component={AppFormTextField}
        light={darkMode}
        label="Label"
        help={"Help me if you can"}
        startAdornment={
          <AppDetails bold style={{ marginRight: 5 }}>{`€`}</AppDetails>
        }
        placeholder={"Placeholder"}
      />

      <Field
        style={{ marginTop: 20 }}
        name="appFormPhoneNumberField"
        component={AppFormPhoneNumberField}
        light={darkMode}
        label={"Label"}
        help={"Help me if you can"}
      />
      <Field
        style={{ marginTop: 20 }}
        name="appFormPicker"
        component={AppFormPicker}
        light={darkMode}
        firstOptionAsDefaultValue
        options={[
          { label: "First label", value: "First value" },
          { label: "Second label", value: "Second value" },
        ]}
        label={"Label"}
        help={"Help me if you can"}
      />
      <Field
        bold
        name="appFormCheckbox"
        style={{ margin: "20px 0" }}
        component={AppFormCheckBox}
        light={darkMode}
        comment={
          "Cash rules everything around me, C.R.E.A.M get the money, dollar dollar billion. Cash rules everything around me, C.R.E.A.M get the money, dollar dollar billion."
        }
        label={"Label"}
        help={"Help me if you can"}
      />

      <Field
        name="appFormRadio"
        component={AppFormRadio}
        light={darkMode}
        firstOptionAsDefaultValue
        options={[
          { label: "First label", value: "First value" },
          { label: "Second label", value: "Second value" },
        ]}
        label={"Label"}
        help={"Help me if you can"}
      />

      <Field
        style={{ marginTop: 20 }}
        size={50}
        name="appFormColorPicker"
        component={AppFormColorPicker}
        light={darkMode}
        label={"Label"}
        help={"Help me if you can"}
      />

      <Field
        style={{ marginTop: 20 }}
        size={50}
        name="appFormSwitch"
        component={AppFormSwitch}
        options={[
          { label: "Option 1", value: "Value 1" },
          { label: "Option 2", value: "Value 2" },
        ]}
        light={darkMode}
        label={"Label"}
        help={"Help me if you can"}
      />
    </div>
  );
};

export const AppReduxFormComponents = reduxForm<any, IAppReduxFormBaseProps>({
  form: "appReduxFormComponents",
})(AppReduxFormComp);
