import { faSignOutAlt, faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { User, useUserQuery } from "../../../graphql/generated/types";
import { actions } from "../../../redux";
import { Colors } from "../../../styles/Colors";
import { AppButton } from "../AppButton";
import { AppRow } from "../AppRow";
import { AppText } from "../AppText";
export interface IHeader {
  logo: any;
  menuItems: Array<{ name: string; path: string }>;
  logout: () => void;
}

export const AppHeader: React.FC<IHeader> = ({ menuItems, logout, logo }) => {
  const dispatch = useDispatch();
  const pushUrl = useCallback(
    (location) => {
      dispatch(push(location));
    },
    [dispatch]
  );

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const saveUser = useCallback(
    (user: User) => {
      dispatch(actions.setState(user, `auth.user`));
    },
    [dispatch]
  );
  const deleteAuth = useCallback(() => {
    dispatch(actions.setState({}, `auth`));
  }, [dispatch]);

  const { data } = useUserQuery({
    onCompleted: (data) => {
      saveUser(data.user);
    },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
      if (e.message === "Unauthorized") {
        localStorage.removeItem("token");
        deleteAuth();
        pushUrl("/");
      }
    },
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  const Separator = () => (
    <div
      style={{
        height: 25,
        borderWidth: 0,
        borderLeftWidth: 1,
        borderColor: Colors.transparentLight,
        borderStyle: "solid",
        margin: "0 15px",
      }}
    />
  );

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        zIndex: 100,
        display: "flex",
        justifyContent: "space-between",
        margin: 0,
        left: 0,
        top: 0,
        height: "5vh",
        backgroundColor: "black",
      }}
    >
      <AppRow>
        <NavLink to="/">
          {logo ? (
            <img
              src={logo}
              style={{
                height: 15,
                margin: "14px 40px 12px 40px",
              }}
              alt="logo"
            />
          ) : (
            <AppText bold danger>
              No Logo Uploaded
            </AppText>
          )}
        </NavLink>

        <AppRow>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <AppButton light link onClick={() => pushUrl(item.path)}>
                {item.name}
              </AppButton>
              {index !== menuItems.length - 1 && <Separator />}
            </React.Fragment>
          ))}
        </AppRow>
      </AppRow>

      <AppRow style={{ margin: 15 }}>
        <AppRow>
          <AppButton
            link
            onClick={() => {
              push("/account/");
            }}
          >
            <FontAwesomeIcon icon={faUserCircle} size="2x" />
          </AppButton>
          <AppButton link light onClick={() => push("/account/")}>
            {data?.user.firstName}
          </AppButton>
          <Separator />
          <AppButton link light onClick={() => logout()}>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              // className={`theme.colorLight`}
              size="lg"
            />
          </AppButton>
        </AppRow>
      </AppRow>
    </div>
  );
};
