import { TFunction } from "i18next";

export const validators = (t: TFunction) => {
  return {
    email: {
      presence: { message: t("validator.message.required") },
      email: { message: t("validator.message.invalid") },
    },
    password: {
      presence: { message: t("validator.message.required") },
      length: {
        minimum: 1,
        tooShort: t("validator.message.required"),
        maximum: 20,
        tooLong: t("validator.message.max20char"),
      },
    },
  };
};
