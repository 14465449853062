export const Fonts = {
    regular: 'Roboto-Light',
    medium: 'Roboto-Regular',
    light: 'Roboto-Light',
    thin: 'Roboto-Light',

    pictoRegular: 'FontAwesome5ProRegular',
    pictoThin: 'FontAwesome5ProLight'
};

export const PaperFonts = {
    regular: { fontFamily: 'Roboto-Regular' },
    medium: { fontFamily: 'Roboto-Regular' },
    light: { fontFamily: 'Roboto-Light' },
    thin: { fontFamily: 'Roboto-Light' }
};
