import { get } from "lodash";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SubmissionError } from "redux-form";
import {
  Order,
  useUpdateOrderMutation,
} from "../../../graphql/generated/types";
import { deepOmit } from "../../../helpers/DeepOmit";
import { apiErrors } from "../../../helpers/Errors";
import { AppRow, AppTitle } from "../../components";
import UpdateForm from "./Form";

export interface IUpdateProps {
  id: any;
  initialValues: Order;
  fields?: string[];
  refetch?: () => void;
  onClose: () => void;
}

export const Update: FunctionComponent<IUpdateProps> = ({
  initialValues,
  onClose,
  refetch,
  fields,
  id,
}) => {
  const { t } = useTranslation();

  /**********************************************************
   *** UPDATE PROCESS ***
   **********************************************************/

  const [update, { loading }] = useUpdateOrderMutation({
    onCompleted: () => {
      if (refetch) refetch();
      onClose();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  console.log(initialValues);

  const newInitialValues = {
    ...deepOmit(initialValues, "__typename"),
    status: initialValues.status,
    messenger: initialValues.messenger?.id,
  };

  return (
    <>
      <AppRow
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <AppTitle
          small
          extrabold
          light
          subtitle={t("update.subtitle", "Please change the needed fields")}
        >
          {t("update.title", "Update")}
        </AppTitle>
      </AppRow>

      <UpdateForm
        submitButtonString={t("button.modify", "UPDATE")}
        loading={loading}
        fields={fields}
        initialValues={newInitialValues}
        refetch={refetch}
        // validate={(values: any) =>
        //   validate(values, validators(t), { fullMessages: false })
        // }
        onSubmit={async (values: any) => {
          // console.log("VALUES", values);

          try {
            await update({
              variables: {
                orderId: id,
                updateOrderInput: {
                  messenger: values.messenger,
                  status: values.status,
                },
              },
            });
          } catch (e: any) {
            throw new SubmissionError(
              apiErrors({
                errors: get(
                  e,
                  "graphQLErrors[0].extensions.exception.errors",
                  []
                ),
                message: e.message,
              })
            );
          }
        }}
      />
    </>
  );
};
