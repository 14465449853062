import { CircularProgress } from "@material-ui/core";
import React from "react";
import theme from "../../styles/Theme.module.scss";
import styles from "./AppButton.module.scss";

export interface IAppButtonProps {
  id?: any;
  primary?: boolean;
  picto?: boolean;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  link?: boolean;
  pill?: boolean;
  className?: string;
  loading?: boolean;
  dark?: boolean;
  light?: boolean;
  transparentDark?: boolean;
  transparentLight?: boolean;
  valid?: boolean;
  danger?: boolean;
  warning?: boolean;
  transparent?: boolean;
  borderWidth?: number;
  right?: boolean;
  center?: boolean;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  children: React.ReactNode;
  onClick?: (value: any | undefined) => void;
  classNameTextStyle?: string;
}

export const AppButton: React.FC<IAppButtonProps> = ({
  id,
  primary,
  picto,
  type,
  disabled,
  small,
  extraSmall,
  link,
  pill,
  className,
  loading,
  right,
  center,
  style,
  textStyle,
  children,
  danger,
  warning,
  transparent,
  transparentDark,
  transparentLight,
  light,
  valid,
  onClick,
  borderWidth,
  classNameTextStyle,
}) => {
  const colorClassName =
    primary ?? (pill && !borderWidth)
      ? `${
          disabled
            ? theme.colorDisabled
            : valid
            ? theme.colorLight
            : danger
            ? theme.colorLight
            : warning
            ? theme.colorDark
            : light
            ? theme.colorDark
            : theme.colorLight
        }`
      : `${
          disabled
            ? theme.colorDisabled
            : valid
            ? theme.colorValid
            : danger
            ? theme.colorDanger
            : warning
            ? theme.colorWarning
            : light
            ? theme.colorLight
            : transparentDark
            ? theme.colorTransparentDark
            : transparentLight
            ? theme.colorTransparentLight
            : theme.colorDark
        }`;

  const backgroundColorClassName = borderWidth
    ? `${theme.bgColorTransparent}`
    : primary ?? link ?? pill
    ? `${
        disabled
          ? theme.bgColorDisabled
          : valid
          ? theme.bgColorValid
          : danger
          ? theme.bgColorDanger
          : warning
          ? theme.bgColorWarning
          : light
          ? theme.bgColorLight
          : transparent
          ? theme.bgColorTransparent
          : transparentDark
          ? theme.bgColorTransparentDark
          : transparentLight
          ? theme.bgColorTransparentLight
          : theme.bgColorDark
      }`
    : `${theme.bgColorTransparent}`;

  const borderColorClassName = `${
    disabled
      ? theme.borderColorDisabled
      : valid
      ? theme.borderColorValid
      : danger
      ? theme.borderColorDanger
      : warning
      ? theme.borderColorWarning
      : light
      ? theme.borderColorLight
      : transparentDark
      ? theme.borderColorTransparentDark
      : transparentLight
      ? theme.borderColorTransparentLight
      : theme.borderColorDark
  }`;

  const fontStyleClassName = extraSmall
    ? `${theme.smallTextStyle} ${theme.bold}`
    : primary
    ? `${theme.labelStyle} ${theme.bold}`
    : `${theme.labelStyle} ${theme.bold}`;

  const getPaddingVertical = () => {
    if (primary)
      return loading ? theme.paddingVertical15 : theme.paddingVertical20;
    if (link) return loading ? theme.paddingVertical9 : theme.paddingVertical10;
    if (pill && !small && !extraSmall)
      return loading ? theme.paddingVertical5 : theme.paddingVertical10;
    if (pill && small && !extraSmall)
      return loading ? theme.paddingVertical3 : theme.paddingVertical5;
    if (pill && !small && extraSmall)
      return loading ? theme.paddingVertical1 : theme.paddingVertical3;

    return loading ? theme.paddingVertical15 : theme.paddingVertical20;
  };

  const getClassName = () => {
    if (primary)
      return `${styles.buttonPrimary}
                ${backgroundColorClassName}
                ${colorClassName}
                ${getPaddingVertical()}
                ${fontStyleClassName}                 
                ${borderWidth ? borderColorClassName : ""}`;
    if (link)
      return `${theme.bgColorTransparent} 
                ${theme.borderColorTransparent}
                ${colorClassName}                  
                ${getPaddingVertical()}
                ${styles.link} ${fontStyleClassName}
                ${
                  right
                    ? theme.justifyContentEnd
                    : center
                    ? theme.justifyContentCenter
                    : theme.justifyContentStart
                }`;
    if (pill)
      return `${styles.pill}
                ${backgroundColorClassName}
                ${colorClassName}
                ${getPaddingVertical()} 
                ${fontStyleClassName}
                ${borderWidth ? borderColorClassName : ""}`;

    return `${styles.buttonSecondary}
                ${borderColorClassName}
                ${backgroundColorClassName}
                ${colorClassName}
                ${theme.bgColorTransparent}
                ${fontStyleClassName}
                ${getPaddingVertical()}`;
  };

  return (
    <div
      className={`
                ${styles.container} 
                ${
                  right
                    ? theme.justifyContentEnd
                    : center
                    ? theme.justifyContentCenter
                    : theme.justifyContentStart
                }
            `}
    >
      <button
        id={id}
        className={`${className as string} ${getClassName()}`}
        style={{
          ...style,
          outline: "none",
        }}
        type={type}
        onClick={!loading ? onClick : undefined}
        disabled={disabled}
      >
        <div
          className={`${classNameTextStyle as string}`}
          style={{ ...textStyle, paddingTop: loading ? 3 : 0 }}
        >
          {loading ? (
            <CircularProgress
              size={link ?? (pill && small) ? 10 : pill && extraSmall ? 0 : 20}
              thickness={5}
              color={"inherit"}
            />
          ) : (
            children
          )}
        </div>
      </button>
    </div>
  );
};
