import { get } from "lodash";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SubmissionError } from "redux-form";
import {
  UpdateContentInput,
  useUpdateContentMutation,
} from "../../../graphql/generated/types";
import { apiErrors } from "../../../helpers/Errors";
import { AppRow, AppTitle } from "../../components";
import UpdateForm from "./Form";

export interface IUpdateProps {
  id: any;
  initialValues?: any;
  fields?: string[];
  refetch?: () => void;
  onClose: () => void;
}

export const Update: FunctionComponent<IUpdateProps> = ({
  initialValues,
  onClose,
  refetch,
  fields,
  id,
}) => {
  const { t } = useTranslation();

  const [update, { loading }] = useUpdateContentMutation({
    onCompleted: () => {
      if (refetch) refetch();
      onClose();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  return (
    <>
      <AppRow
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <AppTitle
          small
          extrabold
          light
          subtitle={t("update.subtitle", "Please change the needed fields")}
        >
          {t("update.title", "Update")}
        </AppTitle>
      </AppRow>

      <UpdateForm
        submitButtonString={t("button.modify", "UPDATE")}
        loading={loading}
        fields={fields}
        initialValues={initialValues}
        // validate={(values: any) =>
        //   validate(values, validators(t), { fullMessages: false })
        // }
        onSubmit={async (values: UpdateContentInput) => {
          try {
            // console.log("UPDATE", values);
            const updateContentInput = {
              ...values,
              carousselSection: values.carousselSection.map((o) => {
                return {
                  title: o.title,
                  text: o.text,
                  image: {
                    filename: o.image?.filename ?? "temporary",
                    description: o.image?.description ?? "temporary",
                  },
                };
              }),
            };
            await update({
              variables: {
                contentId: id,
                updateContentInput,
              },
            });
          } catch (e: any) {
            throw new SubmissionError(
              apiErrors({
                errors: get(
                  e,
                  "graphQLErrors[0].extensions.exception.errors",
                  []
                ),
                message: e.message,
              })
            );
          }
        }}
      />
    </>
  );
};
