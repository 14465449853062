import { FunctionComponent } from "react";
import { AppTitle } from "../components";
import styles from "./Dashboard.module.scss";

export interface IFuncCompProps {}

export const Dashboard: FunctionComponent<IFuncCompProps> = () => {
  //

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <div className={styles.App}>
      <AppTitle></AppTitle>
    </div>
  );
};
