import { indexOf } from "lodash";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import {
  AccountStatuses,
  AccountTypes,
} from "../../../graphql/generated/types";
import { getCountries } from "../../../helpers/CountriesList";
import {
  AppButton,
  AppCard,
  AppFormPicker,
  AppFormTextField,
  AppText,
} from "../../components";

export interface IUpdateBaseFormProps {
  initialValues: any;
  loading?: boolean;
  fields?: string[];
  submitButtonString: any;
}

export type IUpdateFormProps = IUpdateBaseFormProps &
  InjectedFormProps<any, IUpdateBaseFormProps>;

const ReduxForm: FunctionComponent<IUpdateFormProps> = ({
  initialValues,
  loading,
  handleSubmit,
  error,
  fields,
  submitButtonString,
}) => {
  const { t } = useTranslation();

  const accountTypeOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(AccountTypes)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    accountTypeOptions.push({ value: propertyValue, label: propertyKey });
  }

  const accountStatusOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(AccountStatuses)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    accountStatusOptions.push({ value: propertyValue, label: propertyKey });
  }

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}

      <AppCard
        style={{
          margin: "20px 0",
        }}
      >
        {(!fields || indexOf(fields, "status") !== -1) && (
          <Field
            name="status"
            component={AppFormPicker}
            firstOptionAsDefaultValue={initialValues.type}
            options={accountStatusOptions}
            label={t("fieldLabel.status", "Status")}
            //   help={t("fieldHelp.resourceType", "Type of Account")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "type") !== -1) && (
          <Field
            name="type"
            component={AppFormPicker}
            firstOptionAsDefaultValue={initialValues.type}
            options={accountTypeOptions}
            label={t("fieldLabel.type", "Type")}
            //   help={t("fieldHelp.resourceType", "Type of Account")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "name") !== -1) && (
          <Field
            name="name"
            component={AppFormTextField}
            label={t("fieldLabel.accountName", "Account Name")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "name") !== -1) && (
          <Field
            name="company"
            component={AppFormTextField}
            label={t("fieldLabel.company", "Company")}
            //   help={t("fieldHelp.lastname")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "vatIdentifier") !== -1) && (
          <Field
            name="vatIdentifier"
            component={AppFormTextField}
            label={t("fieldLabel.vatIdentifier", "VAT Identifier")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "country") !== -1) && (
          <Field
            name="country"
            component={AppFormPicker}
            options={getCountries()}
            label={t("fieldLabel.country", "Country")}
            // help={t("fieldHelp.country")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "address") !== -1) && (
          <>
            <Field
              name="address"
              component={AppFormTextField}
              label={t("fieldLabel.address", "Address")}
              //   help={t("fieldHelp.email")}
              type="text"
              style={{ marginBottom: 20 }}
            />
            <Field
              name="zip"
              component={AppFormTextField}
              label={t("fieldLabel.zip", "Zipcode")}
              //   help={t("fieldHelp.email")}
              type="text"
              style={{ marginBottom: 20 }}
            />
            <Field
              name="city"
              component={AppFormTextField}
              label={t("fieldLabel.city", "City")}
              //   help={t("fieldHelp.email")}
              type="text"
              style={{ marginBottom: 20 }}
            />
          </>
        )}

        {(!fields || indexOf(fields, "invoicingEmail") !== -1) && (
          <Field
            name="invoicingEmail"
            component={AppFormTextField}
            label={t("fieldLabel.invoicingEmail", "Invoicing Email")}
            // help={t("fieldHelp.country")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}
      </AppCard>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}
      <AppButton primary valid type="submit" loading={loading}>
        {submitButtonString}
      </AppButton>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: "accountForm",
})(ReduxForm);
