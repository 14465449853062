import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Seller, useSellerQuery } from "../../../graphql/generated/types";
import {
  AppCard,
  AppDialog,
  AppError,
  AppLabel,
  AppRow,
  AppTitle,
} from "../../components";
import { UploadSellerImage } from "./UploadSellerImage";

export interface IUploadImagesProps {
  seller?: Seller;
}

export const UploadImages: FunctionComponent<IUploadImagesProps> = ({
  seller: sell,
}) => {
  const { t } = useTranslation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [error, setError] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: "",
    message: "",
  });

  /**********************************************************
   *** MUTATION ***
   **********************************************************/

  const { data, refetch } = useSellerQuery({
    variables: { sellerId: sell?.id ?? "" },
  });

  if (!data) return null;
  const seller = data.seller;

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <>
      <AppRow
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: 20,
        }}
      >
        <AppTitle
          small
          extrabold
          light
          subtitle={t("update.subtitle", "Please upload your images")}
        >
          {t("upload.title", "Upload Images")}
        </AppTitle>
      </AppRow>

      <AppCard style={{ margin: 0 }}>
        <AppLabel bold style={{ marginBottom: 10 }}>
          {t("label.logo", "Logo")}
        </AppLabel>

        <UploadSellerImage
          seller={seller}
          refetch={refetch}
          imageDescription={"logo"}
        />

        <AppLabel bold style={{ marginBottom: 10 }}>
          {t("label.mainImage", "Main Image")}
        </AppLabel>

        <UploadSellerImage
          seller={seller}
          refetch={refetch}
          imageDescription={"mainImage"}
        />

        <AppLabel bold style={{ marginBottom: 10 }}>
          {t("label.images", "Images")}
        </AppLabel>

        {seller.images?.map((image, i) => {
          return (
            <UploadSellerImage
              seller={seller}
              refetch={refetch}
              imageDescription={"image"}
              imageIndex={i}
            />
          );
        })}
        <UploadSellerImage
          seller={seller}
          refetch={refetch}
          imageDescription={"image"}
        />

        <AppDialog
          open={error.show}
          onClose={() => {
            setError({
              show: false,
              title: "",
              message: "",
            });
          }}
          scroll="body"
          width={600}
        >
          <AppError
            title={error.title}
            message={error.message}
            onClose={() => {
              setError({
                show: false,
                title: "",
                message: "",
              });
            }}
          />
        </AppDialog>
      </AppCard>
    </>
  );
};
