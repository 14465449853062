export enum GeoPointTypes {
  POINT = "Point",
  POLYGON = "Polygon",
}
// export enum StripePaymentStatus {
//   REQUIRES_PAYMENT_METHOD = "requires_payment_method",
//   REQUIRES_CONFIRMATION = "requires_confirmation",
//   REQUIRES_ACTION = "requires_action",
//   PROCESSING = "processing",
//   REQUIRES_CAPTURE = "requires_capture",
//   CANCELED = "canceled",
//   SUCCEEDED = "succeeded",
// }
// export enum AccountTypes {
//   STANDARD = "STANDARD",
//   PROFESSIONAL = "PROFESSIONAL",
//   ENTERPRISE = "ENTERPRISE",
// }
// export enum AccountStatuses {
//   ACTIVATED = "ACTIVATED",
//   DEACTIVATED = "DEACTIVATED",
//   DELETED = "DELETED",
// }

// export enum OrderStatuses {
//   PAYMENT_FAILURE = "PAYMENT_FAILURE",
//   PAYMENT_PENDING = "PAYMENT_PENDING",
//   PAYMENT_DONE = "PAYMENT_DONE",
//   PAYMENT_CANCELED = "PAYMENT_CANCELED",

//   WAITING_FOR_EXECUTION_DATETIME = "WAITING_FOR_EXECUTION_DATETIME",

//   READY_FOR_EXECUTION = "READY_FOR_EXECUTION",

//   PREPARATION = "PREPARATION",
//   READY_FOR_PICKUP = "READY_FOR_PICKUP",
//   ON_THE_WAY_FOR_PICKUP = "ON_THE_WAY_FOR_PICKUP",
//   PICKED_UP = "PICKED_UP",
//   ON_THE_WAY_FOR_DELIVERY = "ON_THE_WAY_FOR_DELIVERY",
//   DELIVERED = "DELIVERED",

//   REPRESENTATION_ORDERED = "REPRESENTATION_ORDERED",
//   REPATRIATION_ORDERED = "REPATRIATION_ORDERED",

//   SOLVING_PROBLEM = "SOLVING_PROBLEM",
//   WAITING_FOR_CUSTOMER_RESPONSE = "SOLVING_PROBLEM",
// }
// export enum PaymentProviders {
//   STRIPE = "STRIPE",
//   APPLE = "APPLE",
// }
// export enum PaymentMethods {
//   CARD = "card",
//   APPLE_IAP = "apple_iap",
// }

// export enum UserRoles {
//   USER = "USER",
//   SELLER = "SELLER",
//   ADMIN = "ADMIN",
// }

// export enum InvitationTypes {
//   EMAIL = "EMAIL",
//   SMS = "SMS",
//   FACEBOOK = "FACEBOOK",
// }

// export enum VariantTypes {
//   DEFAULT = "DEFAULT",
//   COLOR = "COLOR",
//   SIZE = "SIZE",
// }

// export enum FrequencyTypes {
//   DAILY = "DAILY",
//   WEEKLY = "WEEKLY",
//   MONTHLY = "MONTHLY",
//   BIMONTHLY = "BIMONTHLY",
//   QUARTERLY = "QUARTERLY",
//   ANNUALLY = "ANNUALLY",
// }

// export enum StreetNumberComplement {
//   TER = "TER",
//   BIS = "BIS",
//   QUATER = "QUATER",
//   ANTE = "ANTE",
// }

// /**********************************************************
//  *** PRODUCT ***
//  **********************************************************/

// export enum ProductStatuses {
//   AVAILABLE = "AVAILABLE",
//   NOT_AVAILABLE = "NOT AVAILABLE",
//   DELETED = "DELETED",
// }
// export enum ProductDeliveryTypes {
//   PHYSICAL = "PHYSICAL",
//   DEMATERIALIZED = "DEMATERIALIZED",
// }
// export enum ProductTypes {
//   ITEM = "ITEM",
//   PACK = "PACK",
//   SUBSCRIPTION = "SUBSCRIPTION",
//   OPTION = "OPTION",
// }
// export enum WeightUnits {
//   G = "G",
//   KG = "KG",
// }
// export enum DimensionsUnits {
//   MM = "MM",
//   CM = "CM",
//   M = "M",
// }

// /**********************************************************
//  *** SELLER ***
//  **********************************************************/

// export enum SellerStatuses {
//   ACTIVATED = "ACTIVATED",
//   DEACTIVATED = "DEACTIVATED",
//   DELETED = "DELETED",
// }

// export enum AvailabilityExceptionTypes {
//   OPEN = "OPEN",
//   CLOSED = "CLOSED",
// }

// export enum CommisionTypes {
//   RATE_PERCENT = "RATE PERCENT",
//   FEE_PER_PRODUCT = "FEE PER PRODUCT",
//   FEE_PER_ORDER = "FEE PER ORDER",
// }

// /**********************************************************
//  *** ORDER ***
//  **********************************************************/

// export enum OrderTypes {
//   CLASSICAL = "CLASSICAL",
//   SUBSCRIPTION = "SUBSCRIPTION",
// }

// /**********************************************************
//  *** SEGMENT ***
//  **********************************************************/

// export enum SegmentStatuses {
//   ACTIVATED = "ACTIVATED",
//   DEACTIVATED = "DEACTIVATED",
//   DELETED = "DELETED",
// }

// /**********************************************************
//  *** CATEGORY ***
//  **********************************************************/

// export enum CategoryStatuses {
//   ACTIVATED = "ACTIVATED",
//   DEACTIVATED = "DEACTIVATED",
//   DELETED = "DELETED",
// }
