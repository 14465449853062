import { Colors } from "./Colors";

export const MaterialTheme: any = (light: any) => {
  const newColor = {
    50: light ? "#fff" : "#000",
    100: light ? "#fff" : "#000",
    200: light ? "#fff" : "#000",
    300: light ? "#fff" : "#000",
    400: light ? "#fff" : "#000",
    500: light ? "#fff" : "#000",
    600: light ? "#fff" : "#000",
    700: light ? "#fff" : "#000",
    800: light ? "#fff" : "#000",
    900: light ? "#fff" : "#000",
    A100: light ? "#fff" : "#000",
    A200: light ? "#fff" : "#000",
    A400: light ? "#fff" : "#000",
    A700: light ? "#fff" : "#000",
  };

  return {
    palette: {
      primary: newColor,
      background: { default: newColor[100], paper: newColor[100] },
      secondary: newColor,
      error: newColor,
      warning: newColor,
      info: newColor,
      success: newColor,
    },
    overrides: {
      MuiFormLabel: {
        root: {
          "&$focused": {
            color: { color: light ? Colors.light : Colors.dark },
          },
        },
        colorSecondary: { color: light ? Colors.light : Colors.dark },
      },
      MuiInputLabel: {
        root: {
          fontFamily: "Roboto-Bold",
          fontWeight: "bold",
          fontSize: 16,
          color: light ? Colors.light : Colors.dark,
          "&$focused": {
            color: { color: light ? Colors.light : Colors.dark },
          },
        },
      },
      MuiInputBase: {
        input: {
          fontFamily: "Roboto-Bold",
          fontWeight: 800,
          fontSize: 18,
          color: light ? Colors.light : Colors.dark,
        },
      },
      MuiInput: {
        underline: {
          borderBottomColor: light ? Colors.light : Colors.dark,
          opacity: 1,
        },
        input: {
          fontFamily: "Roboto-Bold",
          fontWeight: 800,
          fontSize: 18,
          color: light ? Colors.light : Colors.dark,
          borderBottomColor: light ? Colors.light : Colors.dark,
          borderStyle: "solid",
          borderBottomWidth: 1.5,
        },
      },
      MuiDialogActions: {
        root: {
          color: light ? Colors.dark : Colors.light,
          backgroundColor: Colors.light,
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: light
            ? Colors.translucentLight
            : Colors.translucentDark,
        },
      },
      MuiButton: {
        label: {
          color: Colors.dark,
        },
      },
      MuiPickersBasePicker: { pickerView: { backgroundColor: Colors.light } },
      MuiPickersCalendarHeader: {
        iconButton: { backgroundColor: Colors.light },
      },
      MuiPickersToolbar: {
        toolbar: {
          color: Colors.light,
          backgroundColor: light ? Colors.light : Colors.dark,
        },
      },
      MuiTypography: {
        colorTextSecondary: {
          color: light ? Colors.translucentLight : Colors.translucentDark,
        },
      },

      MuiMenu: {
        paper: {
          backgroundColor: Colors.light,
        },
      },
    },
  };
};
