import { faPencil, faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { capitalize, find, get } from "lodash";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  CommissionTypes,
  OrderStatuses,
  useOrdersQuery,
} from "../../graphql/generated/types";
import { priceDisplay } from "../../helpers/PriceDisplay";
import { Colors } from "../../styles/Colors";
import {
  AppButton,
  AppCard,
  AppDialog,
  AppError,
  AppLoader,
  AppRow,
  AppText,
  AppTimerButton,
  AppTitle,
} from "../components";
import styles from "./Orders.module.scss";
import { Cart } from "./cart/Cart";
import { Delete } from "./mutations/Delete";
import { Update } from "./mutations/Update";

export interface IFuncCompProps {}

export const Orders: FunctionComponent<IFuncCompProps> = () => {
  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowPerPage] = useState<number>(15);

  const [showCart, setShowCart] = useState<
    { orderId: string; show: boolean } | undefined
  >(undefined);

  const [deleteRow, setDeleteRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);
  const [updateRow, setUpdateRow] = useState<
    { id: string; show: boolean; fields: string[] } | undefined
  >(undefined);

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const { data, loading, error, refetch } = useOrdersQuery({
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      order: ["processed:ASC", "createdAt:DESC", "nextExecutionAt:DESC"],
    },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
    fetchPolicy: "network-only",
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  if (error) {
    return <AppError title={"GraphQL Error"} error={error} />;
  }
  if (loading) {
    return (
      <AppLoader light size={25} style={{ color: Colors.light }} thickness={3}>
        Loading Orders...
      </AppLoader>
    );
  }

  const orders = get(data, "orders.results", []);

  return (
    <div className={styles.App}>
      <AppRow style={{ justifyContent: "space-between", padding: "0 20px" }}>
        <AppTitle bold>{`Orders`}</AppTitle>
        <AppButton onClick={() => refetch()} pill small>
          REFRESH
        </AppButton>
      </AppRow>
      <AppCard style={stylesheet.appCard as any}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                STATUS
              </TableCell>
              <TableCell style={stylesheet.columnHeadText}>EXECUTION</TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                CUSTOMER
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                CONTACTS
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                DELIVERY ADDRESS
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                SELLER
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                CART
              </TableCell>
              <TableCell style={stylesheet.columnHeadText}>AMOUNT</TableCell>
              <TableCell style={stylesheet.columnHeadText}>
                COMMISSION
              </TableCell>
              <TableCell style={stylesheet.columnHeadText}>DELIVERY</TableCell>
              <TableCell style={stylesheet.columnHeadText}>SERVICE</TableCell>
              <TableCell style={stylesheet.columnHeadText}>EXECUTION</TableCell>
              <TableCell /> <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, key: number) => {
              const commissionAmountWithTax =
                order.seller.commissionType === CommissionTypes.FeePerOrder
                  ? order.seller.commissionValue
                  : order.seller.commissionType ===
                    CommissionTypes.FeePerProduct
                  ? order.seller.commissionValue ?? 0 * order.cart.length
                  : order.seller.commissionType === CommissionTypes.RatePercent
                  ? Math.round(
                      (order.amountWithTax / 100) *
                        (order.seller.commissionValue
                          ? order.seller.commissionValue
                          : 0)
                    )
                  : 0;

              /* const commissionAmountWithoutTax =
                order.seller.commissionType === CommissionTypes.FeePerOrder
                  ? order.seller.commissionValue
                  : order.seller.commissionType ===
                    CommissionTypes.FeePerProduct
                  ? order.seller.commissionValue ?? 0 * order.cart.length
                  : order.seller.commissionType === CommissionTypes.RatePercent
                  ? Math.round(
                      (order.amountWithoutTax / 100) *
                        (order.seller.commissionValue
                          ? order.seller.commissionValue
                          : 0)
                    )
                  : 0; */

              return [
                <StyledTableRow
                  key={`${key}-master`}
                  hover
                  tabIndex={-1}
                  onDoubleClick={() => {}}
                >
                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                      width: 30,
                    }}
                  >
                    <AppRow>
                      {moment(order.createdAt).isAfter(
                        moment().subtract(15, "minutes")
                      ) && (
                        <FontAwesomeIcon
                          data-tip={"This order just got pop! (15min)"}
                          icon={faSparkles}
                          style={{ fontSize: 16, marginRight: 10 }}
                          color={Colors.valid}
                        />
                      )}
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <FontAwesomeIcon
                        onClick={() =>
                          setUpdateRow({
                            id: order.id,
                            show: true,
                            fields: ["status"],
                          })
                        }
                        data-tip={"Update data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        style={{
                          margin: "0px 10px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color: Colors.dark,
                        }}
                      />
                      <div>
                        <AppText
                          disabled={order.processed}
                          bold
                          valid={order.status === OrderStatuses.Delivered}
                          danger={order.status === OrderStatuses.PaymentFailure}
                          transparentDark={
                            order.status === OrderStatuses.PaymentPending
                          }
                        >{`${order.status} `}</AppText>
                        <AppText
                          disabled={order.processed}
                          small
                          transparentDark={
                            order.status === OrderStatuses.PaymentPending
                          }
                        >
                          {`Last Modified: ${moment(order.updatedAt).format(
                            " Do MMM , HH:mm"
                          )}`}
                        </AppText>
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    {order.nextExecutionAt ? (
                      <AppText
                        disabled={order.processed}
                        bold
                        transparentDark={
                          order.status === OrderStatuses.PaymentPending
                        }
                      >
                        {moment(order.nextExecutionAt).isBefore(moment())
                          ? `Started at ${moment(order.nextExecutionAt).format(
                              "HH:mm"
                            )}`
                          : `Will Start: ${moment(order.nextExecutionAt).format(
                              "Do MMM , HH:mm"
                            )}`}
                      </AppText>
                    ) : (
                      <>
                        <AppText
                          disabled={order.processed}
                          bold
                          transparentDark={
                            order.status === OrderStatuses.PaymentPending
                          }
                        >
                          {`Subscription`}
                        </AppText>
                      </>
                    )}
                    <AppText
                      small
                      disabled={order.processed}
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >
                      {order.id}
                    </AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >{`${capitalize(order.user.firstName)} ${capitalize(
                      order.user.lastName
                    )} (${order.user.language.toUpperCase()})`}</AppText>
                    <AppText
                      disabled={order.processed}
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                      small
                    >{`Account: ${order.account.name}`}</AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >{`${order.user.phoneNumber}`}</AppText>
                    <AppText
                      disabled={order.processed}
                      small
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >{`${order.user.email}`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >{`${order.deliveryAddress}`}</AppText>
                    <AppText
                      disabled={order.processed}
                      small
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >{`${order.deliveryCity}`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >{`${order.seller.name}`}</AppText>
                    <AppText
                      disabled={order.processed}
                      small
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >{`${order.seller.phoneNumber}`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >{`${order.cart.length} Product(s)`}</AppText>
                    <AppText
                      disabled={order.processed}
                      small
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() => {
                        setShowCart({
                          show: true,
                          orderId: order.id,
                        });
                      }}
                    >
                      {`SHOW CART`}
                    </AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    {/* <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >
                      {`${priceDisplay(
                        order.amountWithoutTax +  / 100,
                        "Fr",
                        order.currency
                      )}HT `}
                    </AppText> */}
                    <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >
                      {`${priceDisplay(
                        (order.amountWithTax +
                          (order.deliveryFee ?? 0) +
                          (order.serviceFee ?? 0)) /
                          100,
                        "Fr",
                        order.currency
                      )}TTC`}
                    </AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    {/* <AppText
                      disabled={order.processed}
                      small
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >
                      {
                        `${priceDisplay(
                        (commissionAmountWithoutTax ?? 0) / 100,
                        "Fr",
                        order.currency
                      )}HT` 
                      }
                    </AppText>*/}
                    <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >
                      {`${priceDisplay(
                        (commissionAmountWithTax ?? 0) / 100,
                        "Fr",
                        order.currency
                      )}TTC`}
                    </AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    {/* <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >
                      {`${priceDisplay(
                        (((order.deliveryFee ?? 0) / 6) * 5) / 100,
                        "Fr",
                        order.currency
                      )}HT`}
                    </AppText> */}
                    <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >
                      {`${priceDisplay(
                        (order.deliveryFee ?? 0) / 100,
                        "Fr",
                        order.currency
                      )}TTC`}
                    </AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    {/* <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >
                      {`${priceDisplay(
                        (((order.serviceFee ?? 0) / 6) * 5) / 100,
                        "Fr",
                        order.currency
                      )}HT`}
                    </AppText> */}
                    <AppText
                      disabled={order.processed}
                      bold
                      transparentDark={
                        order.status === OrderStatuses.PaymentPending
                      }
                    >
                      {`${priceDisplay(
                        (order.serviceFee ?? 0) / 100,
                        "Fr",
                        order.currency
                      )}TTC`}
                    </AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    {order.deliveryOPSreference && (
                      <AppText
                        disabled={order.processed}
                        bold
                        transparentDark={
                          order.status === OrderStatuses.PaymentPending
                        }
                      >{`COURIIER ID: ${order.deliveryOPSreference}`}</AppText>
                    )}
                    {order.deliveryOPStrackingLink &&
                      !order.processed &&
                      ![
                        OrderStatuses.CanceledByDispatch,
                        OrderStatuses.Delivered,
                      ].includes(order.status) && (
                        <AppText
                          onClick={() =>
                            window.open(order.deliveryOPStrackingLink ?? "")
                          }
                          small
                          bold
                          transparentDark={
                            order.status === OrderStatuses.PaymentPending
                          }
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >{`TRACKING LINK`}</AppText>
                      )}
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <FontAwesomeIcon
                        onClick={() =>
                          setUpdateRow({
                            id: order.id,
                            show: true,
                            fields: ["messenger"],
                          })
                        }
                        data-tip={"Update data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        style={{
                          margin: "0px 10px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color: Colors.dark,
                        }}
                      />
                      <div>
                        <AppText
                          disabled={order.processed}
                          bold
                          transparentDark={
                            order.status === OrderStatuses.PaymentPending
                          }
                        >
                          {order.messenger
                            ? `${capitalize(
                                order.messenger?.firstName
                              )} ${order.messenger?.lastName
                                .charAt(0)
                                .toUpperCase()}.`
                            : "No messenger"}
                        </AppText>

                        <AppText
                          onClick={() =>
                            window.open(
                              `https://www.google.com/maps/?q=${order.messengerPosition?.coordinates[0][0][1]},${order.messengerPosition?.coordinates[0][0][0]}`
                            )
                          }
                          small
                          bold
                          transparentDark={
                            order.status === OrderStatuses.PaymentPending
                          }
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >{`TRACKING`}</AppText>
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell scope="row" style={{ paddingRight: 20 }}>
                    <AppRow style={{ justifyContent: "flex-end" }}>
                      {/* <AppButton
                        style={{ margin: "0px 10px" }}
                        link
                        onClick={() =>
                          setUpdateRow({ id: order.id, show: true })
                        }
                      >
                        <FontAwesomeIcon
                          data-tip={"Update row data"}
                          data-for="updateToolTip"
                          icon={faPencil}
              
                          style={{ outline: "none" }}
                        />
                      </AppButton>
                      <ReactTooltip id="updateToolTip" place="left" multiline /> */}
                      <AppTimerButton
                        disabled={
                          ![
                            OrderStatuses.PaymentDone,
                            OrderStatuses.ReadyForExecution,
                            OrderStatuses.Preparation,
                            OrderStatuses.ReadyForPickup,
                            OrderStatuses.MessengerAssigned,
                            OrderStatuses.WaitingForExecutionDatetime,
                          ].includes(order.status)
                        }
                        data-tip={
                          "Delete row data<br/>Press this button for 5s"
                        }
                        data-for="deleteToolTip"
                        style={{ padding: "0 10px 0 0" }}
                        endOfTimeFunction={() => {
                          setDeleteRow({ id: order.id, show: true });
                        }}
                      />
                      <ReactTooltip id="deleteToolTip" place="left" multiline />
                      {/* <AppButton
                        link
                        onClick={() => {
                          handleCheckeds(order);
                        }}
                        textStyle={{ fontSize: 14 }}
                      >
                        <FontAwesomeIcon
                          icon={getCheck(order.id) ? faCheckSquare : faSquare}
                          style={{ fontSize: 16 }}
                          color={
                            getCheck(order.id)
                              ? Colors.valid
                              : Colors.transparentDark
                          }
                        />
                      </AppButton> */}
                    </AppRow>
                  </TableCell>
                </StyledTableRow>,
              ];
            })}
          </TableBody>
        </Table>

        <AppRow
          style={{
            justifyContent: "space-between",
          }}
        >
          {/*             <CustomersFiltersForm width={250} />
           */}
          <Table style={{ width: "100%" }}>
            <TableFooter>
              <TableRow>
                <StyledTablePagination
                  labelRowsPerPage={`ROWS PER PAGE`}
                  labelDisplayedRows={(paginationInfo: any) => {
                    return `PAGE ${paginationInfo.page + 1} - FROM ${
                      paginationInfo.from
                    } TO ${paginationInfo.to} - TOTAL ${paginationInfo.count}`;
                  }}
                  count={data?.orders.total ?? 0}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 20, 50, 100, 250, 500]}
                  page={page}
                  onPageChange={(e, page: number) => {
                    setPage(page);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    setRowPerPage(parseInt(event.target.value, 10));
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </AppRow>
      </AppCard>

      <AppDialog
        open={deleteRow ? deleteRow.show : false}
        onClose={() => setDeleteRow(undefined)}
        scroll="body"
        width={600}
      >
        <Delete id={deleteRow?.id} onClose={() => setDeleteRow(undefined)} />
      </AppDialog>

      <AppDialog
        open={updateRow ? updateRow.show : false}
        onClose={() => setUpdateRow(undefined)}
        scroll="body"
        width={500}
      >
        <Update
          id={updateRow?.id}
          initialValues={find(orders, { id: updateRow?.id })}
          fields={updateRow?.fields}
          onClose={() => {
            refetch();
            setUpdateRow(undefined);
          }}
        />
      </AppDialog>

      <ReactTooltip place="right" multiline />

      <AppDialog
        open={showCart ? showCart.show : false}
        onClose={() => setShowCart(undefined)}
        scroll="body"
        width={800}
      >
        <Cart
          orderId={showCart?.orderId ?? ""}
          onClose={() => setShowCart(undefined)}
        />
      </AppDialog>
    </div>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTablePagination = withStyles({
  root: { borderBottom: 0, color: Colors.transparentDark, fontSize: 12 },
  caption: { color: Colors.transparentDark, fontSize: 10 },
  selectIcon: { color: Colors.transparentDark, fontSize: 22 },
  // MuiButtonBase: { root: { outline: 'none' } }
  // overrides: {
  //     MuiButtonBase: { root: { outline: 'none' } }
  // }
})(TablePagination);

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
