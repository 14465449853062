import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, WrappedFieldArrayProps } from "redux-form";
import { useCategoriesQuery } from "../../../graphql/generated/types";
import { Colors } from "../../../styles/Colors";
import theme from "../../../styles/Theme.module.scss";
import { AppButton, AppCol, AppFormPicker, AppRow } from "../../components";

export const FieldArrayCategories: FunctionComponent<
  WrappedFieldArrayProps
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  const { data } = useCategoriesQuery({
    variables: { skip: 0, limit: 1000 },
  });

  if (fields.length === 0) fields.push("");

  if (!data) return null;
  const categories = data.categories.results.map((category) => {
    return { label: category.name, value: category.id };
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((category, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={category}
                component={AppFormPicker}
                options={categories}
                label={
                  index === 0 &&
                  t("fieldLabel.subCategory", "Segment Categories")
                }
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addCategory", "ADD A CATEGORY")}
      </AppButton>
    </div>
  );
};
