import { faPencil, faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { capitalize, find, get } from "lodash";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  Account,
  AccountStatuses,
  useAccountsQuery,
} from "../../graphql/generated/types";
import { Colors } from "../../styles/Colors";
import {
  AppButton,
  AppCard,
  AppDialog,
  AppError,
  AppLoader,
  AppRow,
  AppTimerButton,
  AppTitle,
} from "../components";
import { AppText } from "../components/AppText";
import styles from "./Customers.module.scss";
import { Delete } from "./mutations/Delete";
import { Update } from "./mutations/Update";

export interface Checked {
  id: string;
  check: boolean;
}

export interface IFuncCompProps {}

export const Customers: FunctionComponent<IFuncCompProps> = () => {
  //

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowPerPage] = useState<number>(50);

  const [deleteRow, setDeleteRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);
  const [updateRow, setUpdateRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);

  /**********************************************************
   *** SELECT ***
   **********************************************************/

  // const handleCheckeds = (row: any) => {
  //   const updateCheckeds = checkeds.filter(({ id, check }) => {
  //     return id !== row.id && id !== "";
  //   });
  //   const checkObject = find(checkeds, (c) => c.id === row.id);
  //   updateCheckeds.push({
  //     id: row.id,
  //     check: checkObject ? !checkObject.check : true,
  //   });
  //   const tmpListrow: any[] = [...rowsSelected];
  //   tmpListrow.push(row);
  //   const newrowsSelectedList = tmpListrow.filter((row) => {
  //     for (const { id, check } of updateCheckeds) {
  //       if (check && row && id === row.id) return true;
  //     }
  //     return false;
  //   });
  //   setRowsSelected(newrowsSelectedList);
  //   setCheckeds(updateCheckeds);
  // };

  // const unselectAll = () => {
  //   setRowsSelected([]);
  //   setCheckeds([]);
  // };

  /**********************************************************
   *** TABLE CONTROL ***
   **********************************************************/

  // const getCheck = (idInput: string): boolean => {
  //   for (const { id, check } of checkeds) {
  //     if (id === idInput) return check;
  //   }
  //   return false;
  // };

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const { data, loading, error } = useAccountsQuery({
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      sortedBy: ["createdAt:DESC"],
    },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  if (error) {
    return <AppError title={"GraphQL Error"} error={error} />;
  }
  if (loading) {
    return (
      <AppLoader light size={25} style={{ color: Colors.light }} thickness={3}>
        Loading Customers...
      </AppLoader>
    );
  }

  const accounts = get(data, "accounts.results", []);

  return (
    <div className={styles.App}>
      <AppTitle bold>{`Customers`}</AppTitle>
      <AppCard style={stylesheet.appCard as any}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                STATUS
              </TableCell>
              <TableCell style={stylesheet.columnHeadText}>COMPANY</TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                ACCOUNT
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                CONTACTS
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                INVOICING
              </TableCell>
              <TableCell style={stylesheet.columnHeadText}>
                ACCOUNT ID
              </TableCell>
              <TableCell style={stylesheet.columnHeadText}>ORDERS</TableCell>
              {/* <TableCell
                style={{
                  paddingRight: 21,
                  paddingBottom: 5,
                  textAlign: "right",
                }}
              >
                <FontAwesomeIcon
                  onClick={() => unselectAll()}
                  data-tip="Unselect all."
                  icon={faTimesSquare}
                  style={{ fontSize: 16, cursor: "pointer" }}
                  color={Colors.transparentDark}
                />
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account: Account, key: number) => {
              return [
                <StyledTableRow
                  key={`${key}-master`}
                  hover
                  tabIndex={-1}
                  onDoubleClick={() => {}}
                >
                  <TableCell
                    scope="row"
                    style={{ ...stylesheet.rowText, width: 30 }}
                  >
                    <AppRow>
                      {moment(account.createdAt).isAfter(
                        moment().subtract(5, "days")
                      ) && (
                        <FontAwesomeIcon
                          data-tip={"This account just got pop!"}
                          icon={faSparkles}
                          style={{ fontSize: 16, marginRight: 10 }}
                          color={Colors.valid}
                        />
                      )}
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      bold
                      valid={account.status === AccountStatuses.Activated}
                      danger={account.status === AccountStatuses.Deactivated}
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${account.status} `}</AppText>
                    <AppText
                      small
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >
                      {account.type}
                    </AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      bold
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${account.company}`}</AppText>
                    <AppText
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                      small
                    >{`VAT: ${account.vatIdentifier ?? ""}`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppText
                      bold
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${account.name}`}</AppText>
                    <AppText
                      small
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${capitalize(account.users[0]?.firstName)} ${capitalize(
                      account.users[0]?.lastName
                    )}${
                      account.users.length > 1
                        ? ` +${account.users.length - 1}`
                        : ``
                    }`}</AppText>
                  </TableCell>
                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      bold
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${account.users[0]?.phoneNumber}`}</AppText>
                    <AppText
                      small
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${account.users[0]?.language.toUpperCase()}`}</AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      bold
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${account.address} ${account.zip} ${account.city} ${account.country} `}</AppText>{" "}
                    <AppText
                      small
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${account.invoicingEmail}`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppText
                      bold
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${account.id}`}</AppText>
                    <AppText
                      small
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >
                      {moment(account.createdAt).format("MMM Do YYYY, HH:mm")}
                    </AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      bold
                      transparentDark={
                        account.status === AccountStatuses.Deleted
                      }
                    >{`${account.orders?.length} order(s)`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ paddingRight: 20 }}>
                    <AppRow style={{ justifyContent: "flex-end" }}>
                      <AppButton
                        style={{ margin: "0px 10px" }}
                        link
                        // disabled={!getCheck(order._id) || addTimeToOrderLoading}
                        onClick={() =>
                          setUpdateRow({ id: account.id, show: true })
                        }
                      >
                        <FontAwesomeIcon
                          data-tip={"Update row data"}
                          data-for="updateToolTip"
                          icon={faPencil}
                          color={
                            /* !getCheck(order._id) || addTimeToOrderLoading ? Colors.disabled :  */ Colors.dark
                          }
                          style={{ outline: "none" }}
                        />
                      </AppButton>
                      <ReactTooltip id="updateToolTip" place="left" multiline />
                      <AppTimerButton
                        data-tip={
                          "Delete row data<br/>Press this button for 5s"
                        }
                        data-for="deleteToolTip"
                        style={{ padding: "0 10px 0 0" }}
                        endOfTimeFunction={() => {
                          setDeleteRow({ id: account.id, show: true });
                        }}
                      />
                      <ReactTooltip id="deleteToolTip" place="left" multiline />
                      {/* <AppButton
                        link
                        onClick={() => {
                          handleCheckeds(account);
                        }}
                        textStyle={{ fontSize: 14 }}
                      >
                        <FontAwesomeIcon
                          icon={getCheck(account.id) ? faCheckSquare : faSquare}
                          style={{ fontSize: 16 }}
                          color={
                            getCheck(account.id)
                              ? Colors.valid
                              : Colors.transparentDark
                          }
                        />
                      </AppButton> */}
                    </AppRow>
                  </TableCell>
                </StyledTableRow>,
              ];
            })}
          </TableBody>
        </Table>

        <AppRow
          style={{
            justifyContent: "space-between",
          }}
        >
          {/*             <CustomersFiltersForm width={250} />
           */}
          <Table style={{ width: "100%" }}>
            <TableFooter>
              <TableRow>
                <StyledTablePagination
                  labelRowsPerPage={`ROWS PER PAGE`}
                  labelDisplayedRows={(paginationInfo: any) => {
                    return `PAGE ${paginationInfo.page + 1} - FROM ${
                      paginationInfo.from
                    } TO ${paginationInfo.to} - TOTAL ${paginationInfo.count}`;
                  }}
                  count={data?.accounts.total ?? 0}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 20, 50, 100, 250, 500]}
                  page={page}
                  onPageChange={(e, page: number) => {
                    setPage(page);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    setRowPerPage(parseInt(event.target.value, 10));
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </AppRow>
      </AppCard>

      <AppDialog
        open={deleteRow ? deleteRow.show : false}
        onClose={() => setDeleteRow(undefined)}
        scroll="body"
        width={600}
      >
        <Delete id={deleteRow?.id} onClose={() => setDeleteRow(undefined)} />
      </AppDialog>

      <AppDialog
        open={updateRow ? updateRow.show : false}
        onClose={() => setUpdateRow(undefined)}
        scroll="body"
        width={500}
      >
        <Update
          id={updateRow?.id}
          initialValues={find(accounts, { id: updateRow?.id })}
          onClose={() => setUpdateRow(undefined)}
        />
      </AppDialog>

      <ReactTooltip place="right" multiline />
    </div>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTablePagination = withStyles({
  root: { baccountBottom: 0, color: Colors.transparentDark, fontSize: 12 },
  caption: { color: Colors.transparentDark, fontSize: 10 },
  selectIcon: { color: Colors.transparentDark, fontSize: 22 },
  // MuiButtonBase: { root: { outline: 'none' } }
  // overrides: {
  //     MuiButtonBase: { root: { outline: 'none' } }
  // }
})(TablePagination);

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
