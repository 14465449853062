import {
  faCaretDown,
  faCaretUp,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, FieldArray, WrappedFieldArrayProps } from "redux-form";
import { Colors } from "../../../styles/Colors";
import theme from "../../../styles/Theme.module.scss";
import { AppButton, AppCol, AppFormTextField, AppRow } from "../../components";
import { AppTitle } from "../../components/AppTitle";

export const FieldArraycarousselSection: FunctionComponent<
  WrappedFieldArrayProps
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  const handleRearrange = (from: number, to: number) => {
    fields.move(from, to);
  };

  if (fields.length === 0) fields.push({ title: "", text: "" });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((field, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol>
              <FontAwesomeIcon
                icon={faCaretUp}
                onClick={() => {
                  if (index > 0) handleRearrange(index, index - 1);
                }}
                style={{ cursor: "pointer" }}
              />
              <FontAwesomeIcon
                icon={faCaretDown}
                onClick={() => {
                  if (index < fields.length - 1)
                    handleRearrange(index, index + 1);
                }}
                style={{ cursor: "pointer", marginBottom: index > 0 ? 20 : 0 }}
              />
            </AppCol>
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              {index === 0 && (
                <AppTitle bold>
                  {t("fieldLabel.croussel", "Caroussel")}
                </AppTitle>
              )}
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: Colors.transparentDark,
                  padding: 10,
                  marginTop: 10,
                  marginBottom: 30,
                  borderRadius: 5,
                }}
              >
                <Field
                  name={`${field}.title`}
                  component={AppFormTextField}
                  label={`Title ${index + 1}`}
                  type="text"
                  style={{
                    marginTop: 10,
                    marginBottom: index === fields.length - 1 ? 15 : 5,
                  }}
                />
                <Field
                  name={`${field}.text`}
                  component={AppFormTextField}
                  label={`Text ${index + 1}`}
                  type="text"
                  style={{
                    marginTop: 10,
                    marginBottom: index === fields.length - 1 ? 15 : 5,
                  }}
                />{" "}
              </div>
            </AppCol>

            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addAnItem", "ADD AN ITEM")}
      </AppButton>
    </div>
  );
};

export const FieldArrayChapters: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push({ title: "", sections: [] });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((field, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              {/* index === 0 && (
                <AppLabel bold>
                  {t("fieldLabel.subCategory", "Products Display")}
                </AppLabel>
              ) */}
              <Field
                name={`${field}.title`}
                component={AppFormTextField}
                label={`Section Title (Position ${index + 1})`}
                type="text"
                style={{
                  marginTop: 10,
                  marginBottom: index === fields.length - 1 ? 15 : 5,
                }}
              />
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: Colors.transparentDark,
                  padding: 10,
                  marginTop: 10,
                  borderRadius: 5,
                }}
              >
                <FieldArray
                  name={`${field}.sections`}
                  component={FieldArraySections as any}
                  props
                />
              </div>
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{
          flex: "none",
          borderStyle: "solid",
          borderWidth: 1,
          marginTop: 20,
        }}
      >
        {t("button.addCategory", "ADD A SECTION")}
      </AppButton>
    </div>
  );
};

export const FieldArraySections: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push({ title: "", text: "" });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((field, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <div style={{ marginBottom: 20 }}>
                <AppTitle small bold>
                  {t("fieldLabel.chapter", "Chapter") + ` ${index + 1}`}
                </AppTitle>

                <Field
                  name={`${field}.title`}
                  component={AppFormTextField}
                  label={`Chapter Title`}
                  type="text"
                  style={{
                    marginTop: 10,
                    marginBottom: index === fields.length - 1 ? 15 : 5,
                  }}
                />
                <Field
                  name={`${field}.text`}
                  component={AppFormTextField}
                  label={`Chapter Text`}
                  type="text"
                  style={{
                    marginTop: 10,
                    marginBottom: index === fields.length - 1 ? 15 : 5,
                  }}
                />
              </div>
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addCategory", "ADD A CHAPTER")}
      </AppButton>
    </div>
  );
};
