import { ApolloProvider } from "@apollo/client";
// import "bootstrap";
import React, { FunctionComponent, ReactNode } from "react";
import { Provider } from "react-redux";
import "typeface-roboto";
import { client } from "./graphql/client";
import { createStore } from "./redux/store";

export interface IAppIndexProps {
  children: (history: any) => ReactNode;
}

export const AppIndex: FunctionComponent<IAppIndexProps> = ({ children }) => {
  const { store, history } = createStore();

  return (
    <div id="application">
      <ApolloProvider client={client}>
        <Provider store={store}>{children(history)}</Provider>
      </ApolloProvider>
    </div>
  );
};
