import { indexOf } from "lodash";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import { getCountries } from "../../../helpers/CountriesList";
import { currencyOptions } from "../../../helpers/Currency";
import {
  AppButton,
  AppCard,
  AppCol,
  AppFormPicker,
  AppFormTextField,
  AppRow,
  AppText,
} from "../../components";

export interface IUpdateBaseFormProps {
  initialValues?: any;
  loading?: boolean;
  fields?: string[];
  submitButtonString: any;
}

export type IUpdateFormProps = IUpdateBaseFormProps &
  InjectedFormProps<any, IUpdateBaseFormProps>;

const ReduxForm: FunctionComponent<IUpdateFormProps> = ({
  initialValues,
  loading,
  handleSubmit,
  error,
  fields,
  submitButtonString,
}) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}

      <AppCard
        style={{
          margin: "20px 0",
        }}
      >
        <AppRow style={{ gap: 20 }}>
          <AppCol style={{ width: "100%" }}>
            {(!fields || indexOf(fields, "country") !== -1) && (
              <Field
                name="country"
                component={AppFormPicker}
                options={getCountries()}
                label={t("fieldLabel.country", "Country")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                style={{ marginBottom: 20 }}
              />
            )}
          </AppCol>

          <AppCol style={{ width: "100%" }}>
            {(!fields || indexOf(fields, "currency") !== -1) && (
              <Field
                name="currency"
                component={AppFormPicker}
                options={currencyOptions}
                label={t("fieldLabel.currency", "Currency")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                style={{ marginBottom: 20 }}
              />
            )}
          </AppCol>
        </AppRow>

        <AppRow style={{ gap: 20 }}>
          <AppCol style={{ width: "100%" }}>
            {(!fields || indexOf(fields, "amountWithoutTax") !== -1) && (
              <Field
                name="amountWithoutTax"
                component={AppFormTextField}
                label={t("fieldLabel.amountWithoutTax", "Amount without Tax")}
                //   help={t("fieldHelp.firstname", )}
                type="number"
                parse={parseFloat}
                autoFocus
                style={{ marginBottom: 20 }}
              />
            )}
          </AppCol>
          +
          <AppCol style={{ width: "75%" }}>
            {(!fields || indexOf(fields, "taxRate") !== -1) && (
              <Field
                name="taxRate"
                component={AppFormTextField}
                label={t("fieldLabel.taxRate", "Tax Rate")}
                //   help={t("fieldHelp.firstname", )}
                type="number"
                parse={parseFloat}
                autoFocus
                style={{ marginBottom: 20 }}
              />
            )}
          </AppCol>
          =
          <AppCol style={{ width: "100%" }}>
            {(!fields || indexOf(fields, "amountWithTax") !== -1) && (
              <Field
                name="amountWithTax"
                component={AppFormTextField}
                label={t("fieldLabel.amountWithTax", "Amount with Tax")}
                //   help={t("fieldHelp.firstname", )}
                type="number"
                autoFocus
                parse={parseFloat}
                style={{ marginBottom: 20 }}
              />
            )}
          </AppCol>
        </AppRow>
      </AppCard>

      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}
      <AppButton primary valid type="submit" loading={loading}>
        {submitButtonString}
      </AppButton>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: "priceForm",
})(ReduxForm);
