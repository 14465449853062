import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import theme from "../../styles/Theme.module.scss";
import { AppButton, AppFormTextField, AppText } from "../components";
import { IAuthRequest } from "./Signin";
import styles from "./Signin.module.scss";

export interface ISigninBaseFormProps {
  loading?: boolean;
  handleOpenForgotPassword: () => void;
}

export type ISigninFormProps = ISigninBaseFormProps &
  InjectedFormProps<IAuthRequest, ISigninBaseFormProps>;

const SigninForm: FunctionComponent<ISigninFormProps> = ({
  loading,
  handleSubmit,
  error,
  handleOpenForgotPassword,
}) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="email"
        component={AppFormTextField}
        label={t("fieldLabel.email", "Email")}
        help={t("fieldHelp.email", "Enter the email address of your account")}
        type="text"
        className={styles.field}
      />
      <Field
        name="password"
        component={AppFormTextField}
        label={t("fieldLabel.password", "Password")}
        help={t("fieldHelp.password", "Enter your password")}
        type="password"
      />

      {error && (
        <AppText danger bold center className={theme.marginVertical20}>
          {error}
        </AppText>
      )}

      <AppButton
        className={styles.button}
        primary
        valid
        type="submit"
        loading={loading}
      >
        {t("button.signin", "SIGNIN")}
      </AppButton>
      <AppButton link center onClick={() => handleOpenForgotPassword()}>
        {t("button.forgotPassword", "FORGOT PASSWORD?")}
      </AppButton>
    </Form>
  );
};

export default reduxForm<IAuthRequest, ISigninBaseFormProps>({
  form: "signin",
})(SigninForm);
