import React from "react";
import theme from "../../styles/Theme.module.scss";
export interface IAppRowProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

export const AppRow: React.FC<IAppRowProps> = ({
  style,
  className,
  children,
  onClick,
}) => {
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      className={`row no-gutters ${className} ${theme.row}`}
      style={{ position: "relative", ...style }}
    >
      {children}
    </div>
  );
};
