import { handleActions } from 'redux-actions';
import { set, get, remove } from 'lodash';
import { options } from './actions';

export default handleActions(
    {
        // DIRECT JOBS
        setState: (state: any, action: any) => {
            const newState = { ...state };
            set(newState, action.meta, action.payload);
            return newState;
        },
        deleteState: (state: any, action: any) => {
            const newState = { ...state };
            set(newState, action.meta, undefined);
            return newState;
        },
        // ARRAY JOBS
        add: (state: any, action: any) => {
            const newState = Object.assign({}, state);
            let partState = get(newState, action.meta, []);
            if (Array.isArray(partState)) {
                partState.push(action.payload);
            }
            set(newState, action.meta, partState);
            return newState;
        },
        remove: (state: any, action: any) => {
            const newState = Object.assign({}, state);
            let partState = get(newState, action.meta);
            if (Array.isArray(partState)) {
                const index = partState.indexOf(action.payload);
                if (index > -1) partState.splice(index, 1);
            }
            set(newState, action.meta, partState);
            return newState;
        },
        toggle: (state: any, action: any) => {
            const newState = Object.assign({}, state);
            let partState = get(newState, action.meta, []);
            if (Array.isArray(partState)) {
                const index = partState.indexOf(action.payload);
                if (index > -1) partState.splice(index, 1);
                else partState.push(action.payload);
            }
            set(newState, action.meta, partState);
            return newState;
        },
        removeInArray: (state: any, action: any) => {
            const newState = Object.assign({}, state);
            const arrayTarget = get(newState, action.meta.key, []);
            const arrayClean = remove(arrayTarget, (n) => n !== arrayTarget[action.meta.index]);
            set(newState, action.meta.key, arrayClean);
            return newState;
        }
    },
    {},
    options
);
