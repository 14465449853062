import {
  faCaretDown,
  faCaretUp,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, FieldArray, WrappedFieldArrayProps } from "redux-form";
import {
  ProductTypes,
  useCategoriesQuery,
  useOptionsQuery,
  useProductsQuery,
  useSegmentsQuery,
  useSellerQuery,
} from "../../../graphql/generated/types";
import {
  hours,
  minutes,
  monthDays,
  weekDays,
} from "../../../helpers/PickerValues";
import { Colors } from "../../../styles/Colors";
import theme from "../../../styles/Theme.module.scss";
import {
  AppButton,
  AppCol,
  AppFormPicker,
  AppLabel,
  AppRow,
} from "../../components";
import { AppFormTextField } from "../../components/ReduxFormComponents/AppFormTextField";

export interface IFieldArrayProps {
  sellerId: string;
}

export const FieldArraySegments: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  const { t } = useTranslation();

  const { data } = useSegmentsQuery({
    variables: { skip: 0, limit: 1000 },
  });

  if (fields.length === 0) fields.push("");

  if (!data) return null;
  const segments = data.segments.results.map((segment) => {
    return { label: segment.name, value: segment.id };
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((segment, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={segment}
                component={AppFormPicker}
                options={segments}
                label={
                  index === 0 && t("fieldLabel.segments", "Product Segments")
                }
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addSegment", "ADD A SEGMENT")}
      </AppButton>
    </div>
  );
};

export const FieldArrayCategories: FunctionComponent<
  WrappedFieldArrayProps
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  const { data } = useCategoriesQuery({
    variables: { skip: 0, limit: 1000 },
  });

  if (fields.length === 0) fields.push("");

  if (!data) return null;
  const categories = data.categories.results.map((category) => {
    return { label: category.name, value: category.id };
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((category, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={category}
                component={AppFormPicker}
                options={categories}
                label={
                  index === 0 &&
                  t("fieldLabel.categories", "Product Categories")
                }
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{
                  marginBottom: index === fields.length - 1 ? 15 : 5,
                }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addCategory", "ADD A CATEGORY")}
      </AppButton>
    </div>
  );
};

export const FieldArraySubCategories: FunctionComponent<
  WrappedFieldArrayProps & IFieldArrayProps
> = ({ fields, meta: { error }, sellerId }) => {
  const { t } = useTranslation();

  const { data } = useSellerQuery({
    variables: { sellerId },
  });
  if (fields.length === 0) fields.push("");

  if (!data) return null;
  const subCategories = data.seller.subCategories.map((subcategory) => {
    return { label: subcategory, value: subcategory };
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((subcategory: any, index: number) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={subcategory}
                component={AppFormPicker}
                options={subCategories}
                label={
                  index === 0 &&
                  t("fieldLabel.subCategory", "Seller SubCategories")
                }
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{
                  marginBottom: index === fields.length - 1 ? 15 : 5,
                }}
              />
            </AppCol>
            {fields.length > 0 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addSubCategory", "ADD A SUBCATEGORY")}
      </AppButton>
    </div>
  );
};

export const FieldArrayTags: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((tag, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={tag}
                component={AppFormTextField}
                label={index === 0 && t("fieldLabel.tags", "Product Tags")}
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addTag", "ADD A TAG")}
      </AppButton>
    </div>
  );
};

export const FieldArrayOptions: FunctionComponent<
  WrappedFieldArrayProps & IFieldArrayProps
> = ({ fields, meta: { error }, sellerId }) => {
  const { t } = useTranslation();

  const { data } = useOptionsQuery({
    variables: {
      filter: {
        skip: 0,
        limit: 1000,
        sellerId,
      },
    },
  });

  if (fields.length === 0) fields.push("");

  if (!data) return null;
  const options = data.options.results.map((option) => {
    return { label: option.name, value: option.id };
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((option: any, index: number) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={option}
                component={AppFormPicker}
                options={options}
                label={index === 0 && t("fieldLabel.options", "Options")}
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addOption", "ADD AN OPTION")}
      </AppButton>
    </div>
  );
};

export const FieldArrayUpsellProducts: FunctionComponent<
  WrappedFieldArrayProps & IFieldArrayProps
> = ({ fields, meta: { error }, sellerId }) => {
  const { t } = useTranslation();

  const handleRearrange = (from: number, to: number) => {
    fields.move(from, to);
  };

  const { data } = useProductsQuery({
    variables: {
      filter: {
        skip: 0,
        limit: 1000,
        sellers: [sellerId],
      },
    },
  });

  if (fields.length === 0) fields.push("");

  if (!data) return null;
  const products = data.products.results
    .filter((product) => product.type === ProductTypes.Upsell)
    .map((product) => {
      return { label: product.name, value: product.id };
    });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((product: any, index: number) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol>
              <FontAwesomeIcon
                icon={faCaretUp}
                onClick={() => {
                  if (index > 0) handleRearrange(index, index - 1);
                }}
                style={{ cursor: "pointer" }}
              />
              <FontAwesomeIcon
                icon={faCaretDown}
                onClick={() => {
                  if (index < fields.length - 1)
                    handleRearrange(index, index + 1);
                }}
                style={{ cursor: "pointer", marginBottom: index > 0 ? 20 : 0 }}
              />
            </AppCol>
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={product}
                component={AppFormPicker}
                options={products}
                label={
                  index === 0 &&
                  t("fieldLabel.upsellProducts", "Upsell Products")
                }
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addUpsell", "ADD AN UPSELL")}
      </AppButton>
    </div>
  );
};

export const FieldArrayPackProducts: FunctionComponent<
  WrappedFieldArrayProps & IFieldArrayProps
> = ({ fields, meta: { error }, sellerId }) => {
  const { t } = useTranslation();

  if (fields.length === 0)
    fields.push({
      quantityToSelect: 1,
      callToSelectMessage: `Veuillez faire un choix:`,
      productList: [],
    });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((product: any, index: number) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <>
            <div style={{ width: "100%" }}>
              <AppLabel bold>
                {`${t(
                  "fieldLabel.packProductOption",
                  "Pack Products Option Set"
                )} #${index + 1}`}
              </AppLabel>
            </div>
            <AppRow
              key={index}
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
                <Field
                  name={`${product}.quantityToSelect`}
                  component={AppFormTextField}
                  label={
                    index === 0 &&
                    t(
                      "fieldLabel.quantityToSelect",
                      "Quantity of Options to Select"
                    )
                  }
                  //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                  type="number"
                  parse={parseInt}
                  style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
                />
                <Field
                  name={`${product}.callToSelectMessage`}
                  component={AppFormTextField}
                  label={
                    index === 0 &&
                    t(
                      "fieldLabel.callToSelectMessage",
                      "Message to show for selection"
                    )
                  }
                  //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                  type="text"
                  style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
                />
                <AppLabel bold>
                  {t(
                    "fieldLabel.productList",
                    "Product(s) List (options for this set)"
                  )}
                </AppLabel>
                <FieldArray
                  name={`${product}.productList`}
                  component={FieldArrayPackProductOptions as any}
                  props={{ sellerId }}
                />
              </AppCol>
              {fields.length > 1 && (
                <FontAwesomeIcon
                  onClick={() => fields.remove(index)}
                  icon={faTrashAlt}
                  style={{
                    fontSize: 12,
                    color: Colors.transparentDark,
                    margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                    cursor: "pointer",
                  }}
                />
              )}
            </AppRow>
          </>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() =>
          fields.push({
            quantityToSelect: 1,
            callToSelectMessage: `Veuillez faire un choix:`,
            productList: [],
          })
        }
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addSegment", "ADD A SET OF OPTIONS")}
      </AppButton>
    </div>
  );
};

export const FieldArrayPackProductOptions: FunctionComponent<
  WrappedFieldArrayProps & IFieldArrayProps
> = ({ fields, meta: { error }, sellerId }) => {
  const { t } = useTranslation();

  const { data } = useProductsQuery({
    variables: {
      filter: {
        skip: 0,
        limit: 1000,
        sellers: [sellerId],
      },
    },
  });

  if (fields.length === 0) fields.push("");

  if (!data) return null;

  const products = data.products.results
    .filter((product) => product.type === ProductTypes.Item)
    .map((product) => {
      return { label: product.name, value: product.id };
    });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((productOption: any, index: number) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={productOption}
                component={AppFormPicker}
                options={products}
                // label={
                //   index === 0 &&
                //   t("fieldLabel.packProductOptions", "Pack Product Option(s)")
                // }
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addSegment", "ADD A PRODUCT")}
      </AppButton>
    </div>
  );
};

export const FieldArrayMonthDays: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((field, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={field}
                component={AppFormPicker}
                options={monthDays}
                label={
                  index === 0 && t("fieldLabel.monthDay", "Day of the Month")
                }
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="number"
                parse={parseInt}
                style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addMonthDay", "ADD A DAY IN THE MONTH")}
      </AppButton>
    </div>
  );
};

export const FieldArrayWeekDays: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((field, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={field}
                component={AppFormPicker}
                options={weekDays}
                label={index === 0 && t("fieldLabel.weekDay", "WeekDay")}
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="number"
                parse={parseInt}
                style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addWeekDay", "ADD A WEEKDAY")}
      </AppButton>
    </div>
  );
};

export const FieldArrayHours: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push([0, 0]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((field, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <FieldArray name={field} component={FieldArrayHour as any} />
            </AppCol>
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addHour", "ADD AN HOUR")}
      </AppButton>
    </div>
  );
};

export const FieldArrayHour: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push(0);
  if (fields.length === 1) fields.push(0);

  return (
    <AppRow
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 10,
        marginBottom: 15,
      }}
    >
      {fields.map((field, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppCol style={{ width: "20%" }}>
            <Field
              name={field}
              component={AppFormPicker}
              options={index === 0 ? hours : minutes}
              label={
                index === 0
                  ? t("fieldLabel.hours", "Hours")
                  : t("fieldLabel.minutes", "Minutes")
              }
              //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
              type="number"
              parse={parseInt}
              style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
            />
          </AppCol>
        );
      })}
      {/* <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addWeekDay", "ADD A WEEKDAY")}
      </AppButton> */}
    </AppRow>
  );
};
