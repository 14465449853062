import { faCheckSquare, faSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Colors } from "../../../styles/Colors";
import theme from "../../../styles/Theme.module.scss";
import { AppCol } from "../AppCol";
import { AppRow } from "../AppRow";
export interface IAppCheckBoxProps {
  light?: boolean;
  bold?: boolean;
  label?: string;
  help?: string;
  comment?: string;
  style?: React.CSSProperties;
  helpTextStyle?: React.CSSProperties;
  onChange: (value: boolean) => void;
  initialValue?: boolean;
  valid?: boolean;
  disabled?: boolean;
  className?: string;
}

export const AppCheckBox: FunctionComponent<IAppCheckBoxProps> = ({
  help,
  bold,
  onChange,
  comment,
  light,
  style,
  initialValue,
  disabled,
  valid,
  className,
  helpTextStyle,
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (initialValue) {
      setChecked(initialValue);
    }
  }, [initialValue]);

  return (
    <div
      className={className}
      style={{ ...style, cursor: "pointer" }}
      onClick={() => {
        if (!disabled) {
          setChecked(!checked);
          onChange(!checked);
        }
      }}
    >
      <AppRow style={{ justifyContent: "flex-start", alignItems: "center" }}>
        <FontAwesomeIcon
          icon={checked ? faCheckSquare : faSquare}
          color={
            disabled
              ? Colors.disabled
              : valid
              ? Colors.valid
              : light
              ? Colors.light
              : Colors.dark
          }
          style={{ marginRight: 10 }}
        />
        <AppCol>
          <div
            className={`${theme.labelStyle} ${bold ? theme.bold : ""} ${
              disabled
                ? theme.colorDisabled
                : light
                ? theme.colorLight
                : theme.colorDark
            }`}
          >
            {comment}
          </div>
        </AppCol>
      </AppRow>

      {help && (
        <span
          style={{ ...helpTextStyle }}
          className={`${theme.smallTextStyle} ${theme.bold} ${
            light ? theme.colorTransparentLight : theme.colorTransparentDark
          }`}
        >
          {help}
        </span>
      )}
    </div>
  );
};
