import {
  faCaretDown,
  faCaretUp,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, FieldArray, WrappedFieldArrayProps } from "redux-form";
import { TimeSlot } from "../../../graphql/generated/types";
import { languagesOptions } from "../../../helpers/Languages";
import { hours, minutes } from "../../../helpers/PickerValues";
import { Colors } from "../../../styles/Colors";
import theme from "../../../styles/Theme.module.scss";
import {
  AppButton,
  AppCard,
  AppCol,
  AppFormPicker,
  AppFormTextField,
  AppLabel,
  AppRow,
  AppText,
} from "../../components";

export const FieldArraySellerSubCategories: FunctionComponent<
  WrappedFieldArrayProps
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  const handleRearrange = (from: number, to: number) => {
    fields.move(from, to);
  };

  if (fields.length === 0) fields.push("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((subCategory, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol>
              <FontAwesomeIcon
                icon={faCaretUp}
                onClick={() => {
                  if (index > 0) handleRearrange(index, index - 1);
                }}
                style={{ cursor: "pointer" }}
              />
              <FontAwesomeIcon
                icon={faCaretDown}
                onClick={() => {
                  if (index < fields.length - 1)
                    handleRearrange(index, index + 1);
                }}
                style={{ cursor: "pointer", marginBottom: index > 0 ? 20 : 0 }}
              />
            </AppCol>
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={subCategory}
                component={AppFormTextField}
                label={
                  index === 0 &&
                  t(
                    "fieldLabel.subCategory",
                    "Product Categories of the Seller"
                  )
                }
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{ marginBottom: index === fields.length - 1 ? 15 : 5 }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addCategory", "ADD A CATEGORY")}
      </AppButton>
    </div>
  );
};

export const FieldArraySellerLanguagesSpoken: FunctionComponent<
  WrappedFieldArrayProps
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((language, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={language}
                component={AppFormPicker}
                options={languagesOptions()}
                label={index === 0 && t("fieldLabel.languages", "Languages")}
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{
                  marginBottom: index === fields.length - 1 ? 15 : 5,
                }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addLanguage", "ADD A LANGUAGE")}
      </AppButton>
    </div>
  );
};

export const FieldArraySellerImages: FunctionComponent<
  WrappedFieldArrayProps
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((image, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={image}
                component={AppFormTextField}
                label={index === 0 && t("fieldLabel.image", "Image")}
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{
                  marginBottom: index === fields.length - 1 ? 15 : 5,
                }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addImage", "ADD AN IMAGE")}
      </AppButton>
    </div>
  );
};

export const FieldArraySellerAvailabilities: FunctionComponent<
  WrappedFieldArrayProps
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  const weekdays = [
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
    { value: 7, label: "Sunday" },
  ];

  if (fields.length === 0) fields.push("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      <div style={{ width: "100%", marginBottom: 10 }}>
        <AppLabel bold>
          {" "}
          {t("label.opening", "Opening Days and Times")}
        </AppLabel>
      </div>
      {fields.map((availabilities, index) => {
        // console.log('Field Day', day);
        return (
          <AppCard
            key={index}
            border={1}
            style={{ width: "90%", marginLeft: 0, marginRight: 0 }}
          >
            <AppRow
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <AppCol style={{ width: "93%" }}>
                <Field
                  name={`${availabilities}.weekDay`}
                  type="text"
                  component={AppFormPicker}
                  options={weekdays}
                  label="WeekDay"
                />
              </AppCol>
              <FontAwesomeIcon
                icon={faTrashAlt}
                style={{ cursor: "pointer" }}
                onClick={() => fields.remove(index)}
              />
            </AppRow>
            <AppCol cols="col-12 col-md-12">
              <FieldArray<{}, TimeSlot>
                name={`${availabilities}.timeSlots`}
                component={FieldArrayTimeSlots}
              />
            </AppCol>
          </AppCard>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addWeekday", "ADD AN OPENING DAY")}
      </AppButton>
    </div>
  );
};

export const FieldArrayTimeSlots: FunctionComponent<
  WrappedFieldArrayProps<TimeSlot>
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  return (
    <>
      {fields.map((timeSlot, index) => {
        // console.log('Field TimeSlot', timeSlot);

        return (
          <div key={index}>
            <AppCard
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <AppRow
                style={{ justifyContent: "space-between", width: "100%" }}
              >
                <AppCol style={{ width: "100%" }}>
                  <AppRow
                    style={{
                      alignItems: "start",
                      justifyContent: "start",
                      marginBottom: 20,
                    }}
                  >
                    <AppCol style={{ paddingRight: 10, width: "45%" }}>
                      <AppLabel bold>
                        {t("fieldLabel.timeSlotFrom", "Starts at:")}
                      </AppLabel>
                      <FieldArray
                        name={`${timeSlot}.from`}
                        component={FieldArrayTime as any}
                        props
                      />
                    </AppCol>
                    <AppCol style={{ width: "45%" }}>
                      <AppLabel bold>
                        {t("fieldLabel.timeSlotTo", "Ends at:")}
                      </AppLabel>
                      <FieldArray
                        name={`${timeSlot}.to`}
                        component={FieldArrayTime as any}
                        props
                      />
                    </AppCol>
                  </AppRow>
                </AppCol>
                {index > 0 && (
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ cursor: "pointer" }}
                    onClick={() => fields.remove(index)}
                  />
                )}
              </AppRow>
            </AppCard>
          </div>
        );
      })}
      <AppRow style={{ justifyContent: "center" }}>
        <AppButton
          pill
          extraSmall
          type="button"
          borderWidth={1}
          transparentDark
          className={theme.borderColorTransparentDark}
          style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
          center
          onClick={() => fields.push({ from: [8, 30], to: [19, 30] })}
        >
          <AppRow style={{ alignItems: "center" }}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ fontSize: 10, paddingBottom: 1, marginRight: 5 }}
            />
            {t("button.addTimeSlot", "ADD A TIMESLOT")}
          </AppRow>
        </AppButton>
      </AppRow>
    </>
  );
};

export const FieldArrayTime: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  return (
    <AppRow
      style={{
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      {fields.map((time, index) => {
        return (
          <AppRow key={index}>
            {index === 1 && (
              <AppText bold style={{ margin: "0 10px" }}>
                :
              </AppText>
            )}

            <Field
              name={`${time}`}
              component={AppFormPicker}
              options={index === 0 ? hours : minutes}
              // help={t("fieldHelp.timeSlotStartTime")}
              style={{ width: "50px" }}
            />
          </AppRow>
        );
      })}
    </AppRow>
  );
};

export const FieldArraySellerOptions: FunctionComponent<
  WrappedFieldArrayProps
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((language, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <Field
                name={language}
                component={AppFormPicker}
                options={[]}
                label={
                  index === 0 &&
                  t(
                    "fieldLabel.sellerOptions",
                    "Option(s) proposed to every Customer"
                  )
                }
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{
                  marginBottom: index === fields.length - 1 ? 15 : 5,
                }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addOption", "ADD AN OPTION")}
      </AppButton>
    </div>
  );
};
