import React from "react";
import theme from "../../styles/Theme.module.scss";

export interface IAppTextProps {
  valid?: boolean;
  danger?: boolean;
  transparentDark?: boolean;
  transparentLight?: boolean;
  light?: boolean;
  warning?: boolean;
  thin?: boolean;
  disabled?: boolean;
  bold?: boolean;
  center?: boolean;
  right?: boolean;
  textStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
  small?: boolean;
}

export const AppText: React.FC<IAppTextProps> = ({
  valid,
  danger,
  disabled,
  light,
  warning,
  thin,
  bold,
  right,
  center,
  textStyle,
  style,
  onClick,
  children,
  className,
  transparentDark,
  transparentLight,
  small,
}) => {
  /**********************************************************
   *** STYLE ***
   **********************************************************/

  const colorClassName = `${
    disabled
      ? theme.colorDisabled
      : valid
      ? theme.colorValid
      : danger
      ? theme.colorDanger
      : warning
      ? theme.colorWarning
      : light
      ? theme.colorLight
      : transparentDark
      ? theme.colorTransparentDark
      : transparentLight
      ? theme.colorTransparentLight
      : theme.colorDark
  }`;
  const fontWeightClassName = `${
    bold ? theme.bold : thin ? theme.thin : theme.normal
  }`;
  const textAlignClassName = `${
    center ? theme.textAlignCenter : right ? theme.textAlignRight : undefined
  }`;

  const containerClassName = `${onClick && theme.cursorPointer} ${
    small ? theme.smallTextStyle : theme.textStyle
  } ${fontWeightClassName} ${textAlignClassName} ${colorClassName} ${className}`;

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <div
      className={`${containerClassName}`}
      style={{ ...style, ...textStyle }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
