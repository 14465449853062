import { get, indexOf } from "lodash";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import {
  OrderStatuses,
  User,
  useMessengersQuery,
} from "../../../graphql/generated/types";
import { AppButton, AppCard, AppFormPicker, AppText } from "../../components";

export interface IUpdateBaseFormProps {
  initialValues: any;
  loading?: boolean;
  fields?: string[];
  submitButtonString: any;
}

export type IUpdateFormProps = IUpdateBaseFormProps &
  InjectedFormProps<any, IUpdateBaseFormProps>;

const ReduxForm: FunctionComponent<IUpdateFormProps> = ({
  initialValues,
  loading,
  handleSubmit,
  error,
  fields,
  submitButtonString,
}) => {
  const { t } = useTranslation();

  const orderStatusOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(OrderStatuses)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    orderStatusOptions.push({ value: propertyValue, label: propertyKey });
  }

  /**********************************************************
   *** QUERY ***
   **********************************************************/
  const [messengersOptions, setMessengersOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const { data, loading: mloading } = useMessengersQuery({
    onCompleted: (data) => {
      console.log(data);

      const messengers: User[] = get(data, "messengers.results", []);
      const options: any[] = [];
      messengers.forEach((messenger) => {
        const option = {
          value: messenger.id,
          label: `${messenger.firstName} ${messenger.lastName}`,
        };
        options.push(option);
      });
      setMessengersOptions(options);
    },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
    fetchPolicy: "network-only",
  });

  if (!data || mloading) return null;

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}

      <AppCard
        style={{
          margin: "20px 0",
        }}
      >
        {(!fields || indexOf(fields, "status") !== -1) && (
          <Field
            name="status"
            component={AppFormPicker}
            // firstOptionAsDefaultValue={initialValues ? false : true}
            options={orderStatusOptions}
            label={t("fieldLabel.status", "Status")}
            //   help={t("fieldHelp.resourceType", "Type of Account")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "messenger") !== -1) && (
          <Field
            name="messenger"
            component={AppFormPicker}
            // firstOptionAsDefaultValue={initialValues ? false : true}
            options={messengersOptions}
            label={t("fieldLabel.messenger", "Messenger")}
            //   help={t("fieldHelp.resourceType", "Type of Account")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}
      </AppCard>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}
      <AppButton primary valid type="submit" loading={loading}>
        {submitButtonString}
      </AppButton>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: "sellerForm",
})(ReduxForm);
