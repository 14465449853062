import {
  faCheck,
  faCheckSquare,
  faDrawPolygon,
  faExternalLinkAlt,
  faImage,
  faMagic,
  faPencil,
  faSquare,
  faStoreAlt,
  faStoreAltSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { find, get } from "lodash";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  GeoPoint,
  Seller,
  SellerStatuses,
  useCheckStripeSellerAccountMutation,
  useCreateStripeSellerLinkMutation,
  useSellersQuery,
  useUpdateSellerMutation,
} from "../../graphql/generated/types";
import {
  adminDetailsCompleted,
  apiDetailsCompleted,
  dealDetailsCompleted,
  deliveryDetailsCompleted,
  displayDetailsCompleted,
} from "../../helpers/SellerDetailsCompletion";
import { Colors } from "../../styles/Colors";
import {
  AppButton,
  AppCard,
  AppDialog,
  AppError,
  AppLoader,
  AppRow,
  AppTitle,
} from "../components";
import { AppText } from "../components/AppText";
import { AppCheckBox } from "../components/FormComponents/AppCheckBox";
import { Create } from "./mutations/Create";
import { Delete } from "./mutations/Delete";
import { Update } from "./mutations/Update";
import { UploadImages } from "./mutations/UploadImages";
import { PolygonArea } from "./PolygonArea";
import styles from "./Sellers.module.scss";

export interface Checked {
  id: string;
  check: boolean;
}

export interface IFuncCompProps {}

export const Sellers: FunctionComponent<IFuncCompProps> = () => {
  const { t } = useTranslation();
  //

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowPerPage] = useState<number>(50);

  const [deleteRow, setDeleteRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);
  const [updateRow, setUpdateRow] = useState<
    { id: string; show: boolean; fields: string[] } | undefined
  >(undefined);
  const [updateImages, setUpdateImages] = useState<
    { seller: Seller; show: boolean } | undefined
  >(undefined);
  const [createRow, setCreateRow] = useState<boolean>(false);
  const [defineDeliveryAreaPolygon, setDefineDeliveryAreaPolygon] = useState<
    | {
        sellerId: string;
        initialPolygon: GeoPoint | undefined | null;
        show: boolean;
      }
    | undefined
  >(undefined);

  /**********************************************************
   *** SELECT ***
   **********************************************************/

  // const handleCheckeds = (row: any) => {
  //   const updateCheckeds = checkeds.filter(({ id, check }) => {
  //     return id !== row.id && id !== "";
  //   });
  //   const checkObject = find(checkeds, (c) => c.id === row.id);
  //   updateCheckeds.push({
  //     id: row.id,
  //     check: checkObject ? !checkObject.check : true,
  //   });
  //   const tmpListrow: any[] = [...rowsSelected];
  //   tmpListrow.push(row);
  //   const newrowsSelectedList = tmpListrow.filter((row) => {
  //     for (const { id, check } of updateCheckeds) {
  //       if (check && row && id === row.id) return true;
  //     }
  //     return false;
  //   });
  //   setRowsSelected(newrowsSelectedList);
  //   setCheckeds(updateCheckeds);
  // };

  // const unselectAll = () => {
  //   setRowsSelected([]);
  //   setCheckeds([]);
  // };

  /**********************************************************
   *** TABLE CONTROL ***
   **********************************************************/

  // const getCheck = (idInput: string): boolean => {
  //   for (const { id, check } of checkeds) {
  //     if (id === idInput) return check;
  //   }
  //   return false;
  // };

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const { data, loading, error, refetch } = useSellersQuery({
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
    },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
    fetchPolicy: "network-only",
  });

  const [update] = useUpdateSellerMutation({
    onCompleted: () => {
      if (refetch) refetch();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  const [createLink] = useCreateStripeSellerLinkMutation({
    onCompleted: () => {
      if (refetch) refetch();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  const [checkStripeAccount] = useCheckStripeSellerAccountMutation({
    onCompleted: () => {
      if (refetch) refetch();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });
  /**********************************************************
   *** RENDER ***
   **********************************************************/

  if (error) {
    return <AppError title={"GraphQL Error"} error={error} />;
  }
  if (loading) {
    return (
      <AppLoader light size={25} style={{ color: Colors.light }} thickness={3}>
        Loading Sellers...
      </AppLoader>
    );
  }

  const sellers = get(data, "sellers.results", []);

  return (
    <div className={styles.App}>
      <AppRow style={{ justifyContent: "space-between", padding: "0 20px" }}>
        <AppTitle bold>{`Sellers`}</AppTitle>
        <AppButton
          pill
          onClick={() => {
            setCreateRow(true);
          }}
        >
          {t("button.create", "CREATE")}
        </AppButton>
      </AppRow>
      <AppCard style={stylesheet.appCard as any}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                STATUS
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                STRIPE ACCOUNT
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                SELLER
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                OPENING
              </TableCell>
              {/*               <TableCell style={{ ...stylesheet.columnHeadText }}>
                LEGALS
              </TableCell> */}
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                CONTACTS
              </TableCell>
              {/*               <TableCell style={{ ...stylesheet.columnHeadText }}>
                INVOICING
              </TableCell> */}
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                ADMIN
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                DISPLAY
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                DELIVERY
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                DEAL
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                API
              </TableCell>{" "}
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                ZONE
              </TableCell>
              {/* <TableCell
                style={{
                  paddingRight: 21,
                  paddingBottom: 5,
                  textAlign: "right",
                }}
              >
                <FontAwesomeIcon
                  onClick={() => unselectAll()}
                  data-tip="Unselect all."
                  icon={faTimesSquare}
                  style={{ fontSize: 16, cursor: "pointer" }}
                  color={Colors.transparentDark}
                />
              </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {sellers.map((seller: Seller, key: number) => {
              return [
                <StyledTableRow
                  key={`${key}-master`}
                  hover
                  tabIndex={-1}
                  onDoubleClick={() => {}}
                >
                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppButton
                      link
                      onClick={() => {
                        if (
                          // seller.stripeAcceptance &&
                          adminDetailsCompleted(seller) &&
                          displayDetailsCompleted(seller) &&
                          deliveryDetailsCompleted(seller) &&
                          dealDetailsCompleted(seller)
                        )
                          update({
                            variables: {
                              id: seller.id,
                              status:
                                seller.status === SellerStatuses.Deactivated
                                  ? SellerStatuses.Activated
                                  : SellerStatuses.Deactivated,
                            },
                          });
                      }}
                      textStyle={{ fontSize: 14 }}
                    >
                      {
                        /* seller.stripeAcceptance && */
                        adminDetailsCompleted(seller) &&
                          displayDetailsCompleted(seller) &&
                          deliveryDetailsCompleted(seller) &&
                          dealDetailsCompleted(seller) && (
                            <FontAwesomeIcon
                              icon={
                                seller.status === SellerStatuses.Activated
                                  ? faCheckSquare
                                  : faSquare
                              }
                              style={{ fontSize: 16 }}
                              color={
                                seller.status === SellerStatuses.Activated
                                  ? Colors.valid
                                  : Colors.transparentDark
                              }
                            />
                          )
                      }
                    </AppButton>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      bold
                      valid={seller.status === SellerStatuses.Activated}
                      danger={seller.status === SellerStatuses.Deactivated}
                    >{`${seller.status} `}</AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <AppButton
                        link
                        onClick={() => {
                          if (seller.stripeAccountLinkUrl)
                            window.location.href = seller.stripeAccountLinkUrl;
                          else
                            createLink({
                              variables: {
                                sellerId: seller.id,
                                account: seller.stripeAccountId ?? "",
                                refresh_url: `https://admin.goboxbox.com/sellers/accountLinkExpired?${seller.id}`,
                                return_url: `https://admin.goboxbox.com/sellers`,
                                type: "account_onboarding",
                                sellerName: seller.name,
                                sellerEmail: seller.email ?? "",
                                sellerCountry: seller.country ?? "FR",
                              },
                            });
                        }}
                        textStyle={{ fontSize: 14 }}
                      >
                        {
                          <FontAwesomeIcon
                            icon={
                              seller.stripeAcceptance
                                ? faCheck
                                : seller.stripeAccountLinkUrl
                                ? faExternalLinkAlt
                                : faMagic
                            }
                            style={{ fontSize: 10 }}
                            color={
                              seller.stripeAcceptance
                                ? Colors.valid
                                : seller.stripeAccountLinkUrl
                                ? Colors.dark
                                : Colors.danger
                            }
                          />
                        }
                      </AppButton>
                      {seller.stripeAcceptance ? (
                        <div>
                          <AppText
                            valid
                            bold
                          >{`STRIPE ACCOUNT VALIDATED`}</AppText>
                          {/* <AppText
                            disabled
                            bold
                            small
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            UPDATE ACCOUNT
                          </AppText> */}
                        </div>
                      ) : seller.stripeAccountLinkUrl ? (
                        <div>
                          <AppText bold>{`CONNECT TO STRIPE`}</AppText>
                          <AppRow style={{ gap: 10 }}>
                            <AppText
                              onClick={() => {
                                checkStripeAccount({
                                  variables: {
                                    sellerId: seller.id,
                                    stripeAccount: seller.stripeAccountId ?? "",
                                  },
                                });
                              }}
                              bold
                              small
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              CHECK ACCOUNT
                            </AppText>
                            <AppText
                              onClick={() => {
                                update({
                                  variables: {
                                    id: seller.id,
                                    stripeAccountLinkUrl: "",
                                  },
                                });
                              }}
                              bold
                              small
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              DELETE LINK
                            </AppText>
                          </AppRow>
                        </div>
                      ) : (
                        <div>
                          <AppText
                            danger
                            bold
                          >{`START STRIPE PROCESS`}</AppText>
                          {!seller.stripeAccountId && (
                            <AppText disabled bold small>
                              Ready for LIVE
                            </AppText>
                          )}
                        </div>
                      )}
                    </AppRow>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <FontAwesomeIcon
                        onClick={() => {
                          if (!seller.automaticOpening)
                            update({
                              variables: {
                                id: seller.id,
                                isOpen: !seller.isOpen,
                              },
                            });
                        }}
                        data-tip={"Only in Manual"}
                        icon={seller.isOpen ? faStoreAlt : faStoreAltSlash}
                        style={{
                          fontSize: 16,
                          marginRight: 15,
                          cursor: "pointer",
                          outline: "none",
                        }}
                        color={seller.isOpen ? Colors.valid : Colors.danger}
                      />
                      <div>
                        <AppText bold>{`${seller.name}`.toUpperCase()}</AppText>
                        <AppText small>{`${seller.companyName}`}</AppText>
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppCheckBox
                      bold
                      initialValue={seller.automaticOpening}
                      comment={seller.automaticOpening ? "Automatic" : "Manual"}
                      onChange={(value) => {
                        if (value !== seller.automaticOpening)
                          update({
                            variables: {
                              id: seller.id,
                              automaticOpening: value,
                            },
                          });
                      }}
                      style={{ marginRight: 20 }}
                    />
                  </TableCell>

                  {/* <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText bold>{`SIRET: ${seller.workNumber}`}</AppText>
                    <AppText small>{`VAT: ${
                      seller.vatIdentifier ?? ""
                    }`}</AppText>
                  </TableCell> */}

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText bold>{`${seller.contactName}`}</AppText>
                    <AppText
                      small
                    >{`${seller.phoneNumber} (${seller.language})`}</AppText>
                  </TableCell>

                  {/*   <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText bold>{`${seller.billingAddress}`}</AppText>
                    <AppText small>{`${seller.email}`}</AppText>
                  </TableCell> */}

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                      textAlign: "left",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <FontAwesomeIcon
                        onClick={() =>
                          setUpdateRow({
                            id: seller.id,
                            show: true,
                            fields: [
                              "name",
                              "companyName",
                              "contactName",
                              "email",
                              "phoneNumber",
                              "language",
                              "billingAddress",
                              "country",
                              "vatIdentifier",
                              "workNumber",
                            ],
                          })
                        }
                        data-tip={"Update data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        style={{
                          margin: "0px 10px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color: adminDetailsCompleted(seller)
                            ? Colors.dark
                            : Colors.danger,
                        }}
                      />
                      <div>
                        <AppText
                          small
                          valid={adminDetailsCompleted(seller)}
                          danger={!adminDetailsCompleted(seller)}
                        >
                          Admin Details
                        </AppText>
                        <AppText
                          bold
                          valid={adminDetailsCompleted(seller)}
                          danger={!adminDetailsCompleted(seller)}
                        >
                          {adminDetailsCompleted(seller)
                            ? "COMPLETE"
                            : "INCOMPLETE"}
                        </AppText>
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                      textAlign: "left",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <FontAwesomeIcon
                        onClick={() =>
                          setUpdateRow({
                            id: seller.id,
                            show: true,
                            fields: [
                              "name",
                              "headline",
                              "deliveryPromess",
                              "description",
                              "subCategories",
                              "displayAddress",
                              "languagesSpoken",
                            ],
                          })
                        }
                        data-tip={"Update data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        style={{
                          margin: "0px 10px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color: displayDetailsCompleted(seller)
                            ? Colors.dark
                            : Colors.danger,
                        }}
                      />
                      <FontAwesomeIcon
                        onClick={() =>
                          setUpdateImages({
                            seller,
                            show: true,
                          })
                        }
                        data-tip={"Update data"}
                        data-for="updateToolTip"
                        icon={faImage}
                        style={{
                          margin: "0px 10px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color:
                            seller.logo && seller.mainImage
                              ? Colors.dark
                              : Colors.danger,
                        }}
                      />
                      <div>
                        <AppText
                          small
                          valid={displayDetailsCompleted(seller)}
                          danger={!displayDetailsCompleted(seller)}
                        >
                          Display Details
                        </AppText>
                        <AppText
                          bold
                          valid={displayDetailsCompleted(seller)}
                          danger={!displayDetailsCompleted(seller)}
                        >
                          {displayDetailsCompleted(seller)
                            ? "COMPLETE"
                            : "INCOMPLETE"}
                        </AppText>
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                      textAlign: "left",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <FontAwesomeIcon
                        onClick={() =>
                          setUpdateRow({
                            id: seller.id,
                            show: true,
                            fields: [
                              "pickupAddress",
                              "availabilities",
                              "maxPreparationDuration",
                              "sellerOptions",
                            ],
                          })
                        }
                        data-tip={"Update data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        style={{
                          margin: "0px 10px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color: deliveryDetailsCompleted(seller)
                            ? Colors.dark
                            : Colors.danger,
                        }}
                      />

                      <div>
                        <AppText
                          small
                          valid={deliveryDetailsCompleted(seller)}
                          danger={!deliveryDetailsCompleted(seller)}
                        >
                          Delivery Details
                        </AppText>
                        <AppText
                          bold
                          valid={deliveryDetailsCompleted(seller)}
                          danger={!deliveryDetailsCompleted(seller)}
                        >
                          {deliveryDetailsCompleted(seller)
                            ? "COMPLETE"
                            : "INCOMPLETE"}
                        </AppText>
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                      textAlign: "left",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <FontAwesomeIcon
                        onClick={() =>
                          setUpdateRow({
                            id: seller.id,
                            show: true,
                            fields: [
                              "commissionType",
                              "commissionValue",
                              "currency",
                            ],
                          })
                        }
                        data-tip={"Update data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        style={{
                          margin: "0px 10px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color: dealDetailsCompleted(seller)
                            ? Colors.dark
                            : Colors.danger,
                        }}
                      />
                      <div>
                        <AppText
                          small
                          valid={dealDetailsCompleted(seller)}
                          danger={!dealDetailsCompleted(seller)}
                        >
                          Deal Details
                        </AppText>
                        <AppText
                          bold
                          valid={dealDetailsCompleted(seller)}
                          danger={!dealDetailsCompleted(seller)}
                        >
                          {dealDetailsCompleted(seller)
                            ? "COMPLETE"
                            : "INCOMPLETE"}
                        </AppText>
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                      textAlign: "left",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <FontAwesomeIcon
                        onClick={() =>
                          setUpdateRow({
                            id: seller.id,
                            show: true,
                            fields: ["apiService", "accessToken", "locationId"],
                          })
                        }
                        data-tip={"Update data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        style={{
                          margin: "0px 10px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color: apiDetailsCompleted(seller)
                            ? Colors.dark
                            : Colors.transparentDark,
                        }}
                      />
                      <div>
                        <AppText
                          small
                          valid={apiDetailsCompleted(seller)}
                          transparentDark={!apiDetailsCompleted(seller)}
                        >
                          API Service
                        </AppText>
                        <AppText
                          bold
                          valid={apiDetailsCompleted(seller)}
                          transparentDark={!apiDetailsCompleted(seller)}
                        >
                          {apiDetailsCompleted(seller)
                            ? "COMPLETE"
                            : "INCOMPLETE"}
                        </AppText>
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell scope="row" style={{ paddingRight: 20 }}>
                    <AppRow style={{ justifyContent: "flex-end" }}>
                      <FontAwesomeIcon
                        onClick={() =>
                          setDefineDeliveryAreaPolygon({
                            sellerId: seller.id,
                            initialPolygon: seller.deliveryAreaPolygon
                              ? seller.deliveryAreaPolygon
                              : {
                                  type: "polygon",
                                  coordinates: [[[-1.43064, 43.687511]]],
                                },
                            show: true,
                          })
                        }
                        data-tip={"Draw the polygon delivery area"}
                        data-for="updateToolTip"
                        icon={faDrawPolygon}
                        style={{
                          margin: "0px 20px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color: seller.deliveryAreaPolygon
                            ? Colors.valid
                            : Colors.danger,
                        }}
                      />
                      <ReactTooltip id="updateToolTip" place="left" multiline />
                      {/* <AppTimerButton
                        data-tip={
                          "Delete row data<br/>Press this button for 5s"
                        }
                        data-for="deleteToolTip"
                        style={{ padding: "0 10px 0 0" }}
                        disabled={getCheck(seller.id)}
                        endOfTimeFunction={() => {
                          setDeleteRow({ id: seller.id, show: true });
                        }}
                      />
                      <ReactTooltip id="deleteToolTip" place="left" multiline /> */}
                    </AppRow>
                  </TableCell>
                </StyledTableRow>,
              ];
            })}
          </TableBody>
        </Table>

        <AppRow
          style={{
            justifyContent: "space-between",
          }}
        >
          {/*             <SellersFiltersForm width={250} />
           */}
          <Table style={{ width: "100%" }}>
            <TableFooter>
              <TableRow>
                <StyledTablePagination
                  labelRowsPerPage={`ROWS PER PAGE`}
                  labelDisplayedRows={(paginationInfo: any) => {
                    return `PAGE ${paginationInfo.page + 1} - FROM ${
                      paginationInfo.from
                    } TO ${paginationInfo.to} - TOTAL ${paginationInfo.count}`;
                  }}
                  count={data?.sellers.total ?? 0}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 20, 50, 100, 250, 500]}
                  page={page}
                  onPageChange={(e, page: number) => {
                    setPage(page);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    setRowPerPage(parseInt(event.target.value, 10));
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </AppRow>
      </AppCard>

      <AppDialog
        open={deleteRow ? deleteRow.show : false}
        onClose={() => setDeleteRow(undefined)}
        scroll="body"
        width={600}
      >
        <Delete id={deleteRow?.id} onClose={() => setDeleteRow(undefined)} />
      </AppDialog>

      <AppDialog
        open={updateRow ? updateRow.show : false}
        onClose={() => setUpdateRow(undefined)}
        scroll="body"
        width={500}
      >
        <Update
          id={updateRow?.id}
          initialValues={find(sellers, { id: updateRow?.id })}
          onClose={() => setUpdateRow(undefined)}
          fields={updateRow?.fields}
        />
      </AppDialog>

      <AppDialog
        open={updateImages ? updateImages.show : false}
        onClose={() => setUpdateImages(undefined)}
        scroll="body"
        width={400}
      >
        <UploadImages seller={updateImages?.seller} />
      </AppDialog>

      <AppDialog
        open={updateRow ? updateRow.show : false}
        onClose={() => setUpdateRow(undefined)}
        scroll="body"
        width={500}
      >
        <Update
          id={updateRow?.id}
          initialValues={find(sellers, { id: updateRow?.id })}
          onClose={() => setUpdateRow(undefined)}
          fields={updateRow?.fields}
        />
      </AppDialog>

      <AppDialog
        open={createRow}
        onClose={() => setCreateRow(false)}
        scroll="body"
        width={500}
      >
        <Create
          onClose={() => {
            setCreateRow(false);
            refetch();
          }}
          initialValues={{ country: "FR", language: "fr" }}
          fields={[
            "name",
            "companyName",
            "contactName",
            "email",
            "phoneNumber",
            "language",
            "billingAddress",
            "country",
            "vatIdentifier",
            "workNumber",
          ]}
        />
      </AppDialog>

      <AppDialog
        open={
          defineDeliveryAreaPolygon ? defineDeliveryAreaPolygon.show : false
        }
        onClose={() => setDefineDeliveryAreaPolygon(undefined)}
        scroll="body"
        width={1280}
      >
        <PolygonArea
          initialPolygons={
            defineDeliveryAreaPolygon?.initialPolygon?.coordinates
          }
          onClose={() => setDefineDeliveryAreaPolygon(undefined)}
          refetch={refetch}
          sellerId={defineDeliveryAreaPolygon?.sellerId ?? ""}
          containerElement={
            <div
              style={{ height: `800px`, width: "1240px", paddingBottom: 40 }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </AppDialog>
      <ReactTooltip place="right" multiline />
    </div>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTablePagination = withStyles({
  root: { bsellerBottom: 0, color: Colors.transparentDark, fontSize: 12 },
  caption: { color: Colors.transparentDark, fontSize: 10 },
  selectIcon: { color: Colors.transparentDark, fontSize: 22 },
  // MuiButtonBase: { root: { outline: 'none' } }
  // overrides: {
  //     MuiButtonBase: { root: { outline: 'none' } }
  // }
})(TablePagination);

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
