import { faCheckSquare, faSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import React, { FunctionComponent, useEffect, useState } from "react";
import { WrappedFieldProps } from "redux-form";
import { Colors } from "../../../styles/Colors";
import theme from "../../../styles/Theme.module.scss";
import { AppCol } from "../AppCol";
import { AppRow } from "../AppRow";
export interface IAppFormCheckboxProps {
  light?: boolean;
  bold?: boolean;
  help?: string;
  comment?: string;
  style?: React.CSSProperties;
  helpTextStyle?: React.CSSProperties;
  initialChecked?: boolean;
  className?: string;
}

export const AppFormCheckBox: FunctionComponent<
  IAppFormCheckboxProps & CheckboxProps & WrappedFieldProps
> = ({
  help,
  bold,
  comment,
  light,
  input,
  style,
  helpTextStyle,
  initialChecked,
  className,
  meta: { touched, error, warning },
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (initialChecked) {
      setChecked(initialChecked);
    }
  }, [initialChecked]);

  useEffect(() => {
    if (input.value) {
      setChecked(input.value);
    }
  }, [input.value]);

  return (
    <div
      className={className}
      style={{ ...style, cursor: "pointer" }}
      onClick={() => {
        setChecked(!checked);
        input.onChange(!checked);
      }}
    >
      <AppRow
        style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
      >
        <FontAwesomeIcon
          icon={checked ? faCheckSquare : faSquare}
          color={light ? Colors.light : Colors.dark}
          style={{ marginRight: 10 }}
        />
        <AppCol>
          <div
            className={`${theme.labelStyle} ${bold ? theme.bold : undefined} ${
              light ? theme.colorLight : theme.colorDark
            }`}
            style={{ marginBottom: -5 }}
          >
            {comment}
          </div>
        </AppCol>
      </AppRow>

      {(help ?? (touched && (error || warning))) && (
        <span style={{ marginLeft: 23 }}>
          {touched && error && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorDanger}`}
              style={helpTextStyle}
            >
              {error}
            </small>
          )}
          {touched && warning && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorWarning}`}
              style={helpTextStyle}
            >
              {warning}
            </small>
          )}
          {help && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${
                light ? theme.colorTransparentLight : theme.colorTransparentDark
              }`}
              style={helpTextStyle}
            >
              {help}
            </small>
          )}
        </span>
      )}
    </div>
  );
};
