import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore as createReduxStore } from 'redux';
import { reducer as form } from 'redux-form';
import thunk from 'redux-thunk';
import data from './data/reducer';

const composeEnhancers =
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    process.env.NODE_ENV !== 'production'
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const history = createBrowserHistory();

const rootReducer = combineReducers({
    router: connectRouter(history),
    data,
    form
});

export const createStore = (options?: { initialState: any }) => {
    const store = createReduxStore(
        rootReducer,
        options?.initialState ?? {},
        composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
    );

    return { store, history };
};

export type RootState = ReturnType<typeof rootReducer>;
