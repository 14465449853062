import { TextFieldProps } from "@material-ui/core/TextField";
import React, { FunctionComponent, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { WrappedFieldProps } from "redux-form";
import theme from "../../../styles/Theme.module.scss";

export interface IAppFormPhoneNumberFieldProps {
  light?: boolean;
  help?: string;
  parse?: any;
  style?: React.CSSProperties;
}

export const AppFormPhoneNumberField: FunctionComponent<
  TextFieldProps & WrappedFieldProps & IAppFormPhoneNumberFieldProps
> = ({
  style,
  input,
  help,
  meta: { touched, error, warning },
  type,
  parse,
  light,
  label,
  ...rest
}) => {
  const [value, setValue] = useState<string | any>("");

  const handleChange = (str: string) => {
    setValue(str);
    input.onChange(str);
  };

  return (
    <div style={{ ...style }}>
      {label && (
        <div style={{ marginBottom: 8 }}>
          <div
            className={`${theme.labelStyle} ${theme.bold} ${
              light ? theme.colorLight : theme.colorDark
            }`}
          >
            {label}
          </div>
        </div>
      )}

      <PhoneInput
        {...input}
        type={type}
        className={`container-fluid ${theme.inputStyle} ${theme.bold} ${
          light ? theme.colorLight : theme.colorDark
        }`}
        value={value}
        onChange={handleChange}
        style={{ padding: 0 }}
        // {...rest}
      />

      {(help ?? (touched && (error || warning))) && (
        <div style={{ marginTop: 2, marginBottom: -5 }}>
          <span>
            {touched && error && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorDanger}`}
              >
                {error}
              </small>
            )}
            {touched && warning && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorWarning}`}
              >
                {warning}
              </small>
            )}
            {help && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${
                  light
                    ? theme.colorTransparentLight
                    : theme.colorTransparentDark
                }`}
              >
                {help}
              </small>
            )}
          </span>
        </div>
      )}
    </div>
  );
};
