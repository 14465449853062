import React, { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import validate from "validate.js";
import { useAuthLazyQuery } from "../../graphql/generated/types";
import { actions } from "../../redux";
import theme from "../../styles/Theme.module.scss";
import { AppBlockFooter, AppCard, AppText, AppTitle } from "../components";
import styles from "./Signin.module.scss";
import Form from "./SigninForm";
import { validators } from "./validations/validators";
export interface ISigninProps {}

export interface IAuthRequest {
  email: string;
  password: string;
}

export const Signin: FunctionComponent<ISigninProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const saveAuthToken = useCallback(
    (token: string) => {
      dispatch(actions.setState(token, `auth.accessToken`));
    },
    [dispatch]
  );

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const [authenticate] = useAuthLazyQuery({
    onCompleted: (data) => {
      saveAuthToken(data.auth.accessToken);
      localStorage.setItem("token", data.auth.accessToken);
    },
    onError: (error) => {
      setLoading(false);
      console.log(error);
    },
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <>
      <div className={`${styles.globalContainer}`}>
        <AppCard className={`${styles.card}`}>
          <div className={`${styles.titleRow}`}>
            <AppTitle light extrabold className={`${styles.title}`}>
              {t("login.title", "Authentication")}
            </AppTitle>
            <AppText light>
              {t("login.subtitle", "Please enter your credentials")}
            </AppText>
          </div>

          <AppCard className={theme.margin0}>
            <Form
              initialValues={{
                email: "boxbox@soixanteseize.tech",
                password: "Soixante16$",
              }}
              loading={loading}
              handleOpenForgotPassword={() => {
                console.log("FORGOT PASSWORD HANDLE");
              }}
              validate={(values: IAuthRequest) =>
                validate(values, validators(t), {
                  fullMessages: false,
                })
              }
              onSubmit={(values: IAuthRequest) => {
                setLoading(true);
                authenticate({
                  variables: {
                    email: values.email,
                    password: values.password,
                  },
                });
              }}
            />
          </AppCard>

          <AppBlockFooter />
        </AppCard>
      </div>
    </>
  );
};
