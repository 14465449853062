import React from "react";
import theme from "../../styles/Theme.module.scss";

export interface IAppLabelProps {
  valid?: boolean;
  danger?: boolean;
  light?: boolean;
  warning?: boolean;
  bold?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

export const AppLabel: React.FC<IAppLabelProps> = ({
  valid,
  danger,
  light,
  warning,
  bold,
  style,
  children,
  className,
}) => {
  const color = light
    ? `${theme.colorLight}`
    : danger
    ? `${theme.colorDanger}`
    : valid
    ? `${theme.colorValid}`
    : warning
    ? `${theme.colorWarning}`
    : `${theme.colorDark}`;

  return (
    <div
      className={`${className} ${color} ${theme.labelStyle}  ${
        bold ? theme.bold : undefined
      } `}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};
