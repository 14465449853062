import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import React, { FunctionComponent, useEffect, useState } from "react";
import { WrappedFieldProps } from "redux-form";
import { Colors } from "../../../styles/Colors";
import { MaterialTheme } from "../../../styles/MaterialTheme";
import theme from "../../../styles/Theme.module.scss";
interface option {
  value: string;
  label: string;
}
export interface IAppFormPickerProps extends WrappedFieldProps {
  input: any;
  options: option[];
  firstOptionAsDefaultValue?: boolean;
  label?: string;
  help?: string;
  disableUnderline?: boolean;
  hideValue?: boolean;
  replacedValue?: string;
  light?: boolean;
  valid?: boolean;
  danger?: boolean;
  style?: React.CSSProperties;
}

export const AppFormPicker: FunctionComponent<IAppFormPickerProps> = ({
  firstOptionAsDefaultValue,
  help,
  label,
  style,
  options,
  input,
  light,
  meta: { touched, error, warning },
}) => {
  const [optionValue, setOptionValue] = useState<string>("");

  const handleChange = (event: any) => {
    setOptionValue(event.target.value);
    input.onChange(event.target.value);
  };

  useEffect(() => {
    if (input.value) {
      setOptionValue(input.value);
    }
    if (firstOptionAsDefaultValue && !input.value) {
      setOptionValue(options[0].value);
      input.onChange(options[0].value);
    }
  }, [input, firstOptionAsDefaultValue, options]);

  const StyledSelect = withStyles({
    root: { color: light ? Colors.light : Colors.dark },
    icon: { color: light ? Colors.light : Colors.dark },
  })(Select);

  const materialTheme = createTheme(MaterialTheme(light));

  return (
    <ThemeProvider theme={materialTheme}>
      <div style={{ ...style }}>
        {label && (
          <div
            style={{ marginTop: -2 }}
            className={`${theme.labelStyle}  ${theme.bold}
                            ${light ? theme.colorLight : theme.colorDark}`}
          >
            {label}
          </div>
        )}

        <StyledSelect
          style={{ padding: 0, width: "100%" }}
          value={optionValue}
          onChange={handleChange}
          disableUnderline={light}
        >
          {options &&
            options.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
        </StyledSelect>

        {(help ?? (touched && (error || warning))) && (
          <span>
            {touched && error && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorDanger}`}
              >
                {error}
              </small>
            )}
            {touched && warning && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorWarning}`}
              >
                {warning}
              </small>
            )}
            {help && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${
                  light
                    ? theme.colorTransparentLight
                    : theme.colorTransparentDark
                }`}
              >
                {help}
              </small>
            )}
          </span>
        )}
      </div>
    </ThemeProvider>
  );
};
