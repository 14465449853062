import { indexOf } from "lodash";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, InjectedFormProps, reduxForm } from "redux-form";
import { Colors } from "../../../styles/Colors";
import { AppButton, AppCard, AppText } from "../../components";
import { AppTitle } from "../../components/AppTitle";
import { FieldArraycarousselSection, FieldArrayChapters } from "./FieldArrays";

export interface IUpdateBaseFormProps {
  initialValues: any;
  loading?: boolean;
  fields?: string[];
  submitButtonString: any;
}

export type IUpdateFormProps = IUpdateBaseFormProps &
  InjectedFormProps<any, IUpdateBaseFormProps>;

const ReduxForm: FunctionComponent<IUpdateFormProps> = ({
  initialValues,
  loading,
  handleSubmit,
  error,
  fields,
  submitButtonString,
}) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}

      <AppCard
        style={{
          margin: "20px 0",
        }}
      >
        {/* (!fields || indexOf(fields, "language") !== -1) && (
          <Field
            name="language"
            component={AppFormPicker}
            firstOptionAsDefaultValue={initialValues.type}
            options={segmentStatusOptions}
            label={t("fieldLabel.language", "Language")}
            //   help={t("fieldHelp.resourceType", "Type of Account")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        ) */}

        {(!fields || indexOf(fields, "carousselSection") !== -1) && (
          <FieldArray
            name="carousselSection"
            component={FieldArraycarousselSection as any}
            props
          />
        )}

        {(!fields || indexOf(fields, "supportSection") !== -1) && (
          <>
            <AppTitle bold>
              {t("fieldLabel.supportSection", "Support Section")}
            </AppTitle>
            <div
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: Colors.transparentDark,
                padding: 10,
                marginTop: 10,
                marginBottom: 30,
                borderRadius: 5,
              }}
            >
              <FieldArray
                name="supportSection"
                component={FieldArrayChapters as any}
                props
              />
            </div>
          </>
        )}

        {(!fields || indexOf(fields, "legalsSection") !== -1) && (
          <>
            <AppTitle bold>
              {t("fieldLabel.legalsSection", "Legals Section")}
            </AppTitle>
            <div
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: Colors.transparentDark,
                padding: 10,
                marginTop: 10,
                marginBottom: 30,
                borderRadius: 5,
              }}
            >
              <FieldArray
                name="legalsSection"
                component={FieldArrayChapters as any}
                props
              />
            </div>
          </>
        )}
      </AppCard>

      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}
      <AppButton primary valid type="submit" loading={loading}>
        {submitButtonString}
      </AppButton>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: "contentForm",
})(ReduxForm);
