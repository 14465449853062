import { join } from "lodash";

export const formatTimeFromSeconds = (
  second: number,
  showSeconds?: boolean
) => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.floor(second / 60) - hours * 60;
  const seconds = second - hours * 3600 - minutes * 60;

  const hoursString = `${hours < 10 ? "0" : ""}${hours}`;
  const minutesString = `${minutes < 10 ? "0" : ""}${minutes}`;
  const secondsString = `${seconds < 10 ? "0" : ""}${seconds}`;

  return join(
    [
      `${hours > 0 ? hoursString : ""}`,
      minutesString,
      showSeconds ? secondsString : undefined,
    ].filter((o) => o),
    ":"
  );
};
