import { get } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import {
  Product,
  ProductTypes,
  useOptionsLazyQuery,
  useUpdateProductMutation,
  useUpdateProductPackMutation,
  useUpdateProductPackSubscriptionMutation,
} from "../../../graphql/generated/types";
import { apiErrors } from "../../../helpers/Errors";
import { AppRow, AppTitle } from "../../components";
import { PickerOption } from "../Products";
import UpdateForm from "./ItemForm";

export interface IUpdateProps {
  id: any;
  initialValues: Product;
  fields?: string[];
  refetch?: () => void;
  onClose: () => void;
}

export const Update: FunctionComponent<IUpdateProps> = ({
  initialValues,
  onClose,
  refetch,
  fields,
  id,
}) => {
  const { t } = useTranslation();

  const [update, { loading: loadingItem }] = useUpdateProductMutation({
    onCompleted: () => {
      if (refetch) refetch();
      onClose();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  const [updatePack, { loading: loadingPack }] = useUpdateProductPackMutation({
    onCompleted: () => {
      if (refetch) refetch();
      onClose();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  const [updateSubscription, { loading: loadingSubs }] =
    useUpdateProductPackSubscriptionMutation({
      onCompleted: () => {
        if (refetch) refetch();
        onClose();
      },
      onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
    });
  // console.log(initialValues);

  const formValues = useSelector((state) =>
    get(state, "form.productForm.values", undefined)
  );

  /**********************************************************
   *** PRODUCTS LIST FOR TYPE ==== OPTION ***
   **********************************************************/

  const [optionsList, setOptionsList] = useState<PickerOption[]>([]);

  const [getOptionList] = useOptionsLazyQuery({
    variables: {
      filter: {
        sellerId: formValues?.seller ? formValues.seller.id : undefined,
        skip: 0,
        limit: 100,
      },
    },
    onCompleted: (d) => {
      const options = d.options.results
        .map((option) => {
          if (option.activated) {
            return { label: option.name, value: option.id };
          } else return undefined;
        })
        .filter((o) => o !== undefined);
      // console.log(options);

      setOptionsList(options as any);
    },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
  });

  // console.log(productsListOptions);

  useEffect(() => {
    getOptionList();
  }, [getOptionList]);

  // console.log(initialValues);
  // console.log(initialValues.upsellProducts);

  return (
    <>
      <AppRow
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <AppTitle
          small
          extrabold
          light
          subtitle={t("update.subtitle", "Please change the needed fields")}
        >
          {t("update.title", "Update")}
        </AppTitle>
      </AppRow>

      <UpdateForm
        submitButtonString={t("button.modify", "UPDATE")}
        loading={loadingItem || loadingPack || loadingSubs}
        fields={fields}
        initialValues={{
          ...initialValues,
          seller: initialValues.seller.id,
          variants: initialValues.variants?.map((o) => o.id),
          segments: initialValues.segments?.map((o) => o.id),
          categories: initialValues.categories?.map((o) => o.id),
          options: initialValues.options?.map((o) => o.id),
          upsellProducts: initialValues.upsellProducts?.map((o) => o.id),
          crossProducts: initialValues.crossProducts?.map((o) => o.id),
          packProducts: initialValues.packProducts?.map((o) => {
            return {
              quantityToSelect: o.quantityToSelect,
              callToSelectMessage: o.callToSelectMessage,
              productList: o.productList.map((p) => p.id),
            };
          }),
        }}
        productsListOptions={optionsList}
        // validate={(values: any) =>
        //   validate(values, validators(t), { fullMessages: false })
        // }
        onSubmit={async (values: any) => {
          console.log(values);

          delete values.__typename;
          delete values.id;
          delete values.deliveryType;
          delete values.mainImage;
          delete values.images;
          delete values.variants;
          delete values.seller;
          delete values.prices;
          delete values.preparationDuration;
          delete values.updatedAt;
          delete values.createdAt;

          if (
            values.type === ProductTypes.Item ||
            values.type === ProductTypes.Upsell ||
            values.type === ProductTypes.Pack
          ) {
            delete values.months;
            delete values.monthdays;
            delete values.weekdays;
            delete values.hours;
            delete values.duration;
            delete values.frequency;
          }

          try {
            if (
              values.type === ProductTypes.Item ||
              values.type === ProductTypes.Upsell
            )
              await update({
                variables: {
                  id,
                  updateProductInput: values,
                },
              });
            if (values.type === ProductTypes.Pack) {
              await updatePack({
                variables: {
                  id,
                  updateProductPackInput: values,
                },
              });
            }
            if (values.type === ProductTypes.Subscription)
              await updateSubscription({
                variables: {
                  id,
                  updateProductPackSubscriptionInput: values,
                },
              });
          } catch (e: any) {
            throw new SubmissionError(
              apiErrors({
                errors: get(
                  e,
                  "graphQLErrors[0].extensions.exception.errors",
                  []
                ),
                message: e.message,
              })
            );
          }
        }}
      />
    </>
  );
};
