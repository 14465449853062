import {
  faCheckSquare,
  faImage,
  faPencil,
  faPlus,
  faSquare,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { find, join } from "lodash";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  DimensionsUnits,
  Product,
  ProductStatuses,
  ProductVariant,
  useProductVariantsQuery,
  useUpdateProductVariantMutation,
  VariantTypes,
  WeightUnits,
} from "../../../graphql/generated/types";
import { priceDisplay } from "../../../helpers/PriceDisplay";
import {
  productVariantDisplayDetailsCompleted,
  productVariantPriceDetailsCompleted,
} from "../../../helpers/ProductDetailsCompletion";
import { Colors } from "../../../styles/Colors";
import {
  AppButton,
  AppCheckBox,
  AppDialog,
  AppRow,
  AppText,
  AppTimerButton,
  AppTitle,
} from "../../components";
import { AppCard } from "../../components/AppCard";
import { CreatePrice } from "../mutations/CreatePrice";
import { DeletePrice } from "../mutations/DeletePrice";
import { Create } from "./mutations/Create";
import { Delete } from "./mutations/Delete";
import { Update } from "./mutations/Update";
import { UploadImages } from "./mutations/UploadImages";

export interface IVariantProps {
  product?: Product;
  onClose: () => void;
}

export const Variant: FunctionComponent<IVariantProps> = ({
  product,
  onClose,
}) => {
  //
  const { t } = useTranslation();

  /**********************************************************
   *** QUERY & MUTATION ***
   **********************************************************/

  const { data, refetch } = useProductVariantsQuery({
    variables: { productId: product?.id ?? "", skip: 0, limit: 100 },

    onError: (e) => {
      console.log(JSON.parse(JSON.stringify(e)));
    },
  });
  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [createRow, setCreateRow] = useState<boolean>(false);
  const [deleteRow, setDeleteRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);

  const [updateRow, setUpdateRow] = useState<
    | {
        show: boolean;
        id: string;
      }
    | undefined
  >(undefined);

  const [updateVariantImages, setUpdateVariantImages] = useState<
    { productVariant: ProductVariant; show: boolean } | undefined
  >(undefined);

  const [createPrice, setCreatePrice] = useState<
    | {
        show: true;
        productId: string;
        productVariantId: string;
      }
    | undefined
  >(undefined);
  const [deletePrice, setDeletePrice] = useState<
    | {
        show: boolean;
        priceId: string;
        productVariantId: string;
      }
    | undefined
  >(undefined);

  /**********************************************************
   *** MUTATIONS ***
   **********************************************************/

  const [updateProductVariant] = useUpdateProductVariantMutation({
    onCompleted: () => {
      refetch();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  const variants: ProductVariant[] = data?.productVariants.results ?? [];

  console.log(variants);
  return (
    <AppCard style={{ margin: 0, paddingTop: 35 }}>
      <AppRow style={{ justifyContent: "space-between", padding: "0 20px" }}>
        <AppTitle
          bold
          subtitle={`Segment(s): ${join(
            product?.segments?.map((segment) => segment.name),
            " | "
          )} / Category: ${join(
            product?.categories?.map((category) => category.name),
            " | "
          )}`}
        >{`Variant: "${product?.name}"`}</AppTitle>

        <AppButton
          pill
          onClick={() => {
            setCreateRow(true);
          }}
        >
          {t("button.createVariant", "CREATE NEW VARIANT")}
        </AppButton>
      </AppRow>
      <AppCard style={stylesheet.appCard as any}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                STATUS
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                SORTING
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                QTY
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                NAME
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                TYPE
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                ACTIVATION PLANNING
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                ACTIVATION
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                DISPLAY DETAILS
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                PRICE
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {variants?.map((variant, key) => (
              <StyledTableRow key={`variant-${key}`}>
                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppRow style={{ justifyContent: "flex-start" }}>
                    <AppButton
                      disabled={
                        !productVariantDisplayDetailsCompleted(variant) ||
                        !productVariantPriceDetailsCompleted(variant)
                      }
                      link
                      onClick={() => {
                        if (variant.status !== ProductStatuses.Deleted)
                          updateProductVariant({
                            variables: {
                              id: variant.id,
                              status:
                                variant.status === ProductStatuses.NotAvailable
                                  ? ProductStatuses.Available
                                  : ProductStatuses.NotAvailable,
                            },
                          });
                      }}
                      textStyle={{ fontSize: 14 }}
                    >
                      {variant.status !== ProductStatuses.Deleted &&
                        productVariantDisplayDetailsCompleted(variant) &&
                        productVariantPriceDetailsCompleted(variant) && (
                          <FontAwesomeIcon
                            icon={
                              variant.status === ProductStatuses.Available
                                ? faCheckSquare
                                : faSquare
                            }
                            style={{ fontSize: 16 }}
                            color={
                              variant.status === ProductStatuses.Available
                                ? Colors.valid
                                : Colors.transparentDark
                            }
                          />
                        )}
                    </AppButton>

                    <AppText
                      bold
                      valid={variant.status === ProductStatuses.Available}
                      danger={variant.status === ProductStatuses.NotAvailable}
                      transparentDark={
                        variant.status === ProductStatuses.Deleted
                      }
                    >{`${variant.status} `}</AppText>
                  </AppRow>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText
                    transparentDark={variant.status === ProductStatuses.Deleted}
                    small
                  >{`${variant.hasSorting ? variant.sorting : "-"}`}</AppText>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText
                    bold
                    transparentDark={variant.status === ProductStatuses.Deleted}
                  >{`${variant.quantity ? variant.quantity : 0}`}</AppText>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText
                    bold
                    transparentDark={variant.status === ProductStatuses.Deleted}
                  >{`${variant.name}`}</AppText>
                  <AppText
                    transparentDark={variant.status === ProductStatuses.Deleted}
                    small
                  >{`${variant.productReference}${variant.postfixVariantReference}`}</AppText>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText
                    bold
                    transparentDark={variant.status === ProductStatuses.Deleted}
                  >
                    {join(
                      variant.types.map(
                        (type) => `${type.type}: ${type.value}`
                      ),
                      " | "
                    )}
                  </AppText>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText
                    bold
                    transparentDark={variant.status === ProductStatuses.Deleted}
                  >{`Starts at: ${moment(variant.availabilityStartDate).format(
                    "MMM Do YYYY, HH:mm"
                  )}`}</AppText>
                  <AppText
                    bold
                    transparentDark={variant.status === ProductStatuses.Deleted}
                  >{`Ends at: ${moment(variant.availabilityEndDate).format(
                    "MMM Do YYYY, HH:mm"
                  )}`}</AppText>
                </TableCell>

                <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                  <AppCheckBox
                    disabled={variant.status === ProductStatuses.Deleted}
                    bold
                    initialValue={variant.automaticActivation}
                    comment={
                      variant.automaticActivation ? "Automatic" : "Manual"
                    }
                    onChange={(value) => {
                      if (value !== variant.automaticActivation)
                        updateProductVariant({
                          variables: {
                            id: variant.id,
                            automaticActivation: value,
                          },
                        });
                    }}
                    style={{ marginRight: 20 }}
                  />
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                    textAlign: "left",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <AppRow style={{ justifyContent: "flex-start" }}>
                    <FontAwesomeIcon
                      onClick={() => {
                        if (variant.status !== ProductStatuses.Deleted)
                          setUpdateRow({
                            id: variant.id,
                            show: true,
                            // fields: [
                            //   "name",
                            //   "deliveryType",
                            //   "reference",
                            //   "segments",
                            //   "categories",
                            //   "subCategories",
                            //   "type",
                            //   "description",
                            //   "availabilityStartDate",
                            //   "availabilityEndDate",
                            // ],
                          });
                      }}
                      data-tip={"Update data"}
                      data-for="updateToolTip"
                      icon={faPencil}
                      style={{
                        margin: "0px 10px 0 0",
                        outline: "none",
                        cursor: "pointer",
                        color:
                          variant.status === ProductStatuses.Deleted
                            ? Colors.disabled
                            : productVariantDisplayDetailsCompleted(variant)
                            ? Colors.dark
                            : Colors.danger,
                      }}
                    />
                    <FontAwesomeIcon
                      onClick={() => {
                        if (variant.status !== ProductStatuses.Deleted)
                          setUpdateVariantImages({
                            productVariant: variant,
                            show: true,
                          });
                      }}
                      data-tip={"Update data"}
                      data-for="updateToolTip"
                      icon={faImage}
                      style={{
                        margin: "0px 10px 0 0",
                        outline: "none",
                        cursor: "pointer",
                        color:
                          variant.status === ProductStatuses.Deleted
                            ? Colors.disabled
                            : variant.images &&
                              variant.images.length > 0 &&
                              variant.swatchImage
                            ? Colors.dark
                            : Colors.danger,
                      }}
                    />
                    <div>
                      <AppText
                        small
                        disabled={variant.status === ProductStatuses.Deleted}
                        valid={productVariantDisplayDetailsCompleted(variant)}
                        danger={!productVariantDisplayDetailsCompleted(variant)}
                      >
                        Display Details
                      </AppText>
                      <AppText
                        disabled={variant.status === ProductStatuses.Deleted}
                        bold
                        valid={productVariantDisplayDetailsCompleted(variant)}
                        danger={!productVariantDisplayDetailsCompleted(variant)}
                      >
                        {productVariantDisplayDetailsCompleted(variant)
                          ? "COMPLETE"
                          : "INCOMPLETE"}
                      </AppText>
                    </div>
                  </AppRow>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                    textAlign: "left",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <AppRow style={{ justifyContent: "flex-start" }}>
                    {!productVariantPriceDetailsCompleted(variant) ? (
                      <FontAwesomeIcon
                        onClick={() => {
                          if (variant.status !== ProductStatuses.Deleted)
                            setCreatePrice({
                              show: true,
                              productId: product
                                ? product
                                  ? product.id
                                  : ""
                                : "",
                              productVariantId: variant.id,
                            });
                        }}
                        data-tip={"Create data"}
                        data-for="createToolTip"
                        icon={faPlus}
                        style={{
                          margin: "0px 10px 0 0",
                          outline: "none",
                          cursor: "pointer",
                          color:
                            variant.status === ProductStatuses.Deleted
                              ? Colors.disabled
                              : productVariantPriceDetailsCompleted(variant)
                              ? Colors.dark
                              : Colors.danger,
                        }}
                      />
                    ) : (
                      <>
                        <AppTimerButton
                          data-tip={
                            "Delete row data<br/>Press this button for 5s"
                          }
                          data-for="deleteToolTip"
                          style={{ padding: "0 10px 0 0" }}
                          endOfTimeFunction={() => {
                            setDeletePrice({
                              priceId:
                                variant.prices && variant.prices.length > 0
                                  ? variant.prices[0].id
                                  : "",
                              productVariantId: variant.id,
                              show: true,
                            });
                          }}
                        />
                        <ReactTooltip
                          id="deleteToolTip"
                          place="left"
                          multiline
                        />
                      </>
                    )}

                    <div>
                      <div>
                        <AppText
                          bold
                          disabled={variant.status === ProductStatuses.Deleted}
                          danger={!productVariantPriceDetailsCompleted(variant)}
                        >
                          {productVariantPriceDetailsCompleted(variant)
                            ? priceDisplay(
                                (find(variant.prices, { country: "FR" })
                                  ?.amountWithTax ?? 0) / 100,
                                "En",
                                find(variant.prices, { country: "FR" })
                                  ?.currency ?? "EUR"
                              )
                            : `Please set a Price`}
                        </AppText>

                        {productVariantPriceDetailsCompleted(variant) && (
                          <AppText small>
                            {`${priceDisplay(
                              (find(variant.prices, { country: "FR" })
                                ?.amountWithoutTax ?? 0) / 100,
                              "En",
                              find(variant.prices, { country: "FR" })
                                ?.currency ?? "EUR"
                            )} + ${
                              find(variant.prices, { country: "FR" })?.taxRate
                            }%`}
                          </AppText>
                        )}
                      </div>
                    </div>
                  </AppRow>
                </TableCell>

                <TableCell scope="row" style={{ paddingRight: 20 }}>
                  <AppRow style={{ justifyContent: "flex-end" }}>
                    {variant.status !== ProductStatuses.Deleted && (
                      <AppTimerButton
                        data-tip={
                          "Delete row data<br/>Press this button for 5s"
                        }
                        data-for="deleteToolTip"
                        style={{ padding: "0 10px 0 0" }}
                        endOfTimeFunction={() => {
                          setDeleteRow({
                            id: variant.id,
                            show: true,
                          });
                        }}
                      />
                    )}
                    <ReactTooltip id="deleteToolTip" place="left" multiline />
                  </AppRow>
                </TableCell>

                <AppDialog
                  open={updateRow ? updateRow.show : false}
                  onClose={() => setUpdateRow(undefined)}
                  scroll="body"
                  width={500}
                >
                  <Update
                    id={updateRow?.id}
                    initialValues={variants.find((v) => v.id === updateRow?.id)}
                    onClose={() => {
                      setUpdateRow(undefined);
                      refetch();
                    }}
                    fields={[
                      "quantity",
                      "postfixVariantReference",
                      "type",
                      "name",
                      "description",
                      "weight",
                      // "weightUnit",
                      "dimensions",
                      // "dimensionsUnit",
                      "availabilityStartDate",
                      "availabilityEndDate",
                    ]}
                  />
                </AppDialog>

                <AppDialog
                  open={deleteRow ? deleteRow.show : false}
                  onClose={() => setDeleteRow(undefined)}
                  scroll="body"
                  width={600}
                >
                  <Delete
                    id={deleteRow?.id}
                    onClose={() => {
                      setDeleteRow(undefined);
                    }}
                  />
                </AppDialog>

                <AppDialog
                  open={updateVariantImages ? updateVariantImages.show : false}
                  onClose={() => setUpdateVariantImages(undefined)}
                  scroll="body"
                  width={400}
                >
                  <UploadImages
                    productVariantId={
                      updateVariantImages?.productVariant.id ?? ""
                    }
                  />
                </AppDialog>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </AppCard>

      <AppDialog
        open={createRow}
        onClose={() => setCreateRow(false)}
        scroll="body"
        width={500}
      >
        <Create
          initialValues={{
            productId: product ? product.id : "",
            productReference: product ? product.reference : "",
            weightUnit: WeightUnits.G,
            dimensionsUnit: DimensionsUnits.Cm,
            types: [{ type: VariantTypes.Default, value: "" }],
          }}
          onClose={() => {
            setCreateRow(false);
            refetch();
          }}
          fields={[
            "quantity",
            "postfixVariantReference",
            "types",
            "name",
            "description",
            "weight",
            // "weightUnit",
            "dimensions",
            // "dimensionsUnit",
            "availabilityStartDate",
            "availabilityEndDate",
          ]}
        />
      </AppDialog>

      <AppDialog
        open={createPrice ? createPrice.show : false}
        onClose={() => setCreatePrice(undefined)}
        scroll="body"
        width={500}
      >
        <CreatePrice
          initialValues={{
            productId: createPrice?.productId,
            productVariantId: createPrice?.productVariantId,
          }}
          onClose={() => {
            refetch();
            setCreatePrice(undefined);
          }}
        />
      </AppDialog>

      <AppDialog
        open={deletePrice ? deletePrice.show : false}
        onClose={() => setDeletePrice(undefined)}
        scroll="body"
        width={600}
      >
        <DeletePrice
          id={deletePrice?.priceId}
          onClose={() => {
            updateProductVariant({
              variables: {
                id: deletePrice?.productVariantId ?? "",
                status: ProductStatuses.NotAvailable,
              },
            });
            setDeletePrice(undefined);
          }}
        />
      </AppDialog>
    </AppCard>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
