import { indexOf } from "lodash";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import {
  AccountStatuses,
  AccountTypes,
} from "../../../graphql/generated/types";
import { languagesOptions } from "../../../helpers/Languages";
import {
  AppButton,
  AppCard,
  AppFormPhoneNumberField,
  AppFormPicker,
  AppFormTextField,
  AppText,
} from "../../components";

export interface IUpdateBaseFormProps {
  initialValues: any;
  loading?: boolean;
  fields?: string[];
  submitButtonString: any;
}

export type IUpdateFormProps = IUpdateBaseFormProps &
  InjectedFormProps<any, IUpdateBaseFormProps>;

const ReduxForm: FunctionComponent<IUpdateFormProps> = ({
  initialValues,
  loading,
  handleSubmit,
  error,
  fields,
  submitButtonString,
}) => {
  const { t } = useTranslation();

  const accountTypeOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(AccountTypes)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    accountTypeOptions.push({ value: propertyValue, label: propertyKey });
  }

  const accountStatusOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(AccountStatuses)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    accountStatusOptions.push({ value: propertyValue, label: propertyKey });
  }

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}

      <AppCard
        style={{
          margin: "20px 0",
        }}
      >
        {(!fields || indexOf(fields, "firstName") !== -1) && (
          <Field
            name="firstName"
            component={AppFormTextField}
            label={t("fieldLabel.firstname", "FirstName")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "lastName") !== -1) && (
          <Field
            name="lastName"
            component={AppFormTextField}
            label={t("fieldLabel.lastname", "LastName")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "language") !== -1) && (
          <Field
            name="language"
            component={AppFormPicker}
            options={languagesOptions()}
            label={t("fieldLabel.language", "Language")}
            // help={t("fieldHelp.country")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "email") !== -1) && (
          <Field
            name="email"
            component={AppFormTextField}
            label={t("fieldLabel.email", "Email")}
            // help={t("fieldHelp.country")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "phoneNumber") !== -1) && (
          <Field
            name="phoneNumber"
            component={AppFormPhoneNumberField}
            label={t("fieldLabel.phoneNumber", "Phone Number")}
            // help={t("fieldHelp.country")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "password") !== -1) && (
          <Field
            name="password"
            component={AppFormTextField}
            label={t("fieldLabel.password", "Password")}
            help={t("fieldHelp.password", "Enter your password")}
            type="password"
          />
        )}
      </AppCard>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}
      <AppButton primary valid type="submit" loading={loading}>
        {submitButtonString}
      </AppButton>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: "accountForm",
})(ReduxForm);
