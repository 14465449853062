import { ApolloError } from "@apollo/client";
import React from "react";
import theme from "../../styles/Theme.module.scss";
import { AppButton } from "./AppButton";
import { AppCard } from "./AppCard";
import { AppCol } from "./AppCol";
import { AppRow } from "./AppRow";
import { AppText } from "./AppText";
import { AppTitle } from "./AppTitle";
export interface IAppErrorProps {
  title: string;
  message?: string;
  error?: ApolloError;
  onClose?: () => void;
}

export const AppError: React.FC<IAppErrorProps> = ({
  title,
  message,
  error,
  onClose,
}) => {
  try {
    const e = JSON.parse(JSON.stringify(error));
    console.log(e);
    return (
      <AppCard danger style={{ margin: 0 }}>
        <AppRow className={theme.justifyContentSpaceBetween}>
          <AppCol cols="col-12 col-md-10">
            <AppTitle bold small light>
              {title}
            </AppTitle>
          </AppCol>
          {onClose && (
            <AppButton right link light onClick={() => onClose()}>
              FERMER
            </AppButton>
          )}
        </AppRow>
        <AppText
          light
          className={theme.marginVertical10}
          style={{ textAlign: "left" }}
        >
          {message ?? e?.message}
        </AppText>
      </AppCard>
    );
  } catch (e) {
    return (
      <AppCard danger style={{ margin: 0 }}>
        <AppRow className={theme.justifyContentSpaceBetween}>
          <AppCol cols="col-12 col-md-10">
            <AppTitle bold small light>
              {title}
            </AppTitle>
          </AppCol>
          {onClose && (
            <AppButton right link light onClick={() => onClose()}>
              FERMER
            </AppButton>
          )}
        </AppRow>
        <AppText
          light
          className={theme.marginVertical10}
          style={{ textAlign: "left" }}
        >
          {JSON.stringify(e)}
        </AppText>
      </AppCard>
    );
  }
};
