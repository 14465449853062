import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, WrappedFieldArrayProps } from "redux-form";
import { VariantTypes } from "../../../../graphql/generated/types";
import { Colors } from "../../../../styles/Colors";
import theme from "../../../../styles/Theme.module.scss";
import { AppButton, AppCol, AppRow } from "../../../components";
import { AppFormPicker } from "../../../components/ReduxFormComponents/AppFormPicker";
import { AppFormTextField } from "../../../components/ReduxFormComponents/AppFormTextField";

export interface IFieldArrayProps {}

export const FieldArrayTypes: FunctionComponent<WrappedFieldArrayProps> = ({
  fields,
  meta: { error },
}) => {
  const { t } = useTranslation();

  const variantTypesOptions: any[] = [];
  for (const [propertyKey, propertyValue] of Object.entries(VariantTypes)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    variantTypesOptions.push({ value: propertyValue, label: propertyKey });
  }

  console.log("variantTypesOptions", variantTypesOptions);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((type, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              <AppRow
                style={{
                  gap: 20,
                }}
              >
                <AppCol
                  style={{
                    width: "50%",
                  }}
                >
                  <Field
                    name={`${type}.type`}
                    component={AppFormPicker}
                    options={variantTypesOptions}
                    label={
                      index === 0 && t("fieldLabel.variantType", "Variant Type")
                    }
                    //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                    type="text"
                    style={{
                      marginBottom: index === fields.length - 1 ? 15 : 5,
                    }}
                  />
                </AppCol>
                <AppCol
                  style={{
                    width: "100%",
                  }}
                >
                  <Field
                    name={`${type}.value`}
                    component={AppFormTextField}
                    label={
                      index === 0 &&
                      t("fieldLabel.variantValue", "Variant Value")
                    }
                    //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                    style={{
                      marginBottom: index === fields.length - 1 ? 15 : 5,
                    }}
                  />
                </AppCol>
              </AppRow>
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addVariantType", "ADD A VARIANT TYPE DEFINITION")}
      </AppButton>
    </div>
  );
};
