// COMPONENT MODIFIED ON 11/09/2020 BY GASPARD
import Dialog from "@material-ui/core/Dialog";
import { ModalProps } from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { detectMob } from "../../helpers/DetectMobile";
import { Colors } from "../../styles/Colors";
import { AppCard } from "./AppCard";
export interface IAppDialog extends ModalProps {
  open: boolean;
  width: string | number;
  scroll?: "body" | "paper";
  onClose?: () => void;
}

export const AppDialog: React.FC<IAppDialog> = ({
  open,
  width,
  scroll = "paper",
  onClose,
  children,
}) => {
  const StyledDialog = withStyles({
    root: {
      display: "flex",
      backgroundColor: Colors.translucentDark,
      justifyContent: "center",
    },
    paperScrollBody: {
      width: detectMob(navigator.userAgent) ? "90vw" : width,
      padding: "50px 20px",
      backgroundColor: Colors.transparent,
      boxShadow: "none",
    },
  })(Dialog);

  return (
    <StyledDialog open={open} onClose={onClose} scroll={scroll} fullScreen>
      <AppCard style={{ backgroundColor: Colors.fakeBlack, margin: 0 }}>
        {children}
      </AppCard>
    </StyledDialog>
  );
};
