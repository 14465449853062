import { ConnectedRouter, push } from "connected-react-router";
import { get } from "lodash";
import { FunctionComponent, Suspense, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import logo from "./images/LogoTypo.svg";
import { Categories } from "./modules/categories/Categories";
import { AppHeader } from "./modules/components";
import { Contents } from "./modules/content/Contents";
import { Customers } from "./modules/customers/Customers";
import { Dashboard } from "./modules/dashboard/Dashboard";
import { Messengers } from "./modules/messengers/Messengers";
import { Orders } from "./modules/orders/Orders";
import { Products } from "./modules/products/Products";
import { Segments } from "./modules/segments/Segments";
import { Sellers } from "./modules/sellers/Sellers";
import { Welcome } from "./modules/welcome/Welcome";
import { RootState, actions } from "./redux";
export interface IAppProps {
  history: History;
}

const App: FunctionComponent<IAppProps> = ({ history }) => {
  //
  const dispatch = useDispatch();
  const pushUrl = useCallback(
    (location: string) => {
      dispatch(push(location));
    },
    [dispatch]
  );

  const user = useSelector((state: RootState) => state.data.auth?.user);

  console.log("USER", user);

  /**********************************************************
   *** AUTH CHECK ***
   **********************************************************/

  const authToken = useSelector((state: RootState) =>
    get(state, "data.auth.accessToken", undefined)
  );
  const saveAuthToken = useCallback(
    (token: string) => {
      dispatch(actions.setState(token, `auth.accessToken`));
    },
    [dispatch]
  );

  const storedToken = localStorage.getItem("token");

  /**********************************************************
   *** NAVIGATION ***
   **********************************************************/

  const deleteAuth = useCallback(() => {
    dispatch(actions.setState({}, `auth`));
  }, [dispatch]);

  useEffect(() => {
    if (storedToken && storedToken !== authToken) saveAuthToken(storedToken);
    if (!storedToken) pushUrl("/");

    if (user && user.role !== "ADMIN") {
      localStorage.removeItem("token");
      deleteAuth();
    }
  }, [authToken, deleteAuth, pushUrl, saveAuthToken, storedToken, user]);

  /**********************************************************
   *** INITIAL STATE ***
   **********************************************************/

  const saveInitialStore = useCallback(
    (data: any, path: string) => {
      dispatch(actions.setState(data, path));
    },
    [dispatch]
  );

  useEffect(() => {
    saveInitialStore(
      {
        state: {
          center: {
            lat: 43.6584045,
            lng: -1.4262382,
          },
          zoom: 14,
        },
      },
      "map"
    );
  });

  return (
    <>
      <ConnectedRouter history={history as any}>
        {authToken && (
          <AppHeader
            logo={logo}
            menuItems={[
              { name: "DASHBOARD", path: "/dashboard" },
              { name: "CONTENT", path: "/content" },
              { name: "ORDERS", path: "/orders" },
              { name: "CUSTOMERS", path: "/customers" },
              { name: "MESSENGERS", path: "/messengers" },
              { name: "SELLERS", path: "/sellers" },
              { name: "SEGMENTS", path: "/segments" },
              { name: "CATEGORIES", path: "/categories" },
              { name: "PRODUCTS", path: "/products" },
            ]}
            logout={() => {
              localStorage.removeItem("token");
              deleteAuth();
              pushUrl("/");
            }}
          />
        )}

        <div
          style={{
            position: "absolute",
            zIndex: 0,
            width: "100vw",
          }}
        >
          <Suspense fallback={<div>Version 1.0.0 (1)</div>}>
            <Switch>
              <Route path="/" exact component={Welcome} />
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/content" exact component={Contents} />
              <Route path="/orders" exact component={Orders} />
              <Route path="/customers" exact component={Customers} />
              <Route path="/messengers" exact component={Messengers} />
              <Route path="/sellers" exact component={Sellers} />
              <Route path="/segments" exact component={Segments} />
              <Route path="/categories" exact component={Categories} />
              <Route path="/products" exact component={Products} />
            </Switch>
          </Suspense>
        </div>
      </ConnectedRouter>
    </>
  );
};

export default App;
