export const Colors = {
  primary: "rgba(0,0,0,1)",
  accent: "rgba(0,200,0,1)",
  background: "rgba(0,200,0,1)",
  paper: "rgba(0,200,0,1)",
  error: "rgba(200,0,0,1)",
  text: "rgba(0,0,0,1)",
  disabled: "rgba(150, 150, 150, 0.7)",
  placeholder: "rgba(0,200,0,1)",

  dark: "rgba(0,0,0,1)",
  darkGrey: "rgba(80,80,80,1)",
  light: "rgba(255,255,255,1)",
  translucentDark: "rgba(0,0,0,0.8)",
  translucentLight: "rgba(255,255,255,0.95)",

  transparentDark: "rgba(20, 20, 20, 0.4)",
  transparentLight: "rgba(235, 235, 235, 0.6)",

  danger: "rgba(200,0,0,1)",
  warning: "rgba(250,190,90,1)",
  valid: "rgba(0,150,0,1)",

  transparent: "rgba(0,0,0,0)",
  fakeBlack: "rgba(40,40,40,1)",

  dev: "rgba(200,0,0,0.2)",
};

export const PaperColors = {
  primary: Colors.primary,
  background: Colors.light,
  surface: Colors.light,
  accent: Colors.valid,
  error: Colors.danger,
  text: Colors.dark,
  onSurface: Colors.dark,
  disabled: Colors.disabled,
  placeholder: Colors.dark,
  backdrop: Colors.translucentDark,
  notification: Colors.warning,
};
