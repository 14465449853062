import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSegmentQuery } from "../../../graphql/generated/types";
import {
  AppCard,
  AppDialog,
  AppError,
  AppLabel,
  AppRow,
  AppTitle,
} from "../../components";
import { UploadSegmentImage } from "./UploadSegmentImage";

export interface IUploadImagesProps {
  segmentId: string;
}

export const UploadImages: FunctionComponent<IUploadImagesProps> = ({
  segmentId,
}) => {
  const { t } = useTranslation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [error, setError] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: "",
    message: "",
  });

  /**********************************************************
   *** MUTATION ***
   **********************************************************/

  const { data, refetch } = useSegmentQuery({
    variables: { segmentId },
  });

  if (!data) return null;
  const segment = data.segment;

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <>
      <AppRow
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: 20,
        }}
      >
        <AppTitle
          small
          extrabold
          light
          subtitle={t("update.subtitle", "Please upload your images")}
        >
          {t("upload.title", "Upload Images")}
        </AppTitle>
      </AppRow>

      <AppCard style={{ margin: 0 }}>
        <AppLabel bold style={{ marginBottom: 10 }}>
          {t("label.labelImage", "Label Image")}
        </AppLabel>

        <UploadSegmentImage
          segment={segment}
          refetch={refetch}
          imageDescription={"labelImage"}
        />

        <AppLabel bold style={{ marginBottom: 10 }}>
          {t("label.mainImage", "Main Image")}
        </AppLabel>

        <UploadSegmentImage
          segment={segment}
          refetch={refetch}
          imageDescription={"mainImage"}
        />

        <AppDialog
          open={error.show}
          onClose={() => {
            setError({
              show: false,
              title: "",
              message: "",
            });
          }}
          scroll="body"
          width={600}
        >
          <AppError
            title={error.title}
            message={error.message}
            onClose={() => {
              setError({
                show: false,
                title: "",
                message: "",
              });
            }}
          />
        </AppDialog>
      </AppCard>
    </>
  );
};
