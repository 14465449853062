import { createActions } from 'redux-actions';

export const options = {
    prefix: '@@APP/DATA'
};

export const actions = createActions(
    {
        setState: [(data: any, meta: string) => data, (data: any, meta: string) => meta],
        deleteState: [(meta: string) => undefined, (meta: string) => meta],
        add: [(data: any, meta: string) => data, (data: any, meta: string) => meta],
        toggle: [(data: any, meta: string) => data, (data: any, meta: string) => meta],
        remove: [(data: any, meta: string) => data, (data: any, meta: string) => meta],
        removeInArray: [(meta: string) => meta, (meta: string) => meta]
    },
    options
);
