import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import ImageUploading from "react-images-uploading";
import { Colors } from "../../../styles/Colors";
import { Fonts } from "../../../styles/Fonts";
import { AppRow } from "../AppRow";
import { AppText } from "../AppText";

export interface IAppImageUploadingProps {
  saveImage: (images: any[]) => void;
  maxFileSize: number;
  maxImagesNumber: number;
}

export const AppImageUploading: FunctionComponent<IAppImageUploadingProps> = ({
  saveImage,
  maxFileSize,
  maxImagesNumber,
}) => {
  const [images, setImages] = React.useState<any[]>([]);
  const maxNumber = 69;

  const onChange = (imageList: any[], addUpdateIndex: any) => {
    setImages(imageList);
    saveImage(imageList);
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <>
          <div className="upload__image-wrapper">
            {images.length < maxImagesNumber && (
              <button
                style={{
                  color: isDragging ? Colors.light : Colors.transparentDark,
                  fontFamily: Fonts.medium,
                  fontWeight: 800,
                  backgroundColor: isDragging
                    ? Colors.danger
                    : Colors.transparentDark,
                  borderRadius: 6,
                  border: "none",
                  outline: "none",
                  width: "100%",
                  height: 100,
                }}
                onClick={onImageUpload}
                {...dragProps}
              >
                Click or Drop here
              </button>
            )}

            {imageList.length > 0 && (
              <div
                style={{
                  width: "100%",
                  height: 100,

                  borderRadius: 6,
                }}
              >
                {imageList.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      borderRadius: 6,
                      backgroundColor:
                        image.file?.size && image.file?.size > maxFileSize
                          ? Colors.danger
                          : Colors.valid,
                    }}
                  >
                    {image.file?.size && image.file?.size > maxFileSize && (
                      <div
                        style={{
                          position: "absolute",
                          zIndex: 100,
                          margin: "auto",
                          display: "flex",
                          width: "65%",
                          height: 100,
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <AppText
                          light
                          bold
                          style={{ backgroundColor: Colors.danger }}
                        >{`Size exceeds limitation of ${
                          maxFileSize / 1000000
                        }Mo`}</AppText>
                      </div>
                    )}
                    <AppRow key={index}>
                      <img src={image.data_url} alt="" height="100" />

                      <button
                        onClick={() => onImageRemove(index)}
                        style={{
                          position: "absolute",
                          border: "none",
                          outline: "none",
                          backgroundColor: Colors.transparent,
                          right: 10,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{ fontSize: 24, color: Colors.light }}
                        />
                      </button>
                    </AppRow>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </ImageUploading>
  );
};
