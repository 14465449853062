import { faPencil, faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { capitalize, find, get } from "lodash";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { User, useMessengersQuery } from "../../graphql/generated/types";
import { Colors } from "../../styles/Colors";
import {
  AppButton,
  AppCard,
  AppDialog,
  AppError,
  AppLoader,
  AppRow,
  AppTitle,
} from "../components";
import { AppText } from "../components/AppText";
import styles from "./Messengers.module.scss";
import { Create } from "./mutations/Create";
import { Delete } from "./mutations/Delete";
import { Update } from "./mutations/Update";

export interface Checked {
  id: string;
  check: boolean;
}

export interface IFuncCompProps {}

export const Messengers: FunctionComponent<IFuncCompProps> = () => {
  const { t } = useTranslation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowPerPage] = useState<number>(50);

  const [createRow, setCreateRow] = useState<boolean>(false);
  const [deleteRow, setDeleteRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);
  const [updateRow, setUpdateRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const { data, loading, error, refetch } = useMessengersQuery({
    // variables: {
    //   skip: page * rowsPerPage,
    //   limit: rowsPerPage,
    //   sortedBy: ["createdAt:DESC"],
    // },
    onError: (error: any) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  if (error) {
    return <AppError title={"GraphQL Error"} error={error} />;
  }
  if (loading) {
    return (
      <AppLoader light size={25} style={{ color: Colors.light }} thickness={3}>
        Loading Messengers...
      </AppLoader>
    );
  }

  const messengers = get(data, "messengers.results", []);

  return (
    <div className={styles.App}>
      <AppRow style={{ justifyContent: "space-between", padding: "0 20px" }}>
        <AppTitle bold>{`Messengers`}</AppTitle>
        <AppButton
          pill
          onClick={() => {
            setCreateRow(true);
          }}
        >
          {t("button.create", "CREATE")}
        </AppButton>
      </AppRow>
      <AppCard style={stylesheet.appCard as any}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                CONNEXION
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                USER
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                CONTACTS
              </TableCell>
              <TableCell style={stylesheet.columnHeadText}>
                IDENTIFICATION
              </TableCell>
              <TableCell style={stylesheet.columnHeadText}>
                LAST POSITION
              </TableCell>
              <TableCell style={stylesheet.columnHeadText} />
            </TableRow>
          </TableHead>
          <TableBody>
            {messengers.map((messenger: User, key: number) => {
              return [
                <StyledTableRow
                  key={`${key}-master`}
                  hover
                  tabIndex={-1}
                  onDoubleClick={() => {}}
                >
                  <TableCell
                    scope="row"
                    style={{ ...stylesheet.rowText, width: 30 }}
                  >
                    <AppRow>
                      {moment(messenger.createdAt).isAfter(
                        moment().subtract(5, "days")
                      ) && (
                        <FontAwesomeIcon
                          data-tip={"This messenger just got pop!"}
                          icon={faSparkles}
                          style={{ fontSize: 16, marginRight: 10 }}
                          color={Colors.valid}
                        />
                      )}
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      bold
                      valid={messenger.activated}
                      danger={!messenger.activated}
                    >{`${
                      messenger.activated ? "CONNECTED" : "DISCONNECTED"
                    } `}</AppText>
                    {/* <AppText
                      small
                      
                    >
                      {messenger.type}
                    </AppText> */}
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppText small>{`${capitalize(
                      messenger?.firstName
                    )} ${capitalize(messenger?.lastName)}`}</AppText>
                  </TableCell>
                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText bold>{`${messenger?.phoneNumber}`}</AppText>
                    <AppText
                      small
                    >{`${messenger?.language.toUpperCase()}`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppText bold>{`${messenger.id}`}</AppText>
                    <AppText small>
                      {`created at: ${moment(messenger.createdAt).format(
                        "MMM Do YYYY, HH:mm"
                      )}`}
                    </AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppText
                      bold
                    >{`Lng: ${messenger?.position?.coordinates[0][0][0]} / Lat: ${messenger?.position?.coordinates[0][0][1]}`}</AppText>
                    <AppText small>
                      {`updated at: ${moment(messenger.updatedAt).format(
                        "MMM Do YYYY, HH:mm"
                      )}`}
                    </AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ paddingRight: 20 }}>
                    <AppRow style={{ justifyContent: "flex-end" }}>
                      <AppButton
                        style={{ margin: "0px 10px" }}
                        link
                        // disabled={!getCheck(order._id) || addTimeToOrderLoading}
                        onClick={() =>
                          setUpdateRow({ id: messenger.id, show: true })
                        }
                      >
                        <FontAwesomeIcon
                          data-tip={"Update row data"}
                          data-for="updateToolTip"
                          icon={faPencil}
                          color={
                            /* !getCheck(order._id) || addTimeToOrderLoading ? Colors.disabled :  */ Colors.dark
                          }
                          style={{ outline: "none" }}
                        />
                      </AppButton>
                      <ReactTooltip id="updateToolTip" place="left" multiline />
                      {/*<AppTimerButton
                        data-tip={
                          "Delete row data<br/>Press this button for 5s"
                        }
                        data-for="deleteToolTip"
                        style={{ padding: "0 10px 0 0" }}
                        endOfTimeFunction={() => {
                          setDeleteRow({ id: messenger.id, show: true });
                        }}
                      />
                      <ReactTooltip id="deleteToolTip" place="left" multiline />
                       <AppButton
                        link
                        onClick={() => {
                          handleCheckeds(messenger);
                        }}
                        textStyle={{ fontSize: 14 }}
                      >
                        <FontAwesomeIcon
                          icon={getCheck(messenger.id) ? faCheckSquare : faSquare}
                          style={{ fontSize: 16 }}
                          color={
                            getCheck(messenger.id)
                              ? Colors.valid
                              : Colors.transparentDark
                          }
                        />
                      </AppButton> */}
                    </AppRow>
                  </TableCell>
                </StyledTableRow>,
              ];
            })}
          </TableBody>
        </Table>

        <AppRow
          style={{
            justifyContent: "space-between",
          }}
        >
          {/*             <MessengersFiltersForm width={250} />
           */}
          <Table style={{ width: "100%" }}>
            <TableFooter>
              <TableRow>
                <StyledTablePagination
                  labelRowsPerPage={`ROWS PER PAGE`}
                  labelDisplayedRows={(paginationInfo: any) => {
                    return `PAGE ${paginationInfo.page + 1} - FROM ${
                      paginationInfo.from
                    } TO ${paginationInfo.to} - TOTAL ${paginationInfo.count}`;
                  }}
                  count={data?.messengers.total ?? 0}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 20, 50, 100, 250, 500]}
                  page={page}
                  onPageChange={(e, page: number) => {
                    setPage(page);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    setRowPerPage(parseInt(event.target.value, 10));
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </AppRow>
      </AppCard>

      <AppDialog
        open={createRow}
        onClose={() => setCreateRow(false)}
        scroll="body"
        width={500}
      >
        <Create
          onClose={() => {
            setCreateRow(false);
            refetch();
          }}
          initialValues={{ country: "FR", language: "fr" }}
          fields={[
            "firstName",
            "lastName",
            "language",
            "email",
            "phoneNumber",
            "password",
          ]}
        />
      </AppDialog>

      <AppDialog
        open={deleteRow ? deleteRow.show : false}
        onClose={() => setDeleteRow(undefined)}
        scroll="body"
        width={600}
      >
        <Delete id={deleteRow?.id} onClose={() => setDeleteRow(undefined)} />
      </AppDialog>

      <AppDialog
        open={updateRow ? updateRow.show : false}
        onClose={() => setUpdateRow(undefined)}
        scroll="body"
        width={500}
      >
        <Update
          id={updateRow?.id}
          initialValues={find(messengers, { id: updateRow?.id })}
          onClose={() => setUpdateRow(undefined)}
        />
      </AppDialog>

      <ReactTooltip place="right" multiline />
    </div>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTablePagination = withStyles({
  root: { bmessengerBottom: 0, color: Colors.transparentDark, fontSize: 12 },
  caption: { color: Colors.transparentDark, fontSize: 10 },
  selectIcon: { color: Colors.transparentDark, fontSize: 22 },
  // MuiButtonBase: { root: { outline: 'none' } }
  // overrides: {
  //     MuiButtonBase: { root: { outline: 'none' } }
  // }
})(TablePagination);

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
