import { Seller } from "../graphql/generated/types";

export const displayDetailsCompleted = (seller: Seller) => {
  if (
    seller.name &&
    seller.headline &&
    seller.deliveryPromess &&
    seller.description &&
    seller.subCategories.length > 0 &&
    seller.languagesSpoken &&
    seller.logo &&
    seller.mainImage
  )
    return true;
  return false;
};

export const deliveryDetailsCompleted = (seller: Seller) => {
  if (
    seller.pickupAddress &&
    seller.pickupZipCode &&
    seller.pickupCity &&
    seller.pickupPosition &&
    seller.availabilities &&
    seller.availabilities.length > 0 &&
    seller.maxPreparationDuration
  )
    return true;
  return false;
};
export const dealDetailsCompleted = (seller: Seller) => {
  if (
    seller.commissionType &&
    seller.commissionValue &&
    seller.currency &&
    seller.servicePercentage &&
    seller.minimumOrderValue
  )
    return true;
  return false;
};
export const adminDetailsCompleted = (seller: Seller) => {
  if (
    seller.name &&
    seller.companyName &&
    seller.workNumber &&
    seller.contactName &&
    seller.email &&
    seller.phoneNumber &&
    seller.language &&
    seller.billingAddress &&
    seller.country &&
    seller.vatIdentifier
  )
    return true;
  return false;
};

export const apiDetailsCompleted = (seller: Seller) => {
  if (seller.apiService && seller.accessToken && seller.locationId) return true;
  return false;
};
