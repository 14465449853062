import React from 'react';

export interface IAppColProps {
    style?: React.CSSProperties;
    className?: string;
    cols?: string;
}

export const AppCol: React.FC<IAppColProps> = ({ style, className = '', cols = 'col-12 col-md', children }) => {
    return (
        <div className={`${cols} ${className}`} style={{ ...style }}>
            {children}
        </div>
    );
};
