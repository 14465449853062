import React from "react";
import theme from "../../styles/Theme.module.scss";
export interface IAppCardProps {
  dark?: boolean;
  valid?: boolean;
  danger?: boolean;
  warning?: boolean;
  transparent?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
  border?: number;
}

export const AppCard: React.FC<IAppCardProps> = ({
  dark,
  valid,
  danger,
  style,
  onClick,
  children,
  className,
  warning,
  border,
  transparent,
}) => {
  const bgColorClassName = transparent
    ? `${theme.bgColorTransparent}`
    : valid
    ? `${theme.bgColorValid}`
    : danger
    ? `${theme.bgColorDanger}`
    : warning
    ? `${theme.bgColorWarning}`
    : dark
    ? `${theme.bgColorDark}`
    : `${theme.bgColorLight}`;

  const borderColorClassName = valid
    ? `${theme.borderColorValid}`
    : danger
    ? `${theme.borderColorDanger}`
    : warning
    ? `${theme.borderColorWarning}`
    : dark
    ? `${theme.borderColorDark}`
    : `${theme.borderColorLight}`;

  return (
    <div
      className={`${className as string} ${bgColorClassName} ${
        border ? theme.borderedCard : theme.card
      } ${border ?? borderColorClassName} ${
        onClick ? theme.cursorPointer : ""
      }`}
      style={{ borderWidth: border, ...style }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
