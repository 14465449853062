import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/Logo_SoixanteSeize_fullWhite.svg";
import { Colors } from "../../styles/Colors";
import { AppButton } from "./AppButton";
import { AppRow } from "./AppRow";

export const AppBlockFooter = () => {
  return (
    <>
      <AppRow style={{ marginTop: 20 }}>
        <AppButton
          link
          light
          style={{ marginTop: -5, fontSize: 11, textAlign: "center" }}
          onClick={() => {
            window.open("http://www.soixanteseize.com");
          }}
        >
          <img
            src={logo}
            className="img"
            alt="Responsive"
            width="125"
            height="auto"
            style={{ textAlign: "center" }}
          />
        </AppButton>
      </AppRow>

      <AppRow>
        <Link
          style={{
            fontSize: 8,
            color: Colors.transparentLight,
            marginRight: 10,
          }}
          to="https://www.couriier.com/terms-and-conditions/"
          target="_blank"
        >
          {"TERMS AND CONDITIONS"}
        </Link>
        <Link
          style={{
            fontSize: 8,
            color: Colors.transparentLight,
            marginRight: 10,
          }}
          to="https://www.couriier.com/privacy-policy/"
          target="_blank"
        >
          {"PRIVACY POLICY"}
        </Link>
        <Link
          style={{
            fontSize: 8,
            color: Colors.transparentLight,
            marginRight: 10,
          }}
          to="https://www.couriier.com/legals/"
          target="_blank"
        >
          {"LEGALS"}
        </Link>
        <Link
          style={{ fontSize: 8, color: Colors.transparentLight }}
          to="https://www.couriier.com/assistance/?lang=fr"
          target="_blank"
        >
          {"ASSISTANCE"}
        </Link>
      </AppRow>
    </>
  );
};
