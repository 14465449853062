import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

// export const apiHost = "https://app.goboxbox.com";
export const apiHost =
  process.env.REACT_APP_API_HOST ?? "http://localhost:8000"; //"https://app.goboxbox.com"; //
console.log(process.env, apiHost);
// const httpLink = createHttpLink({
//   uri: apiHost + "/graphql",
// });

export const uploadLink = createUploadLink({
  uri: apiHost + "/graphql",
  // fetch: customFetch, just for Upload Progress
});

/**********************************************************
 *** AUTH CONTEXT ***
 **********************************************************/
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  credentials: "same-origin",
  link: ApolloLink.from([authLink, uploadLink as any]), //authLink.concat(uploadLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
