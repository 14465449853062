import { get, values } from 'lodash';

export const apiErrors = (error: any) => {
    const errors = get(error, 'errors', []);
    const formErrors: any = {};

    for (const detail of errors) {
        formErrors[detail.property] = values(detail.constraints).join(',');
    }

    return {
        _error: error.message,
        ...formErrors
    };
};
