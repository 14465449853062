import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import theme from "../../styles/Theme.module.scss";
import { AppLabel } from "./AppLabel";
import { AppRow } from "./AppRow";
export interface IAppLoaderProps {
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  light?: boolean;
  danger?: boolean;
  valid?: boolean;
  row?: boolean;
  size: number;
  thickness?: number;
  className?: string;
  classNameTextStyle?: string;
}

export const AppLoader: React.FC<IAppLoaderProps> = ({
  style,
  row,
  textStyle,
  light,
  danger,
  valid,
  size,
  thickness,
  children,
  className,
  classNameTextStyle,
}) => {
  const colorClassName = light
    ? `${theme.colorLight}`
    : danger
    ? `${theme.colorDanger}`
    : valid
    ? `${theme.colorValid}`
    : `${theme.colorDark}`;

  return (
    <AppRow
      className={`${className} ${colorClassName} ${theme.alignItemsCenter}`}
      style={{ ...style, flexDirection: row ? "row" : "column" }}
    >
      <CircularProgress
        size={size}
        color={"inherit"}
        thickness={thickness ?? 3}
        style={{ marginBottom: row ? 0 : 5, marginRight: row ? 5 : 0 }}
      />
      {children && (
        <AppLabel
          bold
          className={`styles.text ${classNameTextStyle}`}
          light={light}
          danger={danger}
          valid={valid}
          style={{ ...textStyle }}
        >
          {children}
        </AppLabel>
      )}
    </AppRow>
  );
};
