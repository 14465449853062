import { get } from "lodash";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SubmissionError } from "redux-form";
import {
  CreateProductInput,
  CreateProductPackInput,
  CreateProductPackSubscriptionInput,
  ProductTypes,
  useCreateProductMutation,
  useCreateProductPackMutation,
  useCreateProductPackSubscriptionMutation,
} from "../../../graphql/generated/types";
import { apiErrors } from "../../../helpers/Errors";
import { AppRow, AppTitle } from "../../components";
import CreateForm from "./ItemForm";

export interface ICreateItemProps {
  initialValues?: any;
  fields?: string[];
  refetch?: () => void;
  onClose: () => void;
}

export const CreateItem: FunctionComponent<ICreateItemProps> = ({
  initialValues,
  onClose,
  refetch,
  fields,
}) => {
  const { t } = useTranslation();

  const [createItem, { loading: loadingItem }] = useCreateProductMutation({
    onCompleted: () => {
      if (refetch) refetch();
      onClose();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  const [createPack, { loading: loadingPack }] = useCreateProductPackMutation({
    onCompleted: () => {
      if (refetch) refetch();
      onClose();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  const [createSubs, { loading: loadingSubs }] =
    useCreateProductPackSubscriptionMutation({
      onCompleted: () => {
        if (refetch) refetch();
        onClose();
      },
      onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
    });

  return (
    <>
      <AppRow
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <AppTitle
          small
          extrabold
          light
          subtitle={t("create.subtitle", "Please enter the needed fields")}
        >
          {t("create.title", "Create")}
        </AppTitle>
      </AppRow>

      <CreateForm
        initialValues={initialValues}
        submitButtonString={t("button.create", "CREATE")}
        loading={loadingItem || loadingPack || loadingSubs}
        fields={fields}
        // validate={(values: any) =>
        //   validate(values, validators(t), { fullMessages: false })
        // }
        onSubmit={async (
          values:
            | CreateProductInput
            | CreateProductPackInput
            | CreateProductPackSubscriptionInput
        ) => {
          try {
            if (
              values.type === ProductTypes.Item ||
              values.type === ProductTypes.Upsell
            ) {
              const variables = values as CreateProductInput;
              await createItem({
                variables: { createProductInput: variables },
              });
            }
            if (values.type === ProductTypes.Pack) {
              const variables = values as CreateProductPackInput;
              await createPack({
                variables: { createProductPackInput: variables },
              });
            }
            if (values.type === ProductTypes.Subscription) {
              const variables = values as CreateProductPackSubscriptionInput;

              await createSubs({
                variables: { createProductPackSubscriptionInput: variables },
              });
            }
          } catch (e: any) {
            throw new SubmissionError(
              apiErrors({
                errors: get(
                  e,
                  "graphQLErrors[0].extensions.exception.errors",
                  []
                ),
                message: e.message,
              })
            );
          }
        }}
      />
    </>
  );
};
