import { get } from "lodash";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SubmissionError } from "redux-form";
import { useDeleteCategoryMutation } from "../../../graphql/generated/types";
import { apiErrors } from "../../../helpers/Errors";
import { AppButton, AppCard, AppCol, AppRow, AppTitle } from "../../components";

export interface IDeleteProps {
  id: any;
  onClose: () => void;
}

export const Delete: FunctionComponent<IDeleteProps> = ({ id, onClose }) => {
  const { t } = useTranslation();

  const [deleteSegment, { loading }] = useDeleteCategoryMutation({
    onCompleted: () => {
      onClose();
    },
  });

  return (
    <AppCard style={{ margin: 0 }}>
      <AppRow
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <AppTitle
          bold
          style={{ marginBottom: 20 }}
          subtitle={t("delete.subtitle", "Confirm the deletion")}
        >
          {t("delete.title", "Are you sure?")}
        </AppTitle>
      </AppRow>

      <AppRow style={{ width: "100%" }}>
        <AppCol style={{ width: "100%", paddingRight: 10 }}>
          <AppButton onClick={() => onClose()}>{t("button.cancel")}</AppButton>
        </AppCol>
        <AppCol style={{ width: "100%" }}>
          <AppButton
            danger
            primary
            loading={loading}
            onClick={async () => {
              try {
                await deleteSegment({
                  variables: {
                    id,
                  },
                });
              } catch (e: any) {
                throw new SubmissionError(
                  apiErrors({
                    errors: get(
                      e,
                      "graphQLErrors[0].extensions.exception.errors",
                      []
                    ),
                    message: e.message,
                  })
                );
              }
            }}
          >
            {t("button.delete", "DELETE")}
          </AppButton>
        </AppCol>
      </AppRow>
    </AppCard>
  );
};
