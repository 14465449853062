import { indexOf } from "lodash";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  Field,
  FieldArray,
  Form,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import {
  ApiServices,
  CommissionTypes,
  SellerStatuses,
} from "../../../graphql/generated/types";
import { getCountries } from "../../../helpers/CountriesList";
import { currencyOptions } from "../../../helpers/Currency";
import { languagesOptions } from "../../../helpers/Languages";
import {
  AppButton,
  AppCard,
  AppCol,
  AppFormPicker,
  AppFormTextField,
  AppRow,
  AppText,
} from "../../components";
import { AppFormPhoneNumberField } from "../../components/ReduxFormComponents/AppFormPhoneNumberField";
import {
  FieldArraySellerAvailabilities,
  FieldArraySellerLanguagesSpoken,
  FieldArraySellerOptions,
  FieldArraySellerSubCategories,
} from "./FieldArrays";

export interface IUpdateBaseFormProps {
  initialValues: any;
  loading?: boolean;
  fields?: string[];
  submitButtonString: any;
}

export type IUpdateFormProps = IUpdateBaseFormProps &
  InjectedFormProps<any, IUpdateBaseFormProps>;

const ReduxForm: FunctionComponent<IUpdateFormProps> = ({
  initialValues,
  loading,
  handleSubmit,
  error,
  fields,
  submitButtonString,
}) => {
  const { t } = useTranslation();

  const sellerStatusOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(SellerStatuses)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    sellerStatusOptions.push({ value: propertyValue, label: propertyKey });
  }
  const commissionTypesOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(CommissionTypes)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    commissionTypesOptions.push({ value: propertyValue, label: propertyKey });
  }

  const apiServicesOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(ApiServices)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    apiServicesOptions.push({ value: propertyValue, label: propertyKey });
  }

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}

      <AppCard
        style={{
          margin: "20px 0",
        }}
      >
        {(!fields || indexOf(fields, "status") !== -1) && (
          <Field
            name="status"
            component={AppFormPicker}
            firstOptionAsDefaultValue={initialValues ? false : true}
            options={sellerStatusOptions}
            label={t("fieldLabel.status", "Status")}
            //   help={t("fieldHelp.resourceType", "Type of Account")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "name") !== -1) && (
          <Field
            name="name"
            component={AppFormTextField}
            label={t("fieldLabel.sellerName", "Seller Name")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "companyName") !== -1) && (
          <Field
            name="companyName"
            component={AppFormTextField}
            label={t("fieldLabel.companyName", "Company Name")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "workNumber") !== -1) && (
          <Field
            name="workNumber"
            component={AppFormTextField}
            label={t("fieldLabel.siret", "SIRET number")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "vatIdentifier") !== -1) && (
          <Field
            name="vatIdentifier"
            component={AppFormTextField}
            label={t("fieldLabel.vatIdentifier", "VAT Identifier")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "contactName") !== -1) && (
          <Field
            name="contactName"
            component={AppFormTextField}
            label={t("fieldLabel.contactName", "Contact Name")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "billingAddress") !== -1) && (
          <Field
            name="billingAddress"
            component={AppFormTextField}
            label={t("fieldLabel.billingAddress", "Address")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "email") !== -1) && (
          <Field
            name="email"
            component={AppFormTextField}
            label={t("fieldLabel.email", "Email")}
            // help={t("fieldHelp.country")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "phoneNumber") !== -1) && (
          <Field
            name="phoneNumber"
            component={AppFormPhoneNumberField}
            label={t("fieldLabel.phoneNumber", "Phone Number")}
            // help={t("fieldHelp.country")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "country") !== -1) && (
          <Field
            name="country"
            component={AppFormPicker}
            options={getCountries()}
            label={t("fieldLabel.country", "Country")}
            // help={t("fieldHelp.country")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "language") !== -1) && (
          <Field
            name="language"
            component={AppFormPicker}
            options={languagesOptions()}
            label={t("fieldLabel.language", "Language")}
            // help={t("fieldHelp.country")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {/**********************************************************
         *** DISPLAY ***
         **********************************************************/}

        {(!fields || indexOf(fields, "headline") !== -1) && (
          <Field
            name="headline"
            component={AppFormTextField}
            label={t("fieldLabel.headline", "Headline")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "deliveryPromess") !== -1) && (
          <Field
            name="deliveryPromess"
            component={AppFormTextField}
            label={t("fieldLabel.deliveryPromess", "Delivery Promess")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "description") !== -1) && (
          <Field
            name="description"
            component={AppFormTextField}
            multiline
            label={t("fieldLabel.description", "Description")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "subCategories") !== -1) && (
          <FieldArray
            name="subCategories"
            component={FieldArraySellerSubCategories as any}
            props
          />
        )}

        {(!fields || indexOf(fields, "displayAddress") !== -1) && (
          <Field
            name="displayAddress"
            component={AppFormTextField}
            multiline
            label={t("fieldLabel.displayAddress", "Address Displayed")}
            //   help={t("fieldHelp.email")}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "languagesSpoken") !== -1) && (
          <FieldArray
            name="languagesSpoken"
            component={FieldArraySellerLanguagesSpoken as any}
            props
          />
        )}

        {/**********************************************************
         *** DELIVERY ***
         **********************************************************/}

        {(!fields || indexOf(fields, "pickupAddress") !== -1) && (
          <>
            <Field
              name="pickupAddress"
              component={AppFormTextField}
              multiline
              label={t("fieldLabel.pickupAddress", "Pickup Address")}
              //   help={t("fieldHelp.email")}
              type="text"
              style={{ marginBottom: 20 }}
            />
            <AppRow
              style={{
                gap: 15,
                alignItems: "flex-start",
              }}
            >
              <AppCol style={{ width: "30%" }}>
                <Field
                  name="pickupZipCode"
                  component={AppFormTextField}
                  multiline
                  label={t("fieldLabel.pickupZipcode", "Zipcode")}
                  //   help={t("fieldHelp.email")}
                  type="number"
                  // parse={parseInt}
                  style={{ marginBottom: 20 }}
                />
              </AppCol>
              <AppCol style={{ width: "70%" }}>
                <Field
                  name="pickupCity"
                  component={AppFormTextField}
                  multiline
                  label={t("fieldLabel.pickupCity", "City")}
                  //   help={t("fieldHelp.email")}
                  type="text"
                  style={{ marginBottom: 20 }}
                />
              </AppCol>
            </AppRow>
            <AppRow style={{ gap: 15, alignItems: "flex-start" }}>
              <AppCol style={{ width: "50%" }}>
                <Field
                  name="pickupLatitude"
                  component={AppFormTextField}
                  multiline
                  label={t("fieldLabel.pickupLatitude", "Latitude")}
                  //   help={t("fieldHelp.email")}
                  type="number"
                  parse={parseFloat}
                  style={{ marginBottom: 20 }}
                />
              </AppCol>
              <AppCol style={{ width: "50%" }}>
                <Field
                  name="pickupLongitude"
                  component={AppFormTextField}
                  multiline
                  label={t("fieldLabel.pickupLongitude", "Longitude")}
                  //   help={t("fieldHelp.email")}
                  type="number"
                  parse={parseFloat}
                  style={{ marginBottom: 20 }}
                />
              </AppCol>
            </AppRow>
          </>
        )}

        {(!fields || indexOf(fields, "maxPreparationDuration") !== -1) && (
          <Field
            name="maxPreparationDuration"
            component={AppFormTextField}
            multiline
            label={t(
              "fieldLabel.maxPreparationDuration",
              "Maximum Preparation Duration (minutes)"
            )}
            //   help={t("fieldHelp.email")}
            type="number"
            parse={parseInt}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "sellerOptions") !== -1) && (
          <FieldArray
            name="sellerOptions"
            component={FieldArraySellerOptions as any}
            props
          />
        )}

        {(!fields || indexOf(fields, "availabilities") !== -1) && (
          <FieldArray
            name="availabilities"
            component={FieldArraySellerAvailabilities as any}
            props
          />
        )}

        {/**********************************************************
         *** DEAL ***
         **********************************************************/}

        {(!fields || indexOf(fields, "stripeAccountId") !== -1) && (
          <Field
            name="stripeAccountId"
            component={AppFormTextField}
            multiline
            label={t("fieldLabel.stripeAccountId", "Stripe Account Id")}
            //   help={t("fieldHelp.email")}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "commissionType") !== -1) && (
          <Field
            name="commissionType"
            component={AppFormPicker}
            options={commissionTypesOptions}
            label={t("fieldLabel.commissionType", "Commission Type")}
            //   help={t("fieldHelp.email")}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "commissionValue") !== -1) && (
          <Field
            name="commissionValue"
            component={AppFormTextField}
            label={t(
              "fieldLabel.commissionValue",
              "Commission Value (Percentage)"
            )}
            //   help={t("fieldHelp.email")}
            type="number"
            parse={parseFloat}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "commissionValue") !== -1) && (
          <Field
            name="deliveryFee"
            component={AppFormTextField}
            label={t("fieldLabel.deliveryFee", "Delivery Fee (Euro)")}
            //   help={t("fieldHelp.email")}
            type="number"
            parse={parseFloat}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "commissionValue") !== -1) && (
          <Field
            name="servicePercentage"
            component={AppFormTextField}
            label={t(
              "fieldLabel.servicePercentage",
              "Service Percentage (Percentage)"
            )}
            //   help={t("fieldHelp.email")}
            type="number"
            parse={parseFloat}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "commissionValue") !== -1) && (
          <Field
            name="minimumOrderValue"
            component={AppFormTextField}
            label={t(
              "fieldLabel.minimumOrderValue",
              "Minimum Order Value (Euro)"
            )}
            //   help={t("fieldHelp.email")}
            type="number"
            parse={parseFloat}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "currency") !== -1) && (
          <Field
            name="currency"
            component={AppFormPicker}
            options={currencyOptions}
            label={t("fieldLabel.currency", "Currency")}
            //   help={t("fieldHelp.email")}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "apiService") !== -1) && (
          <Field
            name="apiService"
            component={AppFormPicker}
            options={apiServicesOptions}
            label={t("fieldLabel.apiService", "API Service Name")}
            //   help={t("fieldHelp.email")}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "accessToken") !== -1) && (
          <Field
            name="accessToken"
            component={AppFormTextField}
            label={t("fieldLabel.accessToken", "API Key or Access Token")}
            //   help={t("fieldHelp.email")}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "locationId") !== -1) && (
          <Field
            name="locationId"
            component={AppFormTextField}
            label={t(
              "fieldLabel.locationId",
              "Shop or Restaurant identifier (ID)"
            )}
            //   help={t("fieldHelp.email")}
            style={{ marginBottom: 20 }}
          />
        )}
      </AppCard>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}
      <AppButton primary valid type="submit" loading={loading}>
        {submitButtonString}
      </AppButton>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: "sellerForm",
})(ReduxForm);
