import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createTheme, ThemeProvider } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import { TextFieldProps } from "@material-ui/core/TextField";
import React, { FunctionComponent, ReactNode } from "react";
import { WrappedFieldProps } from "redux-form";
import { Colors } from "../../../styles/Colors";
import { MaterialTheme } from "../../../styles/MaterialTheme";
import theme from "../../../styles/Theme.module.scss";
import { AppButton } from "../AppButton";
import { AppRow } from "../AppRow";

export interface IAppFormTextFieldProps {
  light?: boolean;
  small?: boolean;
  help?: string;
  style?: React.CSSProperties;
  password?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  disableUnderline?: boolean;
  className?: string;
}

export const AppFormTextField: FunctionComponent<
  TextFieldProps & WrappedFieldProps & IAppFormTextFieldProps
> = ({
  input,
  help,
  meta: { touched, error, warning },
  type,
  light,
  style,
  password,
  startAdornment,
  endAdornment,
  placeholder,
  disableUnderline,
  small,
  label,
  className,
}) => {
  const materialTheme = createTheme(MaterialTheme(light));
  return (
    <ThemeProvider theme={materialTheme}>
      <div className={className} style={{ ...style }}>
        {label && (
          <div
            style={{ marginTop: -2 }}
            className={`${theme.labelStyle}  ${theme.bold}
                            ${light ? theme.colorLight : theme.colorDark}`}
          >
            {label}
          </div>
        )}
        <AppRow style={{ justifyContent: "space-between" }}>
          <Input
            className={`${light ? theme.colorLight : theme.colorDark} ${
              small ? theme.smallInputStyle : theme.inputStyle
            }`}
            placeholder={placeholder}
            style={{ width: "100%", padding: "0 0 0 0" }}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            disableUnderline={disableUnderline}
            type={password ? "password" : type}
            {...input}
          />
          {input.value && (
            <div style={{ position: "absolute", right: 0 }}>
              <AppButton
                link
                onClick={() => {
                  input.onChange("");
                }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  color={
                    light ? Colors.transparentLight : Colors.transparentDark
                  }
                />
              </AppButton>
            </div>
          )}
        </AppRow>

        {(help ?? (touched && (error || warning))) && (
          <div className={`${theme.smallTextStyle} `} style={{ marginTop: 7 }}>
            {touched && error && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorDanger}`}
              >
                {error}
              </small>
            )}
            {touched && warning && (
              <small
                className={`${theme.smallTextStyle}  ${theme.bold} ${theme.colorWarning}`}
              >
                {warning}
              </small>
            )}
            {help && (
              <small
                className={`${theme.smallTextStyle} ${theme.bold} ${
                  light
                    ? theme.colorTransparentLight
                    : theme.colorTransparentDark
                }`}
              >
                {help}
              </small>
            )}
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};
