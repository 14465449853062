import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FunctionComponent, useState } from "react";
import { SwatchesPicker } from "react-color";
import { WrappedFieldProps } from "redux-form";
import theme from "../../../styles/Theme.module.scss";
import { AppDialog } from "../AppDialog";
import { AppRow } from "../AppRow";

export interface IAppFormColorPicker {
  size?: number;
  label?: string;
  light?: boolean;
  help?: string;
  style?: CSSProperties;
}

export const AppFormColorPicker: FunctionComponent<
  IAppFormColorPicker & WrappedFieldProps
> = ({
  input,
  size,
  label,
  light,
  help,
  style,
  meta: { touched, error, warning },
}) => {
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const handleChange = (color: any) => {
    input.onChange({
      red: color.rgb.r,
      green: color.rgb.g,
      blue: color.rgb.b,
      alpha: color.rgb.a,
    });
    setShowPicker(false);
  };

  return (
    <div style={{ ...style }}>
      <AppRow
        className={`${theme.alignItemsCenter} ${theme.justifyContentStart}`}
        style={{ justifyContent: "flex-start" }}
      >
        <FontAwesomeIcon
          icon={faCircle}
          color={`rgba(${input.value.red},${input.value.green},${input.value.blue},${input.value.alpha})`}
          style={{ fontSize: size ?? 20, cursor: "pointer", paddingRight: 10 }}
          onClick={() => {
            setShowPicker(true);
          }}
        />
        {label && (
          <div
            style={{ marginTop: -2 }}
            className={`${theme.labelStyle}  ${theme.bold}
                            ${light ? theme.colorLight : theme.colorDark}`}
          >
            {label}
          </div>
        )}
      </AppRow>

      {(help ?? (touched && (error || warning))) && (
        <span>
          {touched && error && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorDanger}`}
            >
              {error}
            </small>
          )}
          {touched && warning && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorWarning}`}
            >
              {warning}
            </small>
          )}
          {help && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${
                light ? theme.colorTransparentLight : theme.colorTransparentDark
              }`}
            >
              {help}
            </small>
          )}
        </span>
      )}

      <AppDialog
        open={showPicker}
        onClose={() => setShowPicker(false)}
        width={1000}
        scroll="body"
      >
        <AppRow>
          <SwatchesPicker
            color={"rgba(0,0,0,1"}
            onChangeComplete={handleChange}
          />
        </AppRow>
      </AppDialog>
    </div>
  );
};
