import Grid from "@material-ui/core/Grid";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import React, { CSSProperties, useEffect, useState } from "react";
import { WrappedFieldProps } from "redux-form";
import { Colors } from "../../../styles/Colors";
import theme from "../../../styles/Theme.module.scss";
import { AppText } from "../AppText";
interface option {
  label: string;
  value: any;
}
interface AppFormSwitchProps {
  options: option[];
  labelStyle?: CSSProperties;
  light?: boolean;
  style?: CSSProperties;
  label?: string;
  help?: string;
}

export const AppFormSwitch: React.FC<
  SwitchProps & AppFormSwitchProps & WrappedFieldProps
> = ({
  input,
  meta: { touched, error, warning },
  name,
  options,
  labelStyle,
  light,
  label,
  help,
  style,
}) => {
  const [value, setValue] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (!input.value) {
      setValue(options[0].value);
      input.onChange(options[0].value);
    }
    if (input.value) {
      setValue(input.value);
      setChecked(value === options[1].value);
    }
  }, [input, options, value]);

  const StyledSwitch = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 50,
        height: 24,
        padding: 0,
        display: "flex",
      },
      switchBase: {
        padding: 2,
        color: Colors.dark,

        "&$checked": {
          transform: "translateX(26px)",
          color: theme.palette.common.white,
          "& + $track": {
            opacity: 1,
            backgroundColor: Colors.dark,
            borderColor: Colors.transparent,
          },
        },
      },
      thumb: {
        width: 20,
        height: 20,
        boxShadow: "none",
      },
      track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 26 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
      },
      checked: {},
    })
  )(Switch);

  return (
    <div style={{ ...style }}>
      {label && (
        <div
          style={{ marginBottom: 5 }}
          className={`${theme.labelStyle}  ${theme.bold}
                            ${light ? theme.colorLight : theme.colorDark}`}
        >
          {label}
        </div>
      )}

      <AppText>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item style={{ ...labelStyle }}>
            <div
              className={`${theme.inputStyle}  ${
                value === options[0].value ? theme.bold : ""
              }
                            ${light ? theme.colorLight : theme.colorDark}`}
            >
              {options[0].label}
            </div>
          </Grid>
          <Grid item>
            <StyledSwitch
              checked={checked}
              onChange={() => {
                input.onChange(
                  value === options[0].value
                    ? options[1].value
                    : options[0].value
                );
              }}
              name={name}
            />
          </Grid>
          <Grid item style={{ ...labelStyle }}>
            <div
              className={`${theme.inputStyle}  ${
                value === options[1].value ? theme.bold : ""
              }
                            ${light ? theme.colorLight : theme.colorDark}`}
            >
              {options[1].label}
            </div>
          </Grid>
        </Grid>
      </AppText>

      {(help ?? (touched && (error || warning))) && (
        <span>
          {touched && error && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorDanger}`}
            >
              {error}
            </small>
          )}
          {touched && warning && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorWarning}`}
            >
              {warning}
            </small>
          )}
          {help && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${
                light ? theme.colorTransparentLight : theme.colorTransparentDark
              }`}
            >
              {help}
            </small>
          )}
        </span>
      )}
    </div>
  );
};
