import React, { useState } from "react";
import theme from "../../styles/Theme.module.scss";
import { AppButton } from "./AppButton";
import { AppRow } from "./AppRow";

export interface IAppSelectorProps {
  positions: string[];
  initialPosition?: string;
  setPositionState: (position: string) => void;
  right?: boolean;
  small?: boolean;
  center?: boolean;
  style?: React.CSSProperties;
  borderRadius?: number;
  width?: any;
  darkMode?: boolean;
}

export const AppSelector: React.FC<IAppSelectorProps> = ({
  positions,
  initialPosition,
  setPositionState,
  right,
  center,
  small,
  borderRadius,
  style,
  width,
  darkMode,
}) => {
  const [position, setPosition] = useState<string>(
    initialPosition ?? positions[0]
  );

  const handlePosition = (position: string) => {
    setPosition(position);
    setPositionState(position);
  };

  const borderStyle = (index: number): string => {
    if (index === 0) return `${theme.border}`;
    return "";
  };

  return (
    <AppRow
      className={`
                ${
                  right
                    ? theme.justifyContentEnd
                    : center
                    ? theme.justifyContentCenter
                    : theme.justifyContentStart
                }
            `}
    >
      <AppRow
        className={`${theme.border} ${
          darkMode ? theme.borderColorLight : theme.borderColorDark
        }`}
        style={{
          borderRadius: borderRadius ?? 20,
          borderWidth: 1,
          width: width ?? undefined,
          ...style,
        }}
      >
        {positions.map((positionSelector, i) => {
          return (
            <AppButton
              key={i}
              light={
                darkMode
                  ? positionSelector === position
                  : positionSelector !== position
              }
              pill
              small={small}
              className={` 
                            ${borderStyle(i)}
                            ${
                              positionSelector !== position
                                ? theme.borderColorDark
                                : theme.borderColorTransparent
                            }`}
              onClick={() => handlePosition(positionSelector)}
            >
              {positionSelector.toUpperCase()}
            </AppButton>
          );
        })}
      </AppRow>
    </AppRow>
  );
};
