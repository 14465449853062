import {
  faCheckSquare,
  faImage,
  faPencil,
  faSquare,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { find, get, join } from "lodash";
import moment from "moment";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  Segment,
  SegmentStatuses,
  useSegmentsQuery,
  useUpdateSegmentMutation,
} from "../../graphql/generated/types";
import { Colors } from "../../styles/Colors";
import {
  AppButton,
  AppCard,
  AppCheckBox,
  AppDialog,
  AppError,
  AppLoader,
  AppRow,
  AppTimerButton,
  AppTitle,
} from "../components";
import { AppText } from "../components/AppText";
import { Create } from "./mutations/Create";
import { Delete } from "./mutations/Delete";
import { Update } from "./mutations/Update";
import { UploadImages } from "./mutations/UploadImages";
import styles from "./Segments.module.scss";

export interface Checked {
  id: string;
  check: boolean;
}

export interface IFuncCompProps {}

export const Segments: FunctionComponent<IFuncCompProps> = () => {
  const { t } = useTranslation();
  //

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowPerPage] = useState<number>(50);

  const [createRow, setCreateRow] = useState<boolean>(false);

  const [deleteRow, setDeleteRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);
  const [updateRow, setUpdateRow] = useState<
    { id: string; show: boolean } | undefined
  >(undefined);

  const [updateImages, setUpdateImages] = useState<
    { segment: Segment; show: boolean } | undefined
  >(undefined);

  /**********************************************************
   *** QUERY & MUTATION ***
   **********************************************************/

  const { data, loading, error, refetch } = useSegmentsQuery({
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
    },
  });

  const [update, { error: updateError }] = useUpdateSegmentMutation({
    onCompleted: () => {
      if (refetch) refetch();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  if (error || updateError)
    return <AppError title={"GraphQL Error"} error={error ?? updateError} />;

  if (loading) {
    return (
      <AppLoader light size={25} style={{ color: Colors.light }} thickness={3}>
        Loading Segments...
      </AppLoader>
    );
  }

  const segments = get(data, "segments.results", []);

  return (
    <div className={styles.App}>
      <AppRow style={{ justifyContent: "space-between", padding: "0 20px" }}>
        <AppTitle bold>{`Segments`}</AppTitle>{" "}
        <AppButton
          pill
          onClick={() => {
            setCreateRow(true);
          }}
        >
          {t("button.create", "CREATE")}
        </AppButton>
      </AppRow>
      <AppCard style={stylesheet.appCard as any}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                STATUS
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                SORTING
              </TableCell>{" "}
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                SEGMENT
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                ACTIVATION
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                ACTIVATION PLANNING
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                CATEGORIES
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {segments.map((segment: Segment, key: number) => {
              return [
                <StyledTableRow
                  key={`${key}-master`}
                  hover
                  tabIndex={-1}
                  onDoubleClick={() => {}}
                >
                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppButton
                      link
                      onClick={() => {
                        update({
                          variables: {
                            id: segment.id,
                            status:
                              segment.status === SegmentStatuses.Deactivated
                                ? SegmentStatuses.Activated
                                : SegmentStatuses.Deactivated,
                          },
                        });
                      }}
                      textStyle={{ fontSize: 14 }}
                    >
                      <FontAwesomeIcon
                        icon={
                          segment.status === SegmentStatuses.Activated
                            ? faCheckSquare
                            : faSquare
                        }
                        style={{ fontSize: 16 }}
                        color={
                          segment.status === SegmentStatuses.Activated
                            ? Colors.valid
                            : Colors.transparentDark
                        }
                      />
                    </AppButton>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      bold
                      valid={segment.status === SegmentStatuses.Activated}
                      danger={segment.status === SegmentStatuses.Deactivated}
                      transparentDark={
                        segment.status === SegmentStatuses.Deleted
                      }
                    >{`${segment.status} `}</AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      transparentDark={
                        segment.status === SegmentStatuses.Deleted
                      }
                      small
                    >{`${segment.hasSorting ? segment.sorting : "-"}`}</AppText>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      bold
                      transparentDark={
                        segment.status === SegmentStatuses.Deleted
                      }
                    >{`${segment.name}`}</AppText>
                    <AppText
                      transparentDark={
                        segment.status === SegmentStatuses.Deleted
                      }
                      small
                    >{`${segment.description}`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppCheckBox
                      bold
                      initialValue={segment.automaticActivation}
                      comment={
                        segment.automaticActivation ? "Automatic" : "Manual"
                      }
                      onChange={(value) => {
                        if (value !== segment.automaticActivation)
                          update({
                            variables: {
                              id: segment.id,
                              automaticActivation: value,
                            },
                          });
                      }}
                      style={{ marginRight: 20 }}
                    />
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  >
                    <AppText
                      transparentDark={
                        segment.status === SegmentStatuses.Deleted
                      }
                    >{`Starts at: ${moment(
                      segment.availabilityStartDate
                    ).format("MMM Do YYYY, HH:mm")}`}</AppText>
                    <AppText
                      transparentDark={
                        segment.status === SegmentStatuses.Deleted
                      }
                    >{`Ends at: ${moment(segment.availabilityEndDate).format(
                      "MMM Do YYYY, HH:mm"
                    )}`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ ...stylesheet.rowText }}>
                    <AppText
                      bold
                      transparentDark={
                        segment.status === SegmentStatuses.Deleted
                      }
                    >{`${join(
                      segment.categories?.map((category) => category.name),
                      " | "
                    )}`}</AppText>
                  </TableCell>

                  <TableCell scope="row" style={{ paddingRight: 20 }}>
                    <AppRow style={{ justifyContent: "flex-end" }}>
                      <FontAwesomeIcon
                        data-tip={"Update row data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        onClick={() =>
                          setUpdateRow({ id: segment.id, show: true })
                        }
                        color={
                          /* !getCheck(order._id) || addTimeToOrderLoading ? Colors.disabled :  */ Colors.dark
                        }
                        style={{
                          outline: "none",
                          margin: "0 20px 0 0",
                          cursor: "pointer",
                        }}
                      />

                      <FontAwesomeIcon
                        onClick={() =>
                          setUpdateImages({
                            segment,
                            show: true,
                          })
                        }
                        data-tip={"Update data"}
                        data-for="updateToolTip"
                        icon={faImage}
                        style={{
                          outline: "none",
                          margin: "0 20px 0 0",
                          cursor: "pointer",
                          color:
                            segment.labelImage && segment.mainImage
                              ? Colors.dark
                              : Colors.danger,
                        }}
                      />

                      <ReactTooltip id="updateToolTip" place="left" multiline />
                      <AppTimerButton
                        data-tip={
                          "Delete row data<br/>Press this button for 5s"
                        }
                        data-for="deleteToolTip"
                        style={{ padding: "0 10px 0 0" }}
                        endOfTimeFunction={() => {
                          setDeleteRow({ id: segment.id, show: true });
                        }}
                      />
                      <ReactTooltip id="deleteToolTip" place="left" multiline />
                    </AppRow>
                  </TableCell>
                </StyledTableRow>,
              ];
            })}
          </TableBody>
        </Table>

        <AppRow
          style={{
            justifyContent: "space-between",
          }}
        >
          {/*             <SegmentsFiltersForm width={250} />
           */}
          <Table style={{ width: "100%" }}>
            <TableFooter>
              <TableRow>
                <StyledTablePagination
                  labelRowsPerPage={`ROWS PER PAGE`}
                  labelDisplayedRows={(paginationInfo: any) => {
                    return `PAGE ${paginationInfo.page + 1} - FROM ${
                      paginationInfo.from
                    } TO ${paginationInfo.to} - TOTAL ${paginationInfo.count}`;
                  }}
                  count={data?.segments.total ?? 0}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 20, 50, 100, 250, 500]}
                  page={page}
                  onPageChange={(e, page: number) => {
                    setPage(page);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    setRowPerPage(parseInt(event.target.value, 10));
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </AppRow>
      </AppCard>

      <AppDialog
        open={createRow}
        onClose={() => setCreateRow(false)}
        scroll="body"
        width={500}
      >
        <Create
          onClose={() => {
            setCreateRow(false);
            refetch();
          }}
          fields={[
            "name",
            "description",
            "availabilityStartDate",
            "availabilityEndDate",
          ]}
        />
      </AppDialog>

      <AppDialog
        open={deleteRow ? deleteRow.show : false}
        onClose={() => setDeleteRow(undefined)}
        scroll="body"
        width={600}
      >
        <Delete id={deleteRow?.id} onClose={() => setDeleteRow(undefined)} />
      </AppDialog>

      <AppDialog
        open={updateRow ? updateRow.show : false}
        onClose={() => setUpdateRow(undefined)}
        scroll="body"
        width={500}
      >
        <Update
          id={updateRow?.id}
          initialValues={find(segments, { id: updateRow?.id })}
          onClose={() => setUpdateRow(undefined)}
        />
      </AppDialog>
      <ReactTooltip place="right" multiline />

      <AppDialog
        open={updateImages ? updateImages.show : false}
        onClose={() => setUpdateImages(undefined)}
        scroll="body"
        width={400}
      >
        <UploadImages segmentId={updateImages?.segment.id ?? ""} />
      </AppDialog>
    </div>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTablePagination = withStyles({
  root: { bsegmentBottom: 0, color: Colors.transparentDark, fontSize: 12 },
  caption: { color: Colors.transparentDark, fontSize: 10 },
  selectIcon: { color: Colors.transparentDark, fontSize: 22 },
  // MuiButtonBase: { root: { outline: 'none' } }
  // overrides: {
  //     MuiButtonBase: { root: { outline: 'none' } }
  // }
})(TablePagination);

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
