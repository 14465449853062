import { get } from "lodash";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SubmissionError } from "redux-form";
import {
  CreatePriceInput,
  useCreatePriceMutation,
} from "../../../graphql/generated/types";
import { apiErrors } from "../../../helpers/Errors";
import { AppRow, AppTitle } from "../../components";
import CreatePriceForm from "./PriceForm";

export interface ICreatePriceProps {
  initialValues?: any;
  fields?: string[];
  refetch?: () => void;
  onClose: () => void;
}

export const CreatePrice: FunctionComponent<ICreatePriceProps> = ({
  initialValues,
  onClose,
  fields,
}) => {
  const { t } = useTranslation();

  const [create, { loading }] = useCreatePriceMutation({
    onCompleted: () => {
      onClose();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  return (
    <>
      <AppRow
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <AppTitle
          small
          extrabold
          light
          subtitle={t("create.subtitle", "Please enter the needed fields")}
        >
          {t("create.title", "CreatePrice")}
        </AppTitle>
      </AppRow>

      <CreatePriceForm
        initialValues={{
          ...initialValues,
          country: "FR",
          currency: "EUR",
          taxRate: 20,
        }}
        submitButtonString={t("button.create", "CREATE")}
        loading={loading}
        fields={fields}
        // validate={(values: any) =>
        //   validate(values, validators(t), { fullMessages: false })
        // }
        onSubmit={async (values: CreatePriceInput) => {
          console.log(values);
          try {
            await create({
              variables: {
                ...values,
                amountWithTax: values.amountWithTax * 100,
                amountWithoutTax: values.amountWithoutTax * 100,
              },
            });
          } catch (e: any) {
            throw new SubmissionError(
              apiErrors({
                errors: get(
                  e,
                  "graphQLErrors[0].extensions.exception.errors",
                  []
                ),
                message: e.message,
              })
            );
          }
        }}
      />
    </>
  );
};
