import { faImage, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { get } from "lodash";
import { FunctionComponent, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  Content,
  UpdateContentInput,
  useContentsQuery,
} from "../../graphql/generated/types";
import { deepOmit } from "../../helpers/DeepOmit";
import { Colors } from "../../styles/Colors";
import {
  AppCard,
  AppDialog,
  AppError,
  AppLoader,
  AppRow,
  AppTitle,
} from "../components";
import { AppText } from "../components/AppText";
import styles from "./Contents.module.scss";
import { Create } from "./mutations/Create";
import { Update } from "./mutations/Update";
import { UploadImages } from "./mutations/UploadImages";

export interface Checked {
  id: string;
  check: boolean;
}

export interface IFuncCompProps {}

export const Contents: FunctionComponent<IFuncCompProps> = () => {
  //

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowPerPage] = useState<number>(50);

  const [createRow, setCreateRow] = useState<boolean>(false);

  // const [deleteRow, setDeleteRow] = useState<
  //   { id: string; show: boolean } | undefined
  // >(undefined);
  const [updateRow, setUpdateRow] = useState<
    | {
        id: string;
        fields: string[];
        initialValues: UpdateContentInput;
        show: boolean;
      }
    | undefined
  >(undefined);

  const [updateImage, setUpdateImage] = useState<
    { content: Content; carousselIndex: number; show: boolean } | undefined
  >(undefined);

  /**********************************************************
   *** QUERY & MUTATION ***
   **********************************************************/

  const { data, loading, error, refetch } = useContentsQuery({
    variables: {
      language: "fr",
      skip: page * rowsPerPage,
      limit: rowsPerPage,
    },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
  });

  // const [update] = useUpdateContentMutation({
  //   onCompleted: () => {
  //     if (refetch) refetch();
  //   },
  //   onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  // });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  if (error) {
    return <AppError title={"GraphQL Error"} error={error} />;
  }
  if (loading) {
    return (
      <AppLoader light size={25} style={{ color: Colors.light }} thickness={3}>
        Loading Contents...
      </AppLoader>
    );
  }

  const contents: Content[] = get(data, "contents.results", []);

  // console.log("contents", contents);

  return (
    <div className={styles.App}>
      <AppRow style={{ justifyContent: "space-between", padding: "0 20px" }}>
        <AppTitle bold>{`Contents`}</AppTitle>{" "}
        {/* <AppButton
          pill
          onClick={() => {
            setCreateRow(true);
          }}
        >
          {t("button.create", "CREATE NEW LANGUAGE")}
        </AppButton> */}
      </AppRow>
      <AppCard style={stylesheet.appCard as any}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                CAROUSSEL
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                SUPPORT
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                LEGALS
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {contents.map((content: Content, key: number) => {
              return [
                <StyledTableRow
                  key={`${key}-master`}
                  hover
                  tabIndex={-1}
                  onDoubleClick={() => {}}
                >
                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                    }}
                  />

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                      width: "20%",
                    }}
                  >
                    <AppRow style={{ justifyContent: "flex-start" }}>
                      <div>
                        <FontAwesomeIcon
                          data-tip={"Update row data"}
                          data-for="updateToolTip"
                          icon={faPencil}
                          onClick={() =>
                            setUpdateRow({
                              id: content.id,
                              fields: ["carousselSection"],
                              initialValues: {
                                carousselSection: deepOmit(
                                  content.carousselSection,
                                  ["__typename"]
                                ),
                                supportSection: deepOmit(
                                  content.supportSection,
                                  ["__typename"]
                                ),
                                legalsSection: deepOmit(content.legalsSection, [
                                  "__typename",
                                ]),
                              },
                              show: true,
                            })
                          }
                          color={
                            /* !getCheck(order._id) || addTimeToOrderLoading ? Colors.disabled :  */ Colors.dark
                          }
                          style={{
                            outline: "none",
                            margin: "0 20px 0 0",
                            cursor: "pointer",
                          }}
                        />
                        <ReactTooltip
                          id="updateToolTip"
                          place="left"
                          multiline
                        />
                      </div>
                      <div>
                        {content.carousselSection.map((carousselImage, i) => (
                          <AppRow
                            key={i}
                            style={{ justifyContent: "flex-start" }}
                          >
                            <FontAwesomeIcon
                              onClick={() =>
                                setUpdateImage({
                                  content: content,
                                  carousselIndex: i,
                                  show: true,
                                })
                              }
                              data-tip={"Update data"}
                              data-for="updateToolTip"
                              icon={faImage}
                              style={{
                                outline: "none",
                                margin: "0 20px 0 0",
                                cursor: "pointer",
                                color: content.carousselSection[i].image
                                  ? Colors.dark
                                  : Colors.danger,
                              }}
                            />
                            <div style={{ margin: i > 0 ? "10px 0 0 0" : "0" }}>
                              <AppText
                                bold
                              >{`${carousselImage.title} `}</AppText>
                              <AppText>{`${carousselImage.text} `}</AppText>
                            </div>
                          </AppRow>
                        ))}
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                      width: "40%",
                    }}
                  >
                    <AppRow
                      style={{
                        justifyContent: "flex-start",
                      }}
                    >
                      <FontAwesomeIcon
                        data-tip={"Update row data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        onClick={() =>
                          setUpdateRow({
                            id: content.id,
                            fields: ["supportSection"],
                            initialValues: {
                              carousselSection: deepOmit(
                                content.carousselSection,
                                ["__typename", "image"]
                              ),
                              supportSection: deepOmit(content.supportSection, [
                                "__typename",
                              ]),
                              legalsSection: deepOmit(content.legalsSection, [
                                "__typename",
                              ]),
                            },
                            show: true,
                          })
                        }
                        color={
                          /* !getCheck(order._id) || addTimeToOrderLoading ? Colors.disabled :  */ Colors.dark
                        }
                        style={{
                          outline: "none",
                          margin: "0 20px 0 0",
                          cursor: "pointer",
                        }}
                      />
                      <div>
                        {content.supportSection.map((chapter, ind) => (
                          <div
                            key={ind}
                            style={{
                              margin: ind > 0 ? "10px 0 0 0" : "0 0 0 0",
                            }}
                          >
                            <AppText
                              bold
                              style={{ marginBottom: 5 }}
                            >{`${chapter.title} `}</AppText>
                            {chapter.sections.map((section, inde) => {
                              return (
                                <div
                                  key={inde}
                                  style={{ paddingLeft: 20, marginBottom: 5 }}
                                >
                                  <AppText bold>{`${section.title} `}</AppText>
                                  <AppText>{`${section.text} `}</AppText>
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    </AppRow>
                  </TableCell>

                  <TableCell
                    scope="row"
                    style={{
                      ...stylesheet.rowText,
                      width: "40%",
                    }}
                  >
                    <AppRow
                      style={{
                        justifyContent: "flex-start",
                      }}
                    >
                      <FontAwesomeIcon
                        data-tip={"Update row data"}
                        data-for="updateToolTip"
                        icon={faPencil}
                        onClick={() =>
                          setUpdateRow({
                            id: content.id,
                            fields: ["legalsSection"],
                            initialValues: {
                              carousselSection: deepOmit(
                                content.carousselSection,
                                ["__typename", "image"]
                              ),
                              supportSection: deepOmit(content.supportSection, [
                                "__typename",
                              ]),
                              legalsSection: deepOmit(content.legalsSection, [
                                "__typename",
                              ]),
                            },
                            show: true,
                          })
                        }
                        color={
                          /* !getCheck(order._id) || addTimeToOrderLoading ? Colors.disabled :  */ Colors.dark
                        }
                        style={{
                          outline: "none",
                          margin: "0 20px 0 0",
                          cursor: "pointer",
                        }}
                      />
                      <div>
                        {content.legalsSection.map((chapter, ind) => (
                          <div
                            key={ind}
                            style={{
                              margin: ind > 0 ? "10px 0 0 0" : "0 0 0 0",
                            }}
                          >
                            <AppText
                              bold
                              style={{ marginBottom: 5 }}
                            >{`${chapter.title} `}</AppText>
                            {chapter.sections.map((section, inde) => {
                              return (
                                <div
                                  key={inde}
                                  style={{ paddingLeft: 20, marginBottom: 5 }}
                                >
                                  <AppText bold>{`${section.title} `}</AppText>
                                  <AppText>{`${section.text} `}</AppText>
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    </AppRow>
                  </TableCell>
                </StyledTableRow>,
              ];
            })}
          </TableBody>
        </Table>

        <AppRow
          style={{
            justifyContent: "space-between",
          }}
        >
          {/*             <ContentsFiltersForm width={250} />
           */}
          <Table style={{ width: "100%" }}>
            <TableFooter>
              <TableRow>
                <StyledTablePagination
                  labelRowsPerPage={`ROWS PER PAGE`}
                  labelDisplayedRows={(paginationInfo: any) => {
                    return `PAGE ${paginationInfo.page + 1} - FROM ${
                      paginationInfo.from
                    } TO ${paginationInfo.to} - TOTAL ${paginationInfo.count}`;
                  }}
                  count={data?.contents.total ?? 0}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 20, 50, 100, 250, 500]}
                  page={page}
                  onPageChange={(e, page: number) => {
                    setPage(page);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    setRowPerPage(parseInt(event.target.value, 10));
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </AppRow>
      </AppCard>

      <AppDialog
        open={createRow}
        onClose={() => setCreateRow(false)}
        scroll="body"
        width={500}
      >
        <Create
          onClose={() => {
            setCreateRow(false);
            refetch();
          }}
          fields={[
            "language",
            "carousselSection",
            "supportSection",
            "legalsSection",
          ]}
        />
      </AppDialog>

      {/*       <AppDialog
        open={deleteRow ? deleteRow.show : false}
        onClose={() => setDeleteRow(undefined)}
        scroll="body"
        width={600}
      >
        <Delete id={deleteRow?.id} onClose={() => setDeleteRow(undefined)} />
      </AppDialog> */}

      <AppDialog
        open={updateRow ? updateRow.show : false}
        onClose={() => setUpdateRow(undefined)}
        scroll="body"
        width={500}
      >
        <Update
          id={updateRow?.id}
          initialValues={updateRow?.initialValues}
          fields={updateRow?.fields}
          onClose={() => setUpdateRow(undefined)}
        />
      </AppDialog>
      <ReactTooltip place="right" multiline />

      <AppDialog
        open={updateImage ? updateImage.show : false}
        onClose={() => setUpdateImage(undefined)}
        scroll="body"
        width={400}
      >
        <UploadImages
          content={updateImage?.content}
          carousselIndex={updateImage?.carousselIndex}
        />
      </AppDialog>
    </div>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    verticalAlign: "top",
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTablePagination = withStyles({
  root: { bcontentBottom: 0, color: Colors.transparentDark, fontSize: 12 },
  caption: { color: Colors.transparentDark, fontSize: 10 },
  selectIcon: { color: Colors.transparentDark, fontSize: 22 },
  // MuiButtonBase: { root: { outline: 'none' } }
  // overrides: {
  //     MuiButtonBase: { root: { outline: 'none' } }
  // }
})(TablePagination);

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
