import { get, indexOf } from "lodash";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Field,
  FieldArray,
  Form,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import {
  FrequencyTypes,
  ProductDeliveryTypes,
  ProductTypes,
  Seller,
  useProductsLazyQuery,
  useSellersQuery,
} from "../../../graphql/generated/types";
import {
  AppButton,
  AppCard,
  AppCol,
  AppFormCheckBox,
  AppFormDateTimePicker,
  AppFormPicker,
  AppFormTextField,
  AppLabel,
  AppRow,
  AppText,
} from "../../components";
import {
  FieldArrayCategories,
  FieldArrayHours,
  FieldArrayMonthDays,
  FieldArrayOptions,
  FieldArrayPackProducts,
  FieldArraySegments,
  FieldArraySubCategories,
  FieldArrayTags,
  FieldArrayUpsellProducts,
  FieldArrayWeekDays,
} from "./FieldArrays";

export interface IUpdateBaseFormProps {
  initialValues?: any;
  loading?: boolean;
  fields?: string[];
  submitButtonString: any;
}

export type IUpdateFormProps = IUpdateBaseFormProps &
  InjectedFormProps<any, IUpdateBaseFormProps>;

const ReduxForm: FunctionComponent<IUpdateFormProps> = ({
  initialValues,
  loading,
  handleSubmit,
  error,
  fields,
  submitButtonString,
}) => {
  const { t } = useTranslation();

  const formValues = useSelector((state) =>
    get(state, "form.productForm.values", undefined)
  );

  /* PICKERS OPTIONS *********************************************************/

  const productTypesOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(ProductTypes)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    productTypesOptions.push({ value: propertyValue, label: propertyKey });
  }

  const deliveryTypesOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(
    ProductDeliveryTypes
  )) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    deliveryTypesOptions.push({ value: propertyValue, label: propertyKey });
  }

  const frequencyTypesOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(FrequencyTypes)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    frequencyTypesOptions.push({ value: propertyValue, label: propertyKey });
  }

  /**********************************************************
   *** PRODUCTS LISTS FOR PACKPRODUCTS & UPSELLS ***
   **********************************************************/

  // const [upsellsList, setUpsellsList] = useState<PickerOption[]>([]);
  // const [itemsList, setItemsList] = useState<PickerOption[]>([]);

  const [getProductList] = useProductsLazyQuery({
    variables: {
      filter: {
        isDeleted: false,
        sellers: [formValues?.seller],
        skip: 0,
        limit: 100,
      },
    },
    // onCompleted: (d) => {
    //   // Upsells
    //   const upsells = d.products.results
    //     .map((p) => {
    //       if (p.type !== ProductTypes.Upsell) {
    //         return { label: p.name, value: p.id };
    //       } else return undefined;
    //     })
    //     .filter((o) => o !== undefined);
    //   setUpsellsList(upsells as any);
    //   // Items
    //   const items = d.products.results
    //     .map((p) => {
    //       if (p.type !== ProductTypes.Item) {
    //         return { label: p.name, value: p.id };
    //       } else return undefined;
    //     })
    //     .filter((o) => o !== undefined);
    //   setItemsList(items as any);
    // },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
  });

  useEffect(() => {
    if (formValues?.seller) {
      getProductList();
    }
  }, [formValues, getProductList]);

  const { data } = useSellersQuery({
    variables: { skip: 0, limit: 100 },
    onError: (e) => console.log(JSON.parse(JSON.stringify(e))),
  });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  if (!data) return null;
  const sellers = data.sellers.results.map((seller: Seller) => {
    return { label: seller.name, value: seller.id };
  });

  // console.log("form", initialValues, fields);

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}

      <AppCard
        style={{
          margin: "20px 0",
          paddingBottom: 10,
        }}
      >
        {(!fields || indexOf(fields, "seller") !== -1) && (
          <Field
            name="seller"
            component={AppFormPicker}
            options={sellers}
            label={t("fieldLabel.productSeller", "Product Seller")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "type") !== -1) && (
          <Field
            name="type"
            component={AppFormPicker}
            options={productTypesOptions}
            label={t("fieldLabel.productType", "Product Type")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}
      </AppCard>

      {formValues?.seller && formValues?.type && (
        <>
          <AppCard
            style={{
              margin: "20px 0",
              paddingBottom: 10,
            }}
          >
            {(!fields || indexOf(fields, "deliveryType") !== -1) && (
              <Field
                name="deliveryType"
                component={AppFormPicker}
                options={deliveryTypesOptions}
                label={t("fieldLabel.deliveryType", "Product Delivery Type")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                style={{ marginBottom: 20 }}
              />
            )}

            {(!fields || indexOf(fields, "reference") !== -1) && (
              <Field
                name="reference"
                component={AppFormTextField}
                label={t("fieldLabel.productReference", "Product Reference")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                autoFocus
                style={{ marginBottom: 20 }}
              />
            )}

            {!initialValues && (
              <AppRow>
                <Field
                  name="hasSorting"
                  component={AppFormCheckBox}
                  label={t("fieldLabel.hasSorting", "Sort?")}
                  //   help={t("fieldHelp.firstname", )}
                  autoFocus
                  style={{ marginBottom: 20 }}
                />
                <AppCol style={{ width: "100%" }}>
                  <Field
                    name="sorting"
                    component={AppFormTextField}
                    label={t("fieldLabel.sorting", "Sorting")}
                    //   help={t("fieldHelp.firstname", )}
                    type="text"
                    autoFocus
                    style={{ marginBottom: 20 }}
                  />
                </AppCol>
              </AppRow>
            )}

            {(!fields || indexOf(fields, "name") !== -1) && (
              <Field
                name="name"
                component={AppFormTextField}
                label={t("fieldLabel.productName", "Product Name")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                autoFocus
                style={{ marginBottom: 20 }}
              />
            )}

            {(!fields || indexOf(fields, "quantity") !== -1) && (
              <Field
                name="quantity"
                component={AppFormTextField}
                label={t("fieldLabel.productQuantity", "Product Quantity")}
                //   help={t("fieldHelp.firstname", )}
                type="number"
                parse={parseInt}
                style={{ marginBottom: 20 }}
              />
            )}

            {(!fields || indexOf(fields, "description") !== -1) && (
              <Field
                name="description"
                component={AppFormTextField}
                label={t("fieldLabel.description", "Description")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                autoFocus
                multiline
                style={{ marginBottom: 20 }}
              />
            )}

            {(!fields || indexOf(fields, "warningDescription") !== -1) && (
              <Field
                name="warningDescription"
                component={AppFormTextField}
                label={t(
                  "fieldLabel.warningDescription",
                  "Warning Description"
                )}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                autoFocus
                style={{ marginBottom: 20 }}
              />
            )}

            {(!fields || indexOf(fields, "totalInventory") !== -1) &&
              initialValues.variants.length === 0 && (
                <Field
                  name="totalInventory"
                  component={AppFormTextField}
                  label={t("fieldLabel.totalInventory", "Warning Description")}
                  //   help={t("fieldHelp.firstname", )}
                  type="number"
                  autoFocus
                  parse={parseInt}
                  style={{ marginBottom: 20 }}
                />
              )}
          </AppCard>

          {formValues?.type === ProductTypes.Subscription && (
            <AppCard
              style={{
                margin: "20px 0",
                paddingBottom: 10,
              }}
            >
              {(!fields || indexOf(fields, "frequency") !== -1) && (
                <Field
                  name="frequency"
                  component={AppFormPicker}
                  options={frequencyTypesOptions}
                  label={t("fieldLabel.frequency", "Subscription Frequency")}
                  //   help={t("fieldHelp.firstname", )}
                  type="text"
                  style={{ marginBottom: 20 }}
                />
              )}
              {(!fields || indexOf(fields, "duration") !== -1) && (
                <Field
                  name="duration"
                  component={AppFormTextField}
                  label={t(
                    "fieldLabel.duration",
                    "Susbcription Duration (Days)"
                  )}
                  //   help={t("fieldHelp.firstname", )}
                  type="number"
                  parse={parseInt}
                  style={{ marginBottom: 20 }}
                />
              )}
              {[FrequencyTypes.Monthly].includes(formValues?.frequency) && (
                <FieldArray
                  name="monthdays"
                  component={FieldArrayMonthDays as any}
                />
              )}
              {[FrequencyTypes.Weekly].includes(formValues?.frequency) && (
                <FieldArray
                  name="weekdays"
                  component={FieldArrayWeekDays as any}
                />
              )}
              {[
                FrequencyTypes.Monthly,
                FrequencyTypes.Weekly,
                FrequencyTypes.Daily,
              ].includes(formValues?.frequency) && (
                <FieldArray name="hours" component={FieldArrayHours as any} />
              )}
            </AppCard>
          )}

          <AppCard
            style={{
              margin: "20px 0",
              paddingBottom: 10,
            }}
          >
            {(((!fields || indexOf(fields, "packProducts") !== -1) &&
              formValues?.type === ProductTypes.Pack) ||
              formValues?.type === ProductTypes.Subscription) && (
              <>
                <AppLabel bold>
                  {t("fieldLabel.packProducts", "Pack Products")}
                </AppLabel>
                <FieldArray
                  name="packProducts"
                  component={FieldArrayPackProducts as any}
                  props={{ sellerId: initialValues.seller }}
                />
              </>
            )}
            {(!fields || indexOf(fields, "options") !== -1) &&
              formValues?.type === ProductTypes.Item && (
                <FieldArray
                  name="options"
                  component={FieldArrayOptions as any}
                  props={{ sellerId: initialValues.seller }}
                />
              )}
            {(!fields || indexOf(fields, "upsellProducts") !== -1) &&
              formValues?.type === ProductTypes.Item && (
                <FieldArray
                  name="upsellProducts"
                  component={FieldArrayUpsellProducts as any}
                  props={{ sellerId: initialValues.seller }}
                />
              )}
            {/* (!fields || indexOf(fields, "crossProducts") !== -1) && (
          <FieldArray
            name="crossProducts"
            component={FieldArrayCrossProducts as any}
            props
          />
        ) */}
          </AppCard>

          <AppCard
            style={{
              margin: "20px 0",
              paddingBottom: 10,
            }}
          >
            {(!fields || indexOf(fields, "segments") !== -1) && (
              <FieldArray
                name="segments"
                component={FieldArraySegments as any}
                props={{}}
              />
            )}
            {(!fields || indexOf(fields, "categories") !== -1) && (
              <FieldArray
                name="categories"
                component={FieldArrayCategories as any}
                props={{}}
              />
            )}
            {(!fields || indexOf(fields, "subCategories") !== -1) && (
              <FieldArray
                name="subCategories"
                component={FieldArraySubCategories as any}
                props={{ sellerId: initialValues.seller }}
              />
            )}
            {(!fields || indexOf(fields, "tags") !== -1) && (
              <FieldArray
                name="tags"
                component={FieldArrayTags as any}
                props={{}}
              />
            )}
          </AppCard>
        </>
      )}

      <AppCard
        style={{
          margin: "20px 0",
          paddingBottom: 10,
        }}
      >
        <AppRow style={{ justifyContent: "space-between" }}>
          {(!fields || indexOf(fields, "availabilityStartDate") !== -1) && (
            <Field
              name="availabilityStartDate"
              component={AppFormDateTimePicker}
              label={t("fieldLabel.availabilityStartDate", "Starts at")}
              //   help={t("fieldHelp.firstname", )}
              type="text"
              autoFocus
              style={{ marginBottom: 20 }}
            />
          )}

          {(!fields || indexOf(fields, "availabilityEndDate") !== -1) && (
            <Field
              name="availabilityEndDate"
              component={AppFormDateTimePicker}
              label={t("fieldLabel.availabilityEndDate", "Ends at")}
              //   help={t("fieldHelp.firstname", )}
              type="text"
              autoFocus
              style={{ marginBottom: 20 }}
            />
          )}
        </AppRow>
      </AppCard>

      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}
      <AppButton primary valid type="submit" loading={loading}>
        {submitButtonString}
      </AppButton>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: "productForm",
})(ReduxForm);
