import React, { FunctionComponent, useState } from "react";
import {
  useDeleteProductVariantImageMutation,
  useUploadProductVariantImageMutation,
} from "../../../../graphql/generated/types";
import {
  AppButton,
  AppDialog,
  AppError,
  AppImageUploading,
  AppRow,
  AppText,
} from "../../../components";
import { AppTimerButton } from "../../../components/AppTimerButton";

export interface IUploadVariantImageProps {
  variant: any;
  imageDescription: "image" | "swatchImage";
  imageIndex?: number;
  refetch: () => void;
}

export const UploadVariantImage: FunctionComponent<
  IUploadVariantImageProps
> = ({ variant, imageDescription, imageIndex, refetch }) => {
  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [error, setError] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: "",
    message: "",
  });

  /**********************************************************
   *** MUTATION ***
   **********************************************************/

  const [images, setImages] = useState<any[]>([]);

  const [uploadImage, { loading: imageLoading }] =
    useUploadProductVariantImageMutation({
      onError: (e) => {
        console.log(JSON.stringify(e));
        setError({
          show: true,
          title: "Problem uploading the image",
          message: JSON.stringify(e),
        });
      },
      onCompleted: () => {
        if (refetch) refetch();
      },
    });
  const [deleteImage, { loading: deleteLoading }] =
    useDeleteProductVariantImageMutation({
      onError: (e) => {
        console.log(JSON.stringify(e));
        setError({
          show: true,
          title: "Problem deleting the image",
          message: JSON.stringify(e),
        });
      },
      onCompleted: () => {
        if (refetch) refetch();
      },
    });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  let filename: string = "";
  let imageUrl: string = "";
  if (imageDescription === "image" && imageIndex !== undefined) {
    filename = variant.images ? variant.images[imageIndex].filename ?? "" : "";
    imageUrl = variant.images ? variant.images[imageIndex].url : "";
  }
  if (imageDescription === "swatchImage") {
    filename = variant.swatchImage?.filename ?? "";
    imageUrl = variant.swatchImage?.url ?? "";
  }

  return (
    <>
      {imageUrl ? (
        <AppRow style={{ justifyContent: "space-between", marginBottom: 15 }}>
          <img
            src={imageUrl}
            className="img"
            alt="Responsive"
            width="auto"
            height="60px"
            style={{ textAlign: "center" }}
          />
          <AppTimerButton
            loading={deleteLoading}
            endOfTimeFunction={() =>
              deleteImage({
                variables: {
                  productVariantId: variant?.id,
                  filename,
                  imageDescription,
                },
              })
            }
          />
        </AppRow>
      ) : (
        <>
          <AppImageUploading
            saveImage={(images) => setImages(images)}
            maxFileSize={5000000}
            maxImagesNumber={1}
          />
          <AppText center style={{ margin: "10px 0 20px 0" }}>
            {imageDescription === "image"
              ? "Images must be a square with at least 640 pixels aside"
              : imageDescription === "swatchImage"
              ? "Swatch must be a square with at least 100 pixels aside"
              : ""}
          </AppText>
        </>
      )}

      {images.length > 0 && (
        <AppButton
          loading={imageLoading}
          primary
          onClick={() => {
            if (variant)
              uploadImage({
                variables: {
                  file: images[0].file,
                  productVariantId: variant?.id,
                  imageDescription,
                },
              }).catch((e: any) => console.log(e));
          }}
        >
          UPLOAD
        </AppButton>
      )}

      <AppDialog
        open={error.show}
        onClose={() => {
          setError({
            show: false,
            title: "",
            message: "",
          });
        }}
        scroll="body"
        width={600}
      >
        <AppError
          title={error.title}
          message={error.message}
          onClose={() => {
            setError({
              show: false,
              title: "",
              message: "",
            });
          }}
        />
      </AppDialog>
    </>
  );
};
