import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Field, WrappedFieldArrayProps } from "redux-form";
import { Colors } from "../../../styles/Colors";
import theme from "../../../styles/Theme.module.scss";
import {
  AppButton,
  AppCol,
  AppFormTextField,
  AppLabel,
  AppRow,
} from "../../components";

export const FieldArrayDisplayProducts: FunctionComponent<
  WrappedFieldArrayProps
> = ({ fields, meta: { error } }) => {
  const { t } = useTranslation();

  if (fields.length === 0) fields.push("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: 15,
      }}
    >
      {fields.map((product, index) => {
        // console.log('FieldArrayDoorCodes', doorCode);
        return (
          <AppRow
            key={index}
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppCol style={{ width: fields.length === 1 ? "100%" : "93%" }}>
              {index === 0 && (
                <AppLabel bold>
                  {t("fieldLabel.subCategory", "Products Display")}
                </AppLabel>
              )}
              <Field
                name={product}
                component={AppFormTextField}
                label={`Position ${index + 1}`}
                //   help={index === fields.length - 1 && t("fieldHelp.doorCode")}
                type="text"
                style={{
                  marginTop: 10,
                  marginBottom: index === fields.length - 1 ? 15 : 5,
                }}
              />
            </AppCol>
            {fields.length > 1 && (
              <FontAwesomeIcon
                onClick={() => fields.remove(index)}
                icon={faTrashAlt}
                style={{
                  fontSize: 12,
                  color: Colors.transparentDark,
                  margin: index === 0 ? "26px 0px 0 5px" : "10px 0px 0 5px",
                  cursor: "pointer",
                }}
              />
            )}
          </AppRow>
        );
      })}
      <AppButton
        pill
        extraSmall
        type="button"
        onClick={() => fields.push("")}
        borderWidth={1}
        transparentDark
        className={theme.borderColorTransparentDark}
        style={{ flex: "none", borderStyle: "solid", borderWidth: 1 }}
      >
        {t("button.addCategory", "ADD A PRODUCT")}
      </AppButton>
    </div>
  );
};
