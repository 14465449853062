import React from "react";
import theme from "../../styles/Theme.module.scss";

export interface IAppDetailsProps {
  valid?: boolean;
  danger?: boolean;
  light?: boolean;
  disabled?: boolean;
  warning?: boolean;
  center?: boolean;
  right?: boolean;
  bold?: boolean;
  obsolete?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

export const AppDetails: React.FC<IAppDetailsProps> = ({
  valid,
  danger,
  disabled,
  light,
  warning,
  bold,
  center,
  right,
  obsolete,
  style,
  children,
  className,
}) => {
  const color = disabled
    ? `${theme.colorDisabled}`
    : light
    ? `${theme.colorLight}`
    : danger
    ? `${theme.colorDanger}`
    : valid
    ? `${theme.colorValid}`
    : warning
    ? `${theme.colorWarning}`
    : `${theme.colorDark}`;
  const alignment = center
    ? `${theme.textAlignCenter}`
    : right
    ? `${theme.textAlignRight}`
    : undefined;

  return (
    <div
      className={`${className} ${alignment} ${color} ${theme.inputStyle}  ${
        bold ? theme.bold : undefined
      } ${obsolete ? theme.obsolete : undefined}`}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};
