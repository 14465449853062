import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AppIndex } from "./AppIndex";
import "./i18n/i18n";
import "./index.css";
import "./styles/fonts/Roboto-Bold.ttf";
import "./styles/fonts/Roboto-Light.ttf";
import "./styles/fonts/Roboto-Regular.ttf";

ReactDOM.render(
  <AppIndex>
    {(history) => {
      return <App history={history} />;
    }}
  </AppIndex>,
  document.getElementById("root")
);
