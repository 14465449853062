import React from "react";
import theme from "../../styles/Theme.module.scss";
export interface IAppTitleProps {
  subtitle?: React.ReactNode;
  supertitle?: React.ReactNode;
  light?: boolean;
  valid?: boolean;
  danger?: boolean;
  warning?: boolean;
  bold?: boolean;
  extrabold?: boolean;
  transparentDark?: boolean;
  transparentLight?: boolean;
  titleTextStyle?: React.CSSProperties;
  subtitleTextStyle?: React.CSSProperties;
  supertitleTextStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
  classNameSupertitle?: string;
  classNameTitle?: string;
  classNameSubtitle?: string;
  small?: boolean;
  right?: boolean;
  center?: boolean;
}

export const AppTitle: React.FC<IAppTitleProps> = ({
  subtitle,
  supertitle,
  light,
  extrabold,
  bold,
  warning,
  valid,
  transparentDark,
  transparentLight,
  danger,
  titleTextStyle,
  subtitleTextStyle,
  supertitleTextStyle,
  style,
  onClick,
  children,
  className,
  classNameSupertitle,
  classNameTitle,
  classNameSubtitle,
  small,
  right,
  center,
}) => {
  /**********************************************************
   *** STYLE ***
   **********************************************************/

  const colorClassName = `${
    valid
      ? theme.colorValid
      : danger
      ? theme.colorDanger
      : warning
      ? theme.colorWarning
      : transparentDark
      ? theme.colorTransparentDark
      : transparentLight
      ? theme.colorTransparentLight
      : light
      ? theme.colorLight
      : theme.colorDark
  }`;

  const containerClassName = `${onClick && theme.cursorPointer} ${className} ${
    center ? theme.textAlignCenter : right ? theme.textAlignRight : ""
  }`;

  const supertitleClassName = `${theme.smallTextStyle}  ${colorClassName}  ${classNameSupertitle}`;

  const titleClassName = `${
    small ? theme.smallTitleStyle : theme.titleStyle
  } ${colorClassName} ${
    extrabold ? theme.extrabold : bold ? theme.bold : theme.normal
  } ${classNameTitle}`;

  const subtitleClassName = `${theme.textStyle} ${colorClassName} ${
    bold ? theme.normal : theme.thin
  } ${classNameSubtitle}`;

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <div
      className={`${containerClassName}`}
      onClick={onClick}
      style={{ ...style }}
    >
      {supertitle && (
        <div
          className={`${supertitleClassName}`}
          style={{ marginBottom: 3, ...supertitleTextStyle }}
        >
          {supertitle}
        </div>
      )}

      <div className={`${titleClassName}`} style={{ ...titleTextStyle }}>
        {children}
      </div>

      {subtitle && (
        <div
          className={`${subtitleClassName}`}
          style={{ marginTop: 0, ...subtitleTextStyle }}
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};
