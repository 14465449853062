import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import {
  CartItem,
  CartPack,
  Product,
  ProductTypes,
  useOrderQuery,
} from "../../../graphql/generated/types";
import { priceDisplay } from "../../../helpers/PriceDisplay";
import { Colors } from "../../../styles/Colors";
import { AppRow, AppText, AppTitle } from "../../components";
import { AppCard } from "../../components/AppCard";

export interface IVariantProps {
  orderId: string;
  onClose: () => void;
}

export const Cart: FunctionComponent<IVariantProps> = ({
  orderId,
  onClose,
}) => {
  //
  // const { t } = useTranslation();

  /**********************************************************
   *** QUERY & MUTATION ***
   **********************************************************/

  const { data } = useOrderQuery({
    variables: { orderId },
    onError: (error) => {
      const e = JSON.parse(JSON.stringify(error));
      console.log(e);
    },
  });
  /**********************************************************
   *** STATE ***
   **********************************************************/

  // const [createRow, setCreateRow] = useState<boolean>(false);
  // const [deleteRow, setDeleteRow] = useState<
  //   { id: string; show: boolean } | undefined
  // >(undefined);

  // const [updateRow, setUpdateRow] = useState<
  //   | {
  //       show: boolean;
  //       id: string;
  //     }
  //   | undefined
  // >(undefined);

  // const [updateVariantImages, setUpdateVariantImages] = useState<
  //   { productVariant: ProductVariant; show: boolean } | undefined
  // >(undefined);

  /**********************************************************
   *** MUTATIONS ***
   **********************************************************/

  // const [updateCartProduct] = useUpdateCartProductMutation({
  //   onCompleted: () => {
  //     refetch();
  //   },
  //   onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  // });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  // console.log("cartProduct", data?.order.cart);

  return (
    <AppCard style={{ margin: 0, paddingTop: 35 }}>
      <AppRow style={{ justifyContent: "space-between", padding: "0 20px" }}>
        <AppTitle bold>{`Cart`}</AppTitle>
      </AppRow>
      <AppCard style={stylesheet.appCard as any}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                NAME
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                QTY
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                UNIT PRICE
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                TOTAL
              </TableCell>

              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.order.cart.map((cartProduct, key) => (
              <StyledTableRow key={`cartProduct-${key}`}>
                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText bold>{`${
                    cartProduct?.cartProduct?.name ?? ""
                  }`}</AppText>

                  {cartProduct.cartProduct.type === ProductTypes.Item &&
                    (cartProduct?.cartProduct as CartItem).upsells?.map(
                      (upsell, index) => {
                        return (
                          <AppText
                            small
                            key={`upsell-${index}`}
                          >{`${upsell.name}`}</AppText>
                        );
                      }
                    )}
                  {cartProduct.cartProduct.type === ProductTypes.Pack &&
                    (cartProduct?.cartProduct as CartPack).content.map(
                      (content, index) => {
                        return (
                          <AppText
                            small
                            key={`content-${index}`}
                          >{`${content.name}`}</AppText>
                        );
                      }
                    )}
                  <AppText small>{`${
                    cartProduct?.cartProduct?.description ??
                    (cartProduct?.cartProduct as CartItem)
                      .variantTypesDescription
                  }`}</AppText>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText bold>{`${
                    cartProduct?.quantity ?? 0 ? cartProduct?.quantity : 0
                  }`}</AppText>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText bold>
                    {`${priceDisplay(
                      (cartProduct?.cartProduct?.prices?.[0]?.amountWithTax ??
                        0) / 100,
                      "Fr",
                      cartProduct?.cartProduct?.prices?.[0]?.currency ?? "none"
                    )}TTC`}
                  </AppText>
                  {cartProduct.cartProduct.type === ProductTypes.Item && (
                    <>
                      {(cartProduct?.cartProduct as CartItem).upsells?.map(
                        (upsell, index) => {
                          return (
                            <AppText key={`upsell-${index}`} bold>
                              {`${priceDisplay(
                                (upsell?.prices?.[0]?.amountWithTax ?? 0) / 100,
                                "Fr",
                                upsell?.prices?.[0]?.currency ?? "none"
                              )}TTC`}
                            </AppText>
                          );
                        }
                      )}
                      <AppText small>
                        {`${priceDisplay(
                          (cartProduct?.cartProduct.prices?.[0]
                            ?.amountWithoutTax ?? 0) +
                            ((
                              cartProduct?.cartProduct as CartItem
                            ).upsells?.reduce(
                              (acc: number, item: Product) =>
                                acc +
                                (item?.prices?.[0]?.amountWithoutTax ?? 0),
                              0
                            ) ?? 0) /
                              100,
                          "Fr",
                          cartProduct?.cartProduct?.prices?.[0]?.currency ??
                            "none"
                        )}HT + ${
                          (cartProduct?.cartProduct as CartItem).prices?.[0]
                            ?.taxRate
                        }%`}
                      </AppText>
                    </>
                  )}

                  {cartProduct.cartProduct.type === ProductTypes.Pack && (
                    <AppText small>
                      {`${priceDisplay(
                        (cartProduct?.cartProduct?.prices?.[0]
                          ?.amountWithoutTax ?? 0) / 100,
                        "Fr",
                        cartProduct?.cartProduct?.prices?.[0]?.currency ??
                          "none"
                      )}HT + ${
                        cartProduct?.cartProduct?.prices?.[0]?.taxRate
                      }%`}
                    </AppText>
                  )}
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  {cartProduct.cartProduct.type === ProductTypes.Item && (
                    <>
                      <AppText bold>
                        {`${priceDisplay(
                          ((
                            cartProduct?.cartProduct as CartItem
                          ).upsells?.reduce(
                            (acc: number, item: Product) =>
                              acc + (item?.prices?.[0]?.amountWithTax ?? 0),
                            0
                          ) ?? 0) / 100,
                          "Fr",
                          cartProduct?.cartProduct?.prices?.[0]?.currency ??
                            "none"
                        )}TTC`}
                      </AppText>
                      <AppText small>
                        {`${priceDisplay(
                          ((
                            cartProduct?.cartProduct as CartItem
                          ).upsells?.reduce(
                            (acc: number, item: Product) =>
                              acc + (item?.prices?.[0]?.amountWithoutTax ?? 0),
                            0
                          ) ?? 0) / 100,
                          "Fr",
                          cartProduct?.cartProduct?.prices?.[0]?.currency ??
                            "none"
                        )}HT + ${
                          cartProduct?.cartProduct?.prices?.[0]?.taxRate
                        }%`}
                      </AppText>
                    </>
                  )}
                  {cartProduct.cartProduct.type === ProductTypes.Pack && (
                    <>
                      <AppText bold>
                        {`${priceDisplay(
                          (cartProduct?.cartProduct?.prices?.[0]
                            ?.amountWithTax ?? 0) / 100,
                          "Fr",
                          cartProduct?.cartProduct?.prices?.[0]?.currency ??
                            "none"
                        )}TTC`}
                      </AppText>
                      <AppText small>
                        {`${priceDisplay(
                          (cartProduct?.cartProduct?.prices?.[0]
                            ?.amountWithoutTax ?? 0) / 100,
                          "Fr",
                          cartProduct?.cartProduct?.prices?.[0]?.currency ??
                            "none"
                        )}HT + ${
                          cartProduct?.cartProduct?.prices?.[0]?.taxRate
                        }%`}
                      </AppText>
                    </>
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </AppCard>
    </AppCard>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
