import {
  FrequencyTypes,
  Product,
  ProductTypes,
  ProductVariant,
} from "../graphql/generated/types";

export const productDisplayDetailsCompleted = (product: Product) => {
  const baseParameters = () => {
    if (
      product.name &&
      product.deliveryType &&
      product.seller &&
      product.reference &&
      product.segments.length > 0 &&
      product.categories.length > 0 &&
      product.type &&
      product.description &&
      product.mainImage &&
      product.availabilityStartDate &&
      product.availabilityEndDate
    )
      return true;
    return false;
  };

  if (product.type === ProductTypes.Item) {
    return baseParameters() ? true : false;
  }

  if (product.type === ProductTypes.Pack) {
    return baseParameters() &&
      product.packProducts &&
      product.packProducts.length > 0
      ? true
      : false;
  }

  if (product.type === ProductTypes.Subscription) {
    const testDays = () => {
      if (
        product.frequency === FrequencyTypes.Weekly &&
        product.weekdays &&
        product.weekdays.length > 0
      )
        return true;
      if (
        product.frequency === FrequencyTypes.Monthly &&
        product.monthdays &&
        product.monthdays.length > 0
      )
        return true;
      if (product.frequency === FrequencyTypes.Daily) return true;
      return false;
    };
    return baseParameters() &&
      product.packProducts &&
      product.packProducts.length > 0 &&
      product.duration &&
      product.frequency &&
      testDays() &&
      product.hours
      ? true
      : false;
  }

  if (product.type === ProductTypes.Upsell) {
    return baseParameters() ? true : false;
  }
};

export const productVariantDisplayDetailsCompleted = (
  variant: ProductVariant
) => {
  if (
    variant.name &&
    // variant.postfixVariantReference &&
    variant.types.length > 0 &&
    variant.description &&
    variant.swatchImage &&
    variant.availabilityStartDate &&
    variant.availabilityEndDate
  )
    return true;
  return false;
};

export const productVariantPriceDetailsCompleted = (
  variant: ProductVariant
) => {
  if (variant.prices && variant.prices.length > 0) return true;
  return false;
};

export const productPriceDetailsCompleted = (product: Product) => {
  if (product.prices && product.prices.length > 0) return true;
  return false;
};
