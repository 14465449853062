import { get } from "lodash";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SubmissionError } from "redux-form";
import {
  CreateMessengerInput,
  useCreateMessengerMutation,
} from "../../../graphql/generated/types";
import { apiErrors } from "../../../helpers/Errors";
import { AppRow, AppTitle } from "../../components";
import CreateForm from "./Form";

export interface ICreateProps {
  initialValues?: any;
  fields?: string[];
  refetch?: () => void;
  onClose: () => void;
}

export const Create: FunctionComponent<ICreateProps> = ({
  initialValues,
  onClose,
  refetch,
  fields,
}) => {
  const { t } = useTranslation();

  const [create, { loading }] = useCreateMessengerMutation({
    onCompleted: () => {
      if (refetch) refetch();
      onClose();
    },
    onError: (e: any) => console.log(JSON.parse(JSON.stringify(e))),
  });

  return (
    <>
      <AppRow
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <AppTitle
          small
          extrabold
          light
          subtitle={t("create.subtitle", "Please enter the needed fields")}
        >
          {t("create.title", "Create")}
        </AppTitle>
      </AppRow>

      <CreateForm
        initialValues={initialValues}
        submitButtonString={t("button.create", "CREATE")}
        loading={loading}
        fields={fields}
        // validate={(values: any) =>
        //   validate(values, validators(t), { fullMessages: false })
        // }
        onSubmit={async (values: CreateMessengerInput) => {
          try {
            await create({
              variables: {
                ...values,
              },
            });
          } catch (e: any) {
            throw new SubmissionError(
              apiErrors({
                errors: get(
                  e,
                  "graphQLErrors[0].extensions.exception.errors",
                  []
                ),
                message: e.message,
              })
            );
          }
        }}
      />
    </>
  );
};
