import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { join } from "lodash";
import React, { FunctionComponent } from "react";
import { Product } from "../../../graphql/generated/types";
import { Colors } from "../../../styles/Colors";
import { AppRow, AppText, AppTitle } from "../../components";
import { AppCard } from "../../components/AppCard";

export interface IPackProductsProps {
  product?: Product;
  onClose: () => void;
}

export const PackProducts: FunctionComponent<IPackProductsProps> = ({
  product,
  onClose,
}) => {
  //

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  const packProducts = product?.packProducts;
  console.log(packProducts);

  return (
    <AppCard style={{ margin: 0, paddingTop: 35 }}>
      <AppRow style={{ justifyContent: "space-between", padding: "0 20px" }}>
        <AppTitle
          bold
          subtitle={`Segment(s): ${join(
            product?.segments?.map((segment) => segment.name),
            " | "
          )} / Category: ${join(
            product?.categories?.map((category) => category.name),
            " | "
          )}`}
        >{`PackProducts: "${product?.name} "`}</AppTitle>
      </AppRow>
      <AppCard style={stylesheet.appCard as any}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell />

              <TableCell style={{ ...stylesheet.columnHeadText }}>
                QUANTITY TO SELECT
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                PRODUCT OPTIONS
              </TableCell>
              <TableCell style={{ ...stylesheet.columnHeadText }}>
                MESSAGE TO USER
              </TableCell>

              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {packProducts?.map((packProduct, key) => (
              <StyledTableRow key={`packProduct-${key}`}>
                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText bold>{`Set of Product(s) #${key + 1}`}</AppText>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText bold>{`${packProduct.quantityToSelect}`}</AppText>
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  {packProduct.productList.map((p) => (
                    <AppText
                      bold
                    >{`${p.name} (Quantity: ${p.quantity})`}</AppText>
                  ))}
                </TableCell>

                <TableCell
                  scope="row"
                  style={{
                    ...stylesheet.rowText,
                  }}
                >
                  <AppText bold>{`${packProduct.callToSelectMessage}`}</AppText>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </AppCard>
    </AppCard>
  );
};

/**********************************************************
 *** STYLES ***
 **********************************************************/

const stylesheet = {
  appCard: {
    padding: 0,
    paddingTop: 0,
    marginTop: 20,
  },
  rowText: {
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  columnHeadText: {
    color: Colors.disabled,
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
};

const StyledTableRow = withStyles({
  root: {},
})(TableRow);
