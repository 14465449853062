import { faCircle, faDotCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { find } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { WrappedFieldProps } from "redux-form";
import { Colors } from "../../../styles/Colors";
import theme from "../../../styles/Theme.module.scss";
import { AppRow } from "../AppRow";

export interface IOption {
  value: any;
  label: any;
}

export interface IAppFormRadioProps {
  row?: boolean;
  options: IOption[];
  firstOptionAsDefaultValue?: boolean;
  help?: any;
  light?: boolean;
  label?: any;
}

export const AppFormRadio: FunctionComponent<
  IAppFormRadioProps & WrappedFieldProps
> = ({
  row,
  options,
  firstOptionAsDefaultValue,
  help,
  light,
  label,
  input,
  meta: { touched, error, warning, valid, invalid },
  ...rest
}) => {
  //
  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [chosenOption, setChosenOption] = useState<{
    label: string;
    value: string | undefined;
  }>({ label: "Select...", value: undefined });

  /**********************************************************
   *** LIFECYCLE ***
   **********************************************************/

  useEffect(() => {
    if (firstOptionAsDefaultValue) {
      setChosenOption({
        value: options[0].value,
        label: options[0].label,
      });
      input.onChange(options[0].value);
    }
    if (input.value) {
      const getOptionFromValue = find(options, {
        value: input.value,
      });
      if (getOptionFromValue !== undefined) {
        setChosenOption({
          value: input.value,
          label: getOptionFromValue.label,
        });
        input.onChange(input.value);
      }
    }
  }, [firstOptionAsDefaultValue, input, options]);

  /**********************************************************
   *** FUNCS ***
   **********************************************************/

  const onChange = (option: any) => {
    setChosenOption(option);
    input.onChange(option.value);
  };

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <>
      {label && (
        <div style={{ marginBottom: 5 }}>
          <div
            className={`${theme.labelStyle} ${theme.bold} ${
              light ? theme.colorLight : theme.colorDark
            }`}
          >
            {label}
          </div>
        </div>
      )}

      <AppRow
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: row ? "row" : "column",
          marginTop: 10,
        }}
      >
        {options.map((option, index) => (
          <div
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => onChange(option)}
          >
            <AppRow
              style={{
                justifyContent: "flex-start",
                marginBottom: index === options.length - 1 ? 2 : 10,
                marginRight: 30,
              }}
            >
              <FontAwesomeIcon
                icon={
                  option.value === chosenOption.value ? faDotCircle : faCircle
                }
                color={light ? Colors.light : Colors.dark}
                style={{ marginRight: 10 }}
              />
              <div
                className={`${theme.inputStyle} ${
                  light ? theme.colorLight : theme.colorDark
                } ${
                  (option.value === chosenOption.value && theme.bold) as string
                }`}
              >
                {option.label}
              </div>
            </AppRow>
          </div>
        ))}
      </AppRow>
      {(help ?? (touched && (error || warning))) && (
        <span>
          {touched && error && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorDanger}`}
            >
              {error}
            </small>
          )}
          {touched && warning && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${theme.colorWarning}`}
            >
              {warning}
            </small>
          )}
          {help && (
            <small
              className={`${theme.smallTextStyle} ${theme.bold} ${
                light ? theme.colorTransparentLight : theme.colorTransparentDark
              }`}
            >
              {help}
            </small>
          )}
        </span>
      )}
    </>
  );
};
