import { isObject, keyBy, transform } from "lodash";

export const deepOmit = (obj: any, keysToOmit: any) => {
  var keysToOmitIndex = keyBy(
    Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]
  ); // create an index object of the keys that should be omitted

  function omitFromObject(obj: any) {
    // the inner function which will be called recursivley
    return transform(obj, function (result: any, value: any, key: any) {
      // transform to a new object
      if (key in keysToOmitIndex) {
        // if the key is in the index skip it
        return;
      }

      result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
    });
  }

  return omitFromObject(obj); // return the inner function result
};
