import React, { FunctionComponent, useState } from "react";
import {
  Content,
  useDeleteCarousselImageMutation,
  useUploadCarousselImageMutation,
} from "../../../graphql/generated/types";
import {
  AppButton,
  AppDialog,
  AppError,
  AppImageUploading,
  AppRow,
  AppText,
  AppTimerButton,
} from "../../components";

export interface IUploadCategoryImageProps {
  content: Content;
  carousselIndex: number;
  refetch: () => void;
}

export const UploadCarousselImage: FunctionComponent<
  IUploadCategoryImageProps
> = ({ content, carousselIndex, refetch }) => {
  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [error, setError] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: "",
    message: "",
  });

  /**********************************************************
   *** MUTATION ***
   **********************************************************/

  const [images, setImages] = useState<any[]>([]);

  const [uploadImage, { loading: imageLoading }] =
    useUploadCarousselImageMutation({
      onError: (e) => {
        console.log(JSON.stringify(e));
        setError({
          show: true,
          title: "Problem uploading the image",
          message: JSON.stringify(e),
        });
      },
      onCompleted: () => {
        if (refetch) refetch();
      },
    });
  const [deleteImage, { loading: deleteLoading }] =
    useDeleteCarousselImageMutation({
      onError: (e) => {
        console.log(JSON.stringify(e));
        setError({
          show: true,
          title: "Problem deleting the image",
          message: JSON.stringify(e),
        });
      },
      onCompleted: () => {
        if (refetch) refetch();
      },
    });

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  const filename =
    content.carousselSection[carousselIndex].image?.filename ?? "";
  const imageUrl = content.carousselSection[carousselIndex].image?.url ?? "";

  return (
    <>
      {imageUrl ? (
        <AppRow style={{ justifyContent: "space-between", marginBottom: 15 }}>
          <img
            src={imageUrl}
            className="img"
            alt="Responsive"
            width="auto"
            height="60px"
            style={{ textAlign: "center" }}
          />
          <AppTimerButton
            loading={deleteLoading}
            endOfTimeFunction={() =>
              deleteImage({
                variables: {
                  contentId: content?.id,
                  filename,
                  carousselIndex,
                },
              })
            }
          />
        </AppRow>
      ) : (
        <>
          <AppImageUploading
            saveImage={(images) => setImages(images)}
            maxFileSize={5000000}
            maxImagesNumber={1}
          />
          <AppText center style={{ margin: "10px 0 20px 0" }}>
            {"Image must be a .png file, 1000px width and 770px height"}
          </AppText>
        </>
      )}

      {images.length > 0 && (
        <AppButton
          loading={imageLoading}
          primary
          onClick={() => {
            if (content)
              uploadImage({
                variables: {
                  file: images[0].file,
                  contentId: content?.id,
                  carousselIndex,
                },
              }).catch((e: any) => console.log(e));
          }}
        >
          UPLOAD
        </AppButton>
      )}

      <AppDialog
        open={error.show}
        onClose={() => {
          setError({
            show: false,
            title: "",
            message: "",
          });
        }}
        scroll="body"
        width={600}
      >
        <AppError
          title={error.title}
          message={error.message}
          onClose={() => {
            setError({
              show: false,
              title: "",
              message: "",
            });
          }}
        />
      </AppDialog>
    </>
  );
};
