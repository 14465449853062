import { indexOf } from "lodash";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  Field,
  FieldArray,
  Form,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { VariantTypes } from "../../../../graphql/generated/types";
import {
  AppButton,
  AppCard,
  AppCol,
  AppFormCheckBox,
  AppFormDateTimePicker,
  AppFormTextField,
  AppRow,
  AppText,
} from "../../../components";
import { FieldArrayTypes } from "./FieldArrays";

export interface IUpdateBaseFormProps {
  initialValues?: any;
  loading?: boolean;
  fields?: string[];
  submitButtonString: any;
}

export type IUpdateFormProps = IUpdateBaseFormProps &
  InjectedFormProps<any, IUpdateBaseFormProps>;

const ReduxForm: FunctionComponent<IUpdateFormProps> = ({
  initialValues,
  loading,
  handleSubmit,
  error,
  fields,
  submitButtonString,
}) => {
  const { t } = useTranslation();

  const variantTypesOptions = [];
  for (const [propertyKey, propertyValue] of Object.entries(VariantTypes)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    variantTypesOptions.push({ value: propertyValue, label: propertyKey });
  }

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}

      <AppCard
        style={{
          margin: "20px 0",
        }}
      >
        {!initialValues && (
          <AppRow>
            <Field
              name="hasSorting"
              component={AppFormCheckBox}
              label={t("fieldLabel.hasSorting", "Sort?")}
              //   help={t("fieldHelp.firstname", )}
              autoFocus
              style={{ marginBottom: 20 }}
            />
            <AppCol style={{ width: "100%" }}>
              <Field
                name="sorting"
                component={AppFormTextField}
                label={t("fieldLabel.sorting", "Sorting")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                autoFocus
                style={{ marginBottom: 20 }}
              />
            </AppCol>
          </AppRow>
        )}

        {(!fields || indexOf(fields, "name") !== -1) && (
          <Field
            name="name"
            component={AppFormTextField}
            label={t("fieldLabel.productVariantName", "Product Variant Name")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "description") !== -1) && (
          <Field
            name="description"
            component={AppFormTextField}
            label={t("fieldLabel.description", "Description")}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            autoFocus
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "types") !== -1) && (
          <FieldArray name="types" component={FieldArrayTypes as any} props />
        )}

        {(!fields || indexOf(fields, "quantity") !== -1) && (
          <Field
            name="quantity"
            component={AppFormTextField}
            label={t("fieldLabel.quantity", "Product Variant Quantity")}
            //   help={t("fieldHelp.firstname", )}
            type="number"
            parse={parseInt}
            style={{ marginBottom: 20 }}
          />
        )}

        {(!fields || indexOf(fields, "postfixVariantReference") !== -1) && (
          <Field
            name="postfixVariantReference"
            component={AppFormTextField}
            label={t(
              "fieldLabel.postfixVariantReference",
              "Product Variant PostFix Ref "
            )}
            //   help={t("fieldHelp.firstname", )}
            type="text"
            style={{ marginBottom: 20 }}
          />
        )}

        <AppRow style={{ gap: 20 }}>
          <AppCol style={{ width: "50%" }}>
            {(!fields || indexOf(fields, "weight") !== -1) && (
              <Field
                name="weight"
                component={AppFormTextField}
                label={t("fieldLabel.weight", "Weight (Grams)")}
                //   help={t("fieldHelp.firstname", )}
                type="number"
                parse={parseInt}
                style={{ marginBottom: 20 }}
              />
            )}
          </AppCol>
          <AppCol style={{ width: "100%" }}>
            {(!fields || indexOf(fields, "dimensions") !== -1) && (
              <Field
                name="dimensions"
                component={AppFormTextField}
                label={t(
                  "fieldLabel.dimensions",
                  "Dimensions Width x Height x Depth (CM)"
                )}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                autoFocus
                style={{ marginBottom: 20 }}
              />
            )}
          </AppCol>
        </AppRow>

        <AppRow style={{ gap: 20 }}>
          <AppCol style={{ width: "100%" }}>
            {(!fields || indexOf(fields, "availabilityStartDate") !== -1) && (
              <Field
                name="availabilityStartDate"
                component={AppFormDateTimePicker}
                label={t("fieldLabel.availabilityStartDate", "Starts at")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                autoFocus
                style={{ marginBottom: 20 }}
              />
            )}
          </AppCol>
          <AppCol style={{ width: "100%" }}>
            {(!fields || indexOf(fields, "availabilityEndDate") !== -1) && (
              <Field
                name="availabilityEndDate"
                component={AppFormDateTimePicker}
                label={t("fieldLabel.availabilityEndDate", "Ends at")}
                //   help={t("fieldHelp.firstname", )}
                type="text"
                autoFocus
                style={{ marginBottom: 20 }}
              />
            )}
          </AppCol>
        </AppRow>

        {/* {(!fields || indexOf(fields, "options") !== -1) && (
          <FieldArray
            name="options"
            component={FieldArrayOptions as any}
            props
          />
        )}

        {(!fields || indexOf(fields, "upsellProducts") !== -1) && (
          <FieldArray
            name="upsellProducts"
            component={FieldArrayUpsellProducts as any}
            props
          />
        )}

        {(!fields || indexOf(fields, "crossProducts") !== -1) && (
          <FieldArray
            name="crossProducts"
            component={FieldArrayCrossProducts as any}
            props
          />
        )} */}
      </AppCard>

      {error && (
        <AppText danger bold center style={{ marginBottom: 20 }}>
          {error}
        </AppText>
      )}
      <AppButton primary valid type="submit" loading={loading}>
        {submitButtonString}
      </AppButton>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: "variantForm",
})(ReduxForm);
