import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";

countries.registerLocale(fr);
countries.registerLocale(en);

export function getCountries(language: string = "en") {
  const allCountries = countries.getNames(language.slice(0, 2));
  return Object.keys(allCountries).map((key) => ({
    value: key,
    label: allCountries[key],
  }));
}
